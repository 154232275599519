import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Card, Grid, Header, Progress, Placeholder } from 'semantic-ui-react';

import EditingBlockWrapper from '../../EditingBlockWrapper';
import dealStore from '~/stores/dealStore';

import ShadowFragment from '../../../Base/ShadowFragment';
import DealStatusEditing, { DEAL_TIMES_STATUS } from '../EditBlocks/DealStatusEditing';

import { EditingWrappedProps } from '../../EditingBlockWrapper';
import Time from '../../../Base/Time';
import DealProgress from '../../../Lists/Deals/DealProgress';
import { ESTATE_TYPE_SELL_ID } from '~/types/estate.types';

@observer
class DealStatusShowing extends Component<EditingWrappedProps> {
    render() {
        const { item_id, editing } = this.props;
        const { item, loadingItem } = dealStore.getItem(item_id);

        if (!item || loadingItem) {
            return (
                <Placeholder>
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                </Placeholder>
            );
        }

        const { createTime, updateTime, description, status, releaseTime, type } = item;

        return (
            <Card fluid color="blue">
                <Card.Content>
                    {!editing && (
                        <Fragment>
                            <Header size="tiny">Описание</Header>
                            <Grid>
                                {type === ESTATE_TYPE_SELL_ID && (
                                    <Grid.Row>
                                        <Grid.Column width={8}>Статус</Grid.Column>
                                        <Grid.Column width={8}>
                                            {Number(status) > 0 && <DealProgress type={type} status={status} size="small" />}
                                        </Grid.Column>
                                    </Grid.Row>
                                )}
                                {description && (
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <ShadowFragment shadow={loadingItem} print={description} />
                                        </Grid.Column>
                                    </Grid.Row>
                                )}
                                {releaseTime && (
                                    <Grid.Row>
                                        <Grid.Column width={8}>Ориентировочная дата проведения</Grid.Column>
                                        <Grid.Column width={8}>{new Date(releaseTime * 1000).toLocaleDateString()}</Grid.Column>
                                    </Grid.Row>
                                )}
                                {DEAL_TIMES_STATUS.map(([key, title]) =>
                                    item[key] ? (
                                        <Grid.Row key={key}>
                                            <Grid.Column width={8}>{title}</Grid.Column>
                                            <Grid.Column width={8}>{new Date(item[key] * 1000).toLocaleDateString()}</Grid.Column>
                                        </Grid.Row>
                                    ) : null
                                )}
                            </Grid>
                        </Fragment>
                    )}
                    {editing && <DealStatusEditing editing={true} item_id={item_id} {...item} />}

                    {!editing && (
                        <Card.Description>
                            <Grid columns={2}>
                                <Grid.Row>
                                    <Grid.Column>Создано</Grid.Column>
                                    <Grid.Column>
                                        <ShadowFragment shadow={loadingItem} print={<Time time={createTime} />} />
                                    </Grid.Column>
                                    {updateTime !== createTime && (
                                        <Fragment>
                                            <Grid.Column>Обновлено</Grid.Column>
                                            <Grid.Column>
                                                <Time time={updateTime} />
                                            </Grid.Column>
                                        </Fragment>
                                    )}
                                </Grid.Row>
                            </Grid>
                        </Card.Description>
                    )}
                </Card.Content>
            </Card>
        );
    }
}

export default EditingBlockWrapper(dealStore, DealStatusShowing);
