import { proxyL10nHandler } from '~/common/localizations';

const KPI_POINT_TYPE = [
    [1, 'Платных в рекламе (Циан / Авито)'],
    [2, 'Новых платных в рекламе (Циан / Авито)'],
    // [2, 'Эксклюзивов'],

    [10, 'Исходящие звонки'],
    [11, 'Исходящие звонки (от 30 секунд)'],
    [15, 'Входящие звонки'],
    [16, 'Входящие звонки (от 30 секунд)'],
    [19, 'Звонки по объектам'],

    [21, 'Вал, рублей'],
    [25, 'Открыто сделок'],
    [26, 'Закрыто сделок'],
    [27, 'Развал сделок'],

    [101, 'Открыто Продажа/Покупка'],
    [102, 'Закрыто Продажа/Покупка'],
    [103, 'Развал Продажа/Покупка'],

    [105, 'Открыто Аренда'],
    [106, 'Закрыто Аренда'],
    [107, 'Развал Аренда'],

    [111, 'Открыто Эксклюзив'],
    [112, 'Закрыто Эксклюзив'],
    [113, 'Развал Эксклюзив'],

    [115, 'Открыто Сопровждение'],
    [116, 'Закрыто Сопровждение'],
    [117, 'Развал Сопровждение'],

    [121, 'Открыто Новостройка'],
    [122, 'Закрыто Новостройка'],
    [123, 'Развал Новостройка'],

    [125, 'Открыто Ипотека'],
    [126, 'Закрыто Ипотека'],
    [127, 'Развал Ипотека'],

    [31, 'Показы'],

    [41, 'Нет звонков (сообщений) по объекту (7 дней)'],
    [42, 'Нет звонков (сообщений) по объекту (14 дней)'],
    [48, 'Сброшенно ограничений'],

    [50, 'Отправлено СМА'],

    [60, 'Запланировано встреч'],

    [70, 'Баланс, руб'],
    [71, 'Расход на рекламу, руб'],
    [72, 'Расход на Премиальную рекламу, руб'],

    [80, 'Понижение цены на 1% и более (от цены не менее суток назад)'],
    [81, 'Понижение цены на 2% и более (от цены не менее суток назад)'],
    [82, 'Понижение цены на 3% и более (от цены не менее суток назад)'],
    [83, 'Понижение цены на 5% и более (от цены не менее суток назад)'],
    [84, 'Понижение цены на 10% и более (от цены не менее суток назад)'],

    [90, 'Количество новых заявок']
];

const KPI_POINT_TYPE_SHORT = [
    [1, 'Циан/Авито'],
    [2, 'Новые Ц/А'],
    // [2, 'Эксклюзивов'],

    [10, 'Исх-ие'],
    [11, 'Исх-ие (30с)'],
    [15, 'Вх-ие'],
    [16, 'Вх-ие (30с)'],
    [19, 'По объект.'],

    [21, 'Вал, р'],
    [25, 'Открыто'],
    [26, 'Закрыто'],
    [25, 'Открыто'],
    [27, 'Развал'],

    [101, 'Открыто П/П'],
    [102, 'Закрыто П/П'],
    [103, 'Развал П/П'],

    [105, 'Открыто А'],
    [106, 'Закрыто А'],
    [107, 'Развал А'],

    [111, 'Открыто Э'],
    [112, 'Закрыто Э'],
    [113, 'Развал Э'],

    [115, 'Открыто С'],
    [116, 'Закрыто С'],
    [117, 'Развал С'],

    [121, 'Открыто Н'],
    [122, 'Закрыто Н'],
    [123, 'Развал Н'],

    [125, 'Открыто И'],
    [126, 'Закрыто И'],
    [127, 'Развал И'],

    [31, 'Показы'],

    [41, 'Нет зв. 7д'],
    [42, 'Нет зв. 14д'],
    [48, 'Сброс'],

    [50, 'СМА'],
    [60, 'Встречи'],
    // [41, 'Бонус новичку']

    [70, 'Баланс'],
    [71, 'Расход'],
    [72, 'Премиум'],

    [80, 'Цена -1%'],
    [81, 'Цена -2%'],
    [82, 'Цена -3%'],
    [83, 'Цена -5%'],
    [84, 'Цена -10%'],

    [90, 'Заявки']
];

const KPI_DAYS = [
    [1, '1 день'],
    [7, '7 дней'],
    [14, '14 дней'],
    [30, '30 дней'],
    [60, '60 дней'],
    [90, '90 дней'],
    [180, '180 дней'],
    [360, '360 дней']
];

const statisticsL10n = new Proxy({}, proxyL10nHandler);

statisticsL10n.KPI_POINT_TYPE = KPI_POINT_TYPE;
statisticsL10n.KPI_POINT_TYPE_SHORT = KPI_POINT_TYPE_SHORT;
statisticsL10n.KPI_DAYS = KPI_DAYS;

export default statisticsL10n;
