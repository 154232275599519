import React, { Fragment } from 'react';
import { TableCell } from '~ui/Table';

import { observer } from 'mobx-react';
import exportingBaseStore from '~/stores/export/exportingBaseStore';

import Select from '~/components/Base/ui/Select';
import { NumberInput } from '~ui/TextInput';

export const MIN_DAYS_OPTIONS = [
    { value: 1, text: 'день' },
    { value: 7, text: '7 д' },
    { value: 30, text: '30 д' }
];

const ExportFeedTariffsPrices = ({ export_base_id, tariff_id }: { tariff_id: number; export_base_id: number }) => {
    const handleEditPrice = (price_id: number, event: React.ChangeEvent<HTMLInputElement>) => {
        exportingBaseStore.setEditingExportingBasePrice(export_base_id, tariff_id, price_id, { dailyPrice: Number(event.target.value) });
    };

    const handleEditExtraPrice = (price_ids: number[], value: number) => {
        exportingBaseStore.setEditingExportingBaseExtraPrice(export_base_id, tariff_id, price_ids, value);
    };

    const handleMinDaysPrice = (price_id: number, event: React.ChangeEvent<HTMLInputElement>) => {
        exportingBaseStore.setEditingExportingBasePrice(export_base_id, tariff_id, price_id, { minDays: Number(event.target.value) });
    };

    const { prices } = exportingBaseStore.getItem(export_base_id).editingItem.tariffs.find(tariff => tariff.tariff_id === tariff_id);
    let pricesStat = [];
    const { tariffStatistics } = exportingBaseStore.getItem(export_base_id).property;
    if (tariffStatistics) {
        pricesStat = tariffStatistics.find(tariff => tariff.tariff_id === tariff_id).prices;
    }
    const isExtraExist = Boolean(prices[0].extraName);

    return (
        <Fragment>
            {prices.map(({ name, dailyPrice, price_id, minDays, isPremium }) => {
                const foundPrice = pricesStat.find(pS => pS.price_id === price_id);
                return (
                    <TableCell align="right" key={name} negative={isPremium}>
                        <NumberInput
                            value={dailyPrice}
                            onChange={handleEditPrice.bind(this, price_id)}
                            extraLabel={<>р.({foundPrice ? foundPrice['countActiveEstates'] : '--'})</>}
                            startLabel={
                                <Select
                                    withoutBorder
                                    value={minDays}
                                    options={MIN_DAYS_OPTIONS}
                                    onChange={handleMinDaysPrice.bind(this, price_id)}
                                />
                            }
                            style={{ minWidth: '11em' }}
                            step={0.25}
                            min={0}
                            size="small"
                            fullWidth
                        />
                    </TableCell>
                );
            })}
            {isExtraExist && (
                <TableCell align="right" key="isExtraExist">
                    <NumberInput
                        value={prices[0].extraPrice}
                        onChange={(event, { value }) =>
                            handleEditExtraPrice(
                                prices.map(({ price_id }) => price_id),
                                value
                            )
                        }
                        extraLabel={<span>р.</span>}
                        step={0.25}
                        min={0}
                        size="small"
                        fullWidth
                    />
                </TableCell>
            )}
        </Fragment>
    );
};

export default observer(ExportFeedTariffsPrices);
