import React, { Component } from 'react';
import { observer } from 'mobx-react';
import history from '~/history';
import deepCopy from '~/common/deepCopy';

import Table, { TableCell, TableRow, TableHead } from '~ui/Table';
import Divider from '~ui/Divider';
import Button from '~ui/Button';
import { SuccessMessage, InfoMessage, WarningMessage } from '~ui/Message';

import cellCallStore from '~/stores/cell/cellCallStore';
import callSourceStore from '~/stores/cell/callSourceStore';
import contactStore from '~/stores/contactStore';
import sourcePhoneStore from '~/stores/cell/sourcePhoneStore';
import { Contact } from '~/types/contacts.types';
import { SourcePhone } from '~/types/sourcePhones.types';
import { ESTATE_BASE_MAIN, ESTATE_BASE_MY, ESTATE_BASES } from '~/types/estate.types';

import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import estateStore from '~/stores/estateStore';

import EditField from '~/components/Base/EditField';
import HandlerEditingBlockWrapper, { HandleChangeType, HandlerEditingProps } from '~/components/Items/HandlerEditingBlockWrapper';
import { HandlerEditingWrappedProps } from '~/components/Items/HandlerEditingBlockWrapper';
import ListError from '~/components/ListError';
import ItemDropdown from '~/components/Items/Common/ItemDropdow';
import CellCallTableBody from '~/components/Lists/CellCalls/CellCallTableBody';
import PersonalBlockEditing from '~/components/Items/Contact/EditBlocks/PersonalBlockEditing';
import TagsBlockEditing from '~/components/Items/Contact/EditBlocks/TagsBlockEditing';
import ButtonGroup from '~/components/Base/ButtonGroup';
import LoaderAwait from '~/components/Base/LoaderAwait';

import { ContactLinkState } from '~/components/Lists/Contacts/ContactLink';
import { UserLinkType } from '~/types/users.types';
import CallEstateUserPhone from './CallEstateUserPhone';
import * as commentsApi from '~/api/commentsApi';
import InfoPopup from '~/components/Base/InfoPopup';

type CallEstateModalPropsOutside = HandlerEditingProps & {
    contact_id: number;
    goToContactOnCreate: boolean;
    defaultBase?: ESTATE_BASES;
    onClose?: () => void;
};

type CallEstateModalProps = HandlerEditingWrappedProps & CallEstateModalPropsOutside;

@observer
class CallEstateEditingMode extends Component<
    CallEstateModalProps,
    { base: ESTATE_BASES; sourcePhone: SourcePhone | null; loadingContact: boolean }
> {
    constructor(props: CallEstateModalProps) {
        super(props);
        const { contact_id, defaultBase } = props;
        this.state = {
            base: defaultBase || ESTATE_BASE_MY,
            sourcePhone: null,
            loadingContact: false
        };

        estateStore.fetchItemDropdownBase = defaultBase || ESTATE_BASE_MY;

        Promise.all([cellCallStore.fetchItem(props.item_id), contactStore.fetchItem(contact_id, null, true)]).then(() => {
            const contact = contactStore.getItem(contact_id);
            const cellCall = cellCallStore.getItem(props.item_id);

            let tags_ids: number[] = [];

            if (cellCall.item) {
                const sourcePhone = sourcePhoneStore.findByPhone(cellCall.item.abonent);
                if (sourcePhone) {
                    tags_ids = sourcePhone.tags_ids;
                    cellCallStore.setEditingItem(props.item_id, {
                        source_id: sourcePhone.source_id,
                        setMajorUsersFromEstate: true
                    });
                    this.setState({ sourcePhone });
                }

                const { estate_id } = cellCall.item;
                if (estate_id) {
                    estateStore.fetchItem(estate_id);
                }
            }

            const newContact: Partial<Contact> = contact.item
                ? { ...contact.item, tags_ids: Array.from(new Set([...tags_ids, ...contact.item?.tags_ids])) }
                : { tags_ids };
            contactStore.setEditingItem(contact_id, deepCopy(newContact));
        });
    }

    handleChangeBase = (event: React.SyntheticEvent, { value }: { value: ESTATE_BASES }) => {
        estateStore.fetchItemDropdownBase = value === ESTATE_BASE_MY ? ESTATE_BASE_MY : ESTATE_BASE_MAIN;
        this.setState({ base: value });
        estateStore.debounceItemDropdownOptions();
    };

    componentWillUnmount() {
        estateStore.fetchItemDropdownBase = ESTATE_BASE_MAIN;
    }

    handleSave = async () => {
        const { item_id, goToContactOnCreate, onClose } = this.props;

        if (!goToContactOnCreate) {
            cellCallStore.saveItem(item_id);
            onClose && onClose();
        } else {
            await cellCallStore.saveItem(item_id);
            await cellCallStore.fetchItem(item_id);
            const { item } = cellCallStore.getItem(item_id);
            history.push(ContactLinkState(item.item_id));
            onClose && onClose();
        }
    };

    handleSaveContact = async () => {
        this.setState({ loadingContact: true });
        let { item_id, contact_id } = this.props;
        const { editingItem, item } = cellCallStore.getItem(item_id);

        if (contact_id) {
            await contactStore.saveItem(contact_id);
        } else {
            contactStore.setEditingItem(CREATING_ITEM_ID, { phones: [{ phone: item.phone, phone_id: 0 }] });
            contact_id = await contactStore.createItem();
        }

        await commentsApi.addComment('CONTACT', contact_id, editingItem.comment);

        history.push(ContactLinkState(contact_id));
        this.setState({ loadingContact: false });
    };

    handleEstateChange: HandleChangeType = (event, data) => {
        if (data.value) {
            estateStore.fetchItem(data.value);
        }
        this.props.handleChange(event, data);
    };

    render() {
        const { item_id, handleChange, handleTextAreaChange, contact_id } = this.props;
        const { sourcePhone, loadingContact } = this.state;
        const { item, editingItem, loadingItem, errors } = cellCallStore.getItem(item_id);

        if (!item) {
            return null;
        }

        const { base } = this.state;
        const { allCallSourcesDropdown, loadingFetchAllSources } = callSourceStore;
        const { estate_id, source_id, comment } = editingItem || {};
        const { loadingItem: loadingContactItem, editingItem: contact } = contactStore.getItem(contact_id);

        let major_users: UserLinkType[] = [];
        if (estate_id) {
            try {
                major_users = estateStore.getItem(estate_id)?.item?.major_users || [];
            } catch (err) {}
        }

        return (
            <>
                {sourcePhone && <SuccessMessage>Звонок на {sourcePhone.title}</SuccessMessage>}

                <ListError errors={Array.from(errors || [])} />

                <Table size="small" elevation={0}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Дата</TableCell>
                            <TableCell>Клиент</TableCell>
                            <TableCell />
                            <TableCell />
                            <TableCell>Сотрудник</TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>

                    <CellCallTableBody cellCallList={!loadingItem ? [item] : []} hideOperations />
                </Table>

                <div className="crm-Item__editingMode">
                    {sourcePhone && (
                        <InfoMessage>Спросите Id объекта, он есть в начале описания. По нему проще всего найти нужный объект</InfoMessage>
                    )}
                    <div className="crm-CellCalls__estateModal_listOrder">
                        Выберите объект &nbsp;&nbsp;
                        <ButtonGroup
                            size="small"
                            buttonSet={[
                                [ESTATE_BASE_MY, 'Мои'],
                                [ESTATE_BASE_MAIN, 'Агентство']
                            ]}
                            name="base"
                            value={base}
                            handleChange={this.handleChangeBase}
                        />
                    </div>
                    <div className="ui form small">
                        <ItemDropdown
                            name="estate_id"
                            store={estateStore}
                            item_id={estate_id}
                            onChange={this.handleEstateChange}
                            placeholder="Выбрать объект"
                            noResultsMessage="Объект не найден"
                        />
                    </div>
                    {major_users.length > 0 && (
                        <>
                            <WarningMessage header={major_users.length === 1 ? 'Ответственный агент' : 'Ответственные агенты'}>
                                {major_users.map(major_user => (
                                    <div key={major_user.user_id}>
                                        <CallEstateUserPhone user_id={major_user.user_id} />
                                    </div>
                                ))}
                            </WarningMessage>
                        </>
                    )}

                    <Divider />
                    <b>{contact_id === CREATING_ITEM_ID ? 'Создание' : 'Редактирование'} контакта</b>

                    {!loadingContactItem && (contact || contact_id === CREATING_ITEM_ID) && (
                        <PersonalBlockEditing item_id={contact_id} {...deepCopy(contact)} simpleMode />
                    )}
                    {!loadingContactItem && (contact || contact_id === CREATING_ITEM_ID) && (
                        <TagsBlockEditing item_id={contact_id} {...deepCopy(contact)} />
                    )}

                    <Divider />
                    <EditField.Drop
                        onChange={handleChange}
                        label="Источник"
                        loading={loadingFetchAllSources}
                        name="source_id"
                        value={source_id}
                        options={allCallSourcesDropdown}
                    />

                    <EditField.Area label="Комментарий" value={comment || null} name="comment" onChange={handleTextAreaChange} />
                    <LoaderAwait active={loadingContactItem || loadingItem} />

                    <div style={{ position: 'sticky', bottom: 0, background: sourcePhone ? '#f5f6fa' : 'white' }}>
                        <Divider />
                        <div style={{ display: 'flex', justifyContent: 'space-evenly', padding: '1rem 2rem 2rem' }}>
                            {sourcePhone && (
                                <span>
                                    <Button
                                        size="medium"
                                        disabled={!comment}
                                        loading={loadingContact}
                                        variant="outlined"
                                        color="secondary"
                                        onClick={this.handleSaveContact}
                                    >
                                        Звонок не по объекту
                                    </Button>
                                    <InfoPopup size="small">
                                        Необходимо написать комментарий, вносящий ясность что это за звонок. Например, объект уже снят с
                                        рекламы или жалоба
                                    </InfoPopup>
                                </span>
                            )}
                            <Button size="medium" disabled={!estate_id || !source_id} primary variant="contained" onClick={this.handleSave}>
                                Сохранить
                            </Button>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default HandlerEditingBlockWrapper<CallEstateModalPropsOutside>(cellCallStore, CallEstateEditingMode);
