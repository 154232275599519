import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Button, Card, Popup, Icon, List } from 'semantic-ui-react';
import estateStore from '~/stores/estateStore';
import MetroLogo from '../../../Base/MetroLogo';
import AddressBlockEditing from '../EditBlocks/AddressBlockEditing';
import initializeAddressMap from '../../../../common/osmMapApi';
import EditingBlockWrapper from '../../EditingBlockWrapper';
import { EditingWrappedProps } from '../../EditingBlockWrapper';
import {
    ESTATE_PROPERTY_TYPE_NEWBUILDING,
    ESTATE_PROPERTY_TYPE_NEWBUILDING_CESSION,
    MetroDistance,
    TRANSPORT_TYPE_WALK
} from '~/types/estate.types';
import { Newbuilding, NewBuildingHouse } from '~/types/newbuildings.types';
import Modal, { ModalContent } from '~ui/Modal';
import commonStore from '~/stores/commonStore';

const YandexMapId = 'yandexmap';

type EstateAddressBlockShowState = {
    isMapModalOpen: boolean;
};

export const PrintNewbuilding = ({
    newbuilding,
    newbuildingHouse,
    houseIsNeeded
}: {
    newbuilding: Newbuilding;
    newbuildingHouse?: NewBuildingHouse;
    houseIsNeeded?: boolean;
}) => (
    <div>
        <Icon name="building" color="grey" />
        ЖК «<b>{newbuilding.name}</b>»
        {newbuildingHouse && (
            <Fragment>
                , корпус <b>{newbuildingHouse.name}</b>
            </Fragment>
        )}
        {!newbuildingHouse && houseIsNeeded && <span className="crm-Estate__list_nophoto"> НЕ УКАЗАН НОМЕР КОРПУСА</span>}
    </div>
);

export const MetrosBlockShow = ({ metros }: { metros: MetroDistance[] }) => {
    return (
        <div className="crm-Estate__metroList_showMode">
            {metros &&
                metros.map(({ name, color, time, transportType }, i) => (
                    <span key={i} className="crm-Estate__metroList_itemShow">
                        <MetroLogo color={`#${color || ''}`} />
                        &nbsp;
                        {name} ({time} мин. {transportType === TRANSPORT_TYPE_WALK ? 'пешком' : 'на машине'})
                    </span>
                ))}
        </div>
    );
};

@observer
class EstateAddressBlockShow extends Component<EditingWrappedProps, EstateAddressBlockShowState> {
    state = {
        isMapModalOpen: false
    };

    showMap = () => {
        const { geo_lat, geo_lon, address } = estateStore.getItem(this.props.item_id).item || {};

        initializeAddressMap(YandexMapId, Number(geo_lat), Number(geo_lon), address);
    };

    toggleMapModal = () => {
        this.setState({
            isMapModalOpen: !this.state.isMapModalOpen
        });
    };

    render() {
        const { item_id, editing } = this.props;
        const { isMapModalOpen } = this.state;
        const item = estateStore.getItem(item_id).item;
        const {
            address,
            metros,
            fias,
            kladr,

            street,
            streetType,
            houseNumber,
            houseNumberType,
            blockNumber,
            blockNumberType,

            town,
            townType,
            region,
            regionType,
            district,
            cityDistrict,
            districtType,
            cityDistrictType,
            settlement,
            settlementType,
            postcode,

            newbuilding,
            newbuildingHouse,

            propertyType
        } = item || {};

        return (
            <Fragment>
                {!editing && (
                    <Fragment>
                        {address}
                        &nbsp;
                        <Popup
                            trigger={
                                <Icon
                                    circular
                                    color="blue"
                                    name="info"
                                    size="small"
                                    title="Информация об адресе"
                                    style={{ cursor: 'pointer' }}
                                />
                            }
                            size="small"
                            on="click"
                            content={
                                <List>
                                    <List.Item>
                                        <List.Header>Регион</List.Header>
                                        {`${regionType || ''} ${region || ''}`}
                                    </List.Item>
                                    <List.Item>
                                        <List.Header>Адмн.образование</List.Header>
                                        {`${districtType || ''} ${district || ''}`}
                                    </List.Item>
                                    <List.Item>
                                        <List.Header>Населенный пункт</List.Header>
                                        {`${townType || ''} ${town || ''}`}
                                    </List.Item>
                                    <List.Item>
                                        <List.Header>Район</List.Header>
                                        {`${cityDistrictType || ''} ${cityDistrict || ''}`}
                                    </List.Item>
                                    <List.Item>
                                        <List.Header>Поселение</List.Header>
                                        {`${settlement || ''} ${settlementType || ''}`}
                                    </List.Item>
                                    <List.Item>
                                        <List.Header>Почтовый код</List.Header>
                                        {`${postcode || ''}`}
                                    </List.Item>
                                    <List.Item>
                                        <List.Header>Улица</List.Header>
                                        {`${streetType || ''} ${street || ''}`}
                                    </List.Item>
                                    <List.Item>
                                        <List.Header>Дом</List.Header>
                                        {`${houseNumberType || ''} ${houseNumber || ''} ${blockNumberType || ''} ${
                                            blockNumber || ''
                                        }`.trim()}
                                    </List.Item>
                                    <List.Item>
                                        <List.Header>Фиас</List.Header>
                                        {`${fias || ''}`}
                                    </List.Item>
                                    <List.Item>
                                        <List.Header>Кладр</List.Header>
                                        {`${kladr || ''}`}
                                    </List.Item>
                                </List>
                            }
                            position="bottom center"
                        />
                        <Button basic size="mini" onClick={this.toggleMapModal}>
                            на карте
                        </Button>
                        {newbuilding && (
                            <PrintNewbuilding
                                newbuilding={newbuilding}
                                newbuildingHouse={newbuildingHouse}
                                houseIsNeeded={[ESTATE_PROPERTY_TYPE_NEWBUILDING, ESTATE_PROPERTY_TYPE_NEWBUILDING_CESSION].includes(
                                    propertyType
                                )}
                            />
                        )}
                        <MetrosBlockShow metros={metros} />
                        {isMapModalOpen && (
                            <Modal
                                onMount={this.showMap}
                                fullScreen={commonStore.isMobile}
                                header="Объект на карте"
                                maxWidth="md"
                                onClose={this.toggleMapModal}
                            >
                                <ModalContent dividers>
                                    <div id={YandexMapId} className="crm-Estate__mapBox" />
                                </ModalContent>
                            </Modal>
                        )}
                    </Fragment>
                )}
                {editing && (
                    <Card fluid color="red">
                        <Card.Content>
                            <AddressBlockEditing editing={editing} estate_id={item_id} {...item} />
                        </Card.Content>
                    </Card>
                )}
            </Fragment>
        );
    }
}

export default EditingBlockWrapper(estateStore, EstateAddressBlockShow);
