import React, { SyntheticEvent } from 'react';
import { observer } from 'mobx-react';

import Select from '~ui/Select';
import Table, { TableRow, TableHead, TableCell, TableBody } from '~ui/Table';

import {
    AccessModes,
    AccessPermission,
    ACCESS_COMPANY,
    ACCESS_DELETE,
    ACCESS_GROUP,
    ACCESS_NONE,
    ACCESS_READ,
    ACCESS_SELF,
    ACCESS_WRITE
} from '~/types/access.types';
import accessStore, { ACCESS_MODULE_NAME } from '~/stores/accessStore';
import commonStore from '~/stores/commonStore';

const ACCESS_MODES = [
    { key: 0, text: 'Нет', value: ACCESS_NONE },
    { key: 1, text: 'Свои', value: ACCESS_SELF },
    { key: 2, text: 'Отдел / Офис', value: ACCESS_GROUP },
    { key: 4, text: 'Все', value: ACCESS_COMPANY }
] as const;

const AccessTableEditing = ({ item_id }: { item_id: number }) => {
    const handleChangePermission = (modulename: string) => (
        event: SyntheticEvent,
        { name, value }: { name: AccessModes; value: AccessPermission }
    ) => {
        accessStore.changePermission(item_id, modulename, name, value);
    };

    const { editingItem } = accessStore.getItem(item_id);
    const { accessModes } = accessStore;
    const { modules } = editingItem;

    return (
        <Table size="small" compact maxHeight={commonStore.isMobile ? '500px' : undefined}>
            <TableHead>
                <TableRow>
                    <TableCell>Модуль</TableCell>
                    <TableCell>Чтение</TableCell>
                    <TableCell>Изменение</TableCell>
                    <TableCell>Удаление</TableCell>
                </TableRow>
            </TableHead>

            <TableBody>
                {modules &&
                    modules.length > 0 &&
                    accessModes.map(({ title, name, permissions, modes }, i) => {
                        const foundMode = modules.find(module => module.name === name);

                        if (!foundMode) {
                            setTimeout(() => {
                                accessStore.insertNewModule(item_id, name);
                            }, 0);
                            return false;
                        }

                        const ACCESS_MODES_FILTERD = ACCESS_MODES.filter(({ value }) => permissions.includes(value));

                        return (
                            <TableRow key={title}>
                                <TableCell>{title}</TableCell>
                                <TableCell align="center">
                                    {modes.includes(ACCESS_READ) && (
                                        <Select
                                            withoutBorder
                                            name={ACCESS_READ}
                                            value={foundMode[ACCESS_MODULE_NAME][ACCESS_READ]}
                                            options={ACCESS_MODES_FILTERD}
                                            onChange={handleChangePermission(name)}
                                        />
                                    )}
                                </TableCell>
                                <TableCell align="center">
                                    {modes.includes(ACCESS_WRITE) && (
                                        <Select
                                            withoutBorder
                                            name={ACCESS_WRITE}
                                            value={foundMode[ACCESS_MODULE_NAME][ACCESS_WRITE]}
                                            options={ACCESS_MODES_FILTERD}
                                            onChange={handleChangePermission(name)}
                                        />
                                    )}
                                </TableCell>
                                <TableCell align="center">
                                    {modes.includes(ACCESS_DELETE) && (
                                        <Select
                                            withoutBorder
                                            name={ACCESS_DELETE}
                                            value={foundMode[ACCESS_MODULE_NAME][ACCESS_DELETE]}
                                            options={ACCESS_MODES_FILTERD}
                                            onChange={handleChangePermission(name)}
                                        />
                                    )}
                                </TableCell>
                            </TableRow>
                        );
                    })}
            </TableBody>
        </Table>
    );
};

export default observer(AccessTableEditing);
