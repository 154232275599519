import React, { Component, Fragment, PureComponent } from 'react';
import { Grid, Image, Icon, Radio, Popup, SemanticCOLORS } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

import estateStore from '~/stores/estateStore';
import config from '~/config/ui.config';
import commonStore from '~/stores/commonStore';
import LoaderAwait from '~/components/Base/LoaderAwait';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';

type PhotosBlockEditingProps = {
    estate_id: number;
};

type PhotoSizeType = {
    src: string;
    rotate?: number;
    isPlan?: boolean;
    isLoading?: boolean;
    watermark?: number | null;
};

type SortableHanders = {
    estate_id: number;
    handleRadioPlan: (event: React.SyntheticEvent, data: { id: number }) => void;
    handleRotatePhoto: (index: number) => void;
    handleDeletePhoto: (index: number) => void;
    handleNextTrademark: (index: number) => void;
};

type SortablePhotoProps = SortableHanders &
    PhotoSizeType & {
        i: number;
        isLoading: boolean;
    };

type SortableContainerProps = SortableHanders & {
    photoSet: (PhotoSizeType & { tempSrc: string })[];
};

const ImageDragHandle = ({ src, rotate }: { src: string; rotate: number }) => {
    const ImageDragHandleNode = SortableHandle(() => (
        <Image
            title="Перетащить фотографию в другое место"
            src={src}
            bordered
            centered
            verticalAlign="middle"
            className={`crm-Estate__photolist_rotate${rotate || 0}`}
        />
    ));
    return <ImageDragHandleNode />;
};

const ImageDragHandleMemo = React.memo(ImageDragHandle);

const SortablePhotoElement = (props: SortablePhotoProps) => {
    const {
        src,
        rotate,
        isPlan,
        watermark,
        i,
        handleNextTrademark,
        handleRadioPlan,
        handleRotatePhoto,
        handleDeletePhoto,
        estate_id,
        isLoading
    } = props;

    const { watermarks } = config.estatePhotos;
    const { title, color } = typeof watermark === 'number' ? watermarks[watermark] : { title: null, color: null };

    return (
        <Grid.Column index={i}>
            <div className="crm-Estate__photolist_box">
                <div className="crm-Estate__photolist_pult">
                    {!isLoading && (
                        <>
                            <Radio
                                name="schema"
                                value="this"
                                checked={isPlan === true}
                                onClick={handleRadioPlan}
                                id={i + 1}
                                className="crm-Estate__squareInput"
                                label={<label title="Пометить (убрать), если эта фография является планировкой квартиры">План</label>}
                            />
                            {estate_id !== CREATING_ITEM_ID && (
                                <Icon link name="undo" title="Повернуть на 90 градусов" onClick={() => handleRotatePhoto(i)} />
                            )}
                            {typeof watermark === 'number' && (
                                <Popup
                                    size="tiny"
                                    wide
                                    trigger={
                                        <Icon
                                            link
                                            name="trademark"
                                            color={color as SemanticCOLORS}
                                            onClick={() => handleNextTrademark(i)}
                                        />
                                    }
                                >
                                    <span>
                                        Водяной знак: <b>{title}</b>
                                    </span>
                                </Popup>
                            )}
                        </>
                    )}
                    <Icon link name="trash" title="Удалить" onClick={() => handleDeletePhoto(i)} />
                </div>
                {isLoading && <LoaderAwait active size="mini" />}
                <ImageDragHandleMemo rotate={rotate} src={src} />
            </div>
        </Grid.Column>
    );
};

const SortablePhoto = SortableElement(SortablePhotoElement);

const SortablePhotosList = SortableContainer(
    ({ photoSet, handleRadioPlan, handleRotatePhoto, handleDeletePhoto, handleNextTrademark, estate_id }: SortableContainerProps) => {
        return (
            <Grid.Row>
                {photoSet.map(({ src, tempSrc, isLoading, isPlan, rotate, watermark }, index) => (
                    <SortablePhoto
                        estate_id={estate_id}
                        src={isLoading ? tempSrc : src}
                        rotate={rotate}
                        isPlan={isPlan}
                        watermark={watermark}
                        key={src}
                        index={index}
                        i={index}
                        handleRadioPlan={handleRadioPlan}
                        handleRotatePhoto={handleRotatePhoto}
                        handleDeletePhoto={handleDeletePhoto}
                        handleNextTrademark={handleNextTrademark}
                        isLoading={isLoading}
                    />
                ))}
            </Grid.Row>
        );
    }
);

@observer
class PhotosListBlockEditing extends Component<PhotosBlockEditingProps> {
    handleRadioPlan = (event: React.SyntheticEvent, { id }: { id: number }) => {
        const { photos } = estateStore.getItem(this.props.estate_id).editingItem;

        const newPhotos = photos.map((photo, i) => ({ ...photo, isPlan: !photo.isPlan && i === id - 1, rotate: 0 }));
        estateStore.setEditingItem(this.props.estate_id, { photos: newPhotos });
    };

    handleDeletePhoto = (index: number) => {
        let { photos } = estateStore.getItem(this.props.estate_id).editingItem;
        photos = [...photos];
        photos.splice(index, 1);
        estateStore.setEditingItem(this.props.estate_id, { photos });
    };

    handleRotatePhoto = (id: number) => {
        const { estate_id } = this.props;

        const { photos } = estateStore.getItem(estate_id).editingItem;
        const { rotate } = photos[id];

        estateStore.changeArrayValue(estate_id, 'photos', id, 'rotate', ((rotate || 0) + 270) % 360);
    };

    handleNextTrademark = (id: number) => {
        const { estate_id } = this.props;

        const { photos } = estateStore.getItem(estate_id).editingItem;
        const { watermark } = photos[id];

        estateStore.changeArrayValue(estate_id, 'photos', id, 'watermark', ((watermark || 0) + 1) % config.estatePhotos.watermarks.length);
    };

    onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
        if (oldIndex !== newIndex) {
            estateStore.movePhoto(this.props.estate_id, oldIndex, newIndex);
        }
    };

    render() {
        const { estate_id } = this.props;
        const { photos } = estateStore.getItem(estate_id).editingItem;

        const {
            estatePhotos: { estatePhotoUrl, sizes, estateTempPhotoUrl }
        } = config;

        const photoSet =
            photos &&
            photos.map(({ filename, isLoading, originalname, rotate, isPlan, watermark }) => {
                return {
                    rotate,
                    isPlan,
                    watermark,
                    isLoading,
                    ...(originalname || isLoading
                        ? {
                              src: estateTempPhotoUrl + filename + `?${rotate || 0}-${watermark || 0}`,
                              tempSrc: estateTempPhotoUrl + filename
                          }
                        : {
                              src: estatePhotoUrl + sizes[2].dir + '/' + sizes[2].prefix + filename,
                              tempSrc: estateTempPhotoUrl + filename,
                              srcSet: sizes.map(
                                  ({ dir, prefix, width }) => estatePhotoUrl + dir + '/' + prefix + filename + ` ` + width + 'w'
                              )
                          })
                };
            });

        return (
            <Fragment>
                {photoSet && photoSet.length > 0 && (
                    <Grid columns={commonStore.isMobile ? 2 : estate_id === CREATING_ITEM_ID ? 5 : 3} className="crm-Estate__photolist">
                        <SortablePhotosList
                            estate_id={estate_id}
                            photoSet={photoSet}
                            onSortEnd={this.onSortEnd}
                            handleRadioPlan={this.handleRadioPlan}
                            handleRotatePhoto={this.handleRotatePhoto}
                            handleDeletePhoto={this.handleDeletePhoto}
                            useDragHandle
                            handleNextTrademark={this.handleNextTrademark}
                            axis="xy"
                        />
                    </Grid>
                )}
            </Fragment>
        );
    }
}

export default PhotosListBlockEditing;
