import React from 'react';
import ReactDOM from 'react-dom';
import './less/main.less';
import './less/styles.less';
import '../node_modules/nprogress/nprogress.css';
import '../node_modules/react-datepicker/dist/react-datepicker.min.css';

import { configure } from 'mobx';
import 'mobx-react-lite/batchingForReactDom';

import accessStore from './stores/accessStore';
import userStore from './stores/userStore';
import authStore from './stores/authStore';
import commonStore from './stores/commonStore';
import contactStore from './stores/contactStore';
import commentStore from './stores/commentStore';
import estateStore from './stores/estateStore';
import exportStore from './stores/export/exportStore';
import showingStore from './stores/showingStore';

import taskStore from './stores/taskStore';
import globalSearchStore from './stores/globalSearchStore';
import notificationStore from './stores/notificationStore';
import triggerStore from './stores/triggerStore';
import requestStore from './stores/requestStore';
import documentStore from './stores/documentStore';
import chatStore from './stores/chatStore';
import dealStore from './stores/dealStore';
import ownerStore from './stores/ownerStore';
import tagStore from './stores/tagStore';
import wishStore from './stores/wishStore';

import calendarStore from './stores/calendarStore';
import meetingEventStore from './stores/meetingEventStore';
import statisticStore from './stores/statisticStore';
import learningStore from './stores/learningStore';
import settingStore from './stores/settingStore';
import shareStore from './stores/shareStore';
import exportStatisticsStore from './stores/export/exportStatisticsStore';
import estateSMAStore from './stores/estateSMAStore';
import historyChangeStore from './stores/historyChangeStore';
import exportReportsStore from './stores/export/exportReportsStore';

import kanbanStore from './stores/kanbanStore';
import kpiStore from './stores/kpiStore';
import whatsappStore from './stores/whatsappStore';
import userStatisticsStore from './stores/userStatisticsStore';
import estateSMAShareStore from './stores/estateSMAShareStore';

import cellCallStore from './stores/cell/cellCallStore';
import callSourceStore from './stores/cell/callSourceStore';
import callEventStore from './stores/cell/callEventStore';
import cellOperatorStore from './stores/cell/cellOperatorStore';
import sourcePhoneStore from './stores/cell/sourcePhoneStore';

import mortgageRequestStore from './stores/mortgageRequest/index';
import mortgagePersonStore from './stores/mortgageRequest/mortgagePersonStore';
import mortgageJobStore from './stores/mortgageRequest/mortgageJobStore';

import App from './App';

configure({ enforceActions: 'never' });

export const GlobalStores = {
    accessStore,
    userStore,
    authStore,
    commonStore,
    contactStore,
    commentStore,
    estateStore,
    exportStore,
    showingStore,
    cellCallStore,
    taskStore,
    globalSearchStore,
    notificationStore,
    triggerStore,
    requestStore,
    documentStore,
    chatStore,
    dealStore,
    ownerStore,
    tagStore,
    callSourceStore,
    wishStore,
    statisticStore,
    learningStore,
    settingStore,
    shareStore,
    calendarStore,
    callEventStore,
    meetingEventStore,
    exportStatisticsStore,
    estateSMAStore,
    historyChangeStore,
    exportReportsStore,
    cellOperatorStore,
    kanbanStore,
    kpiStore,
    userStatisticsStore,
    whatsappStore,
    estateSMAShareStore,
    mortgageRequestStore,
    mortgagePersonStore,
    mortgageJobStore,
    sourcePhoneStore
};

const $root = document.getElementById('root');

if ($root) {
    ReactDOM.render(<App />, $root);
}
