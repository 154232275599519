import React, { Component, Fragment } from 'react';
import { Table, Icon, Segment, Header } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import cs from 'classnames';

import MaterialButtonGroup from '@material-ui/core/ButtonGroup';
import PrintIcon from '@material-ui/icons/Print';
import MapIcon from '@material-ui/icons/Map';

import ButtonUs from '~ui/Button';

import EstateTableBody from './EstateTableBody';
import EstateMobileCardsView from './EstateMobileCardsView';
import ListFooter from '../Common/ListFooter';
import estateStore from '~/stores/estateStore';
import FilterEstate from './FilterEstate';
import EstateTableHeader from './EstateTableHeader';
import { ListWrapperProps } from '../ListWrapper';
import ListWrapper from '../ListWrapper';
import ListError from '../../ListError';
import commonStore from '~/stores/commonStore';
import { ESTATE_BASE_FAVORITES, ESTATE_BASE_GROUP, ESTATE_BASE_MY, ESTATE_BASE_OWNERS } from '~/types/estate.types';
import ListPagination from '../Common/ListPagination';
import EstateMapFilterChips from './MapView/EstateMapFilterChips';
import PreventSwipe from '../../Base/PreventSwipe';
import ShareEstatesButton from './SubBlocks/ShareEstatesButton';

type EstatesListState = {
    reportsModalOpen: boolean;
    manageEstatesModal: boolean;
    isPrinting: boolean;
};

@observer
class EstateForeignList extends Component<ListWrapperProps, EstatesListState> {
    constructor(props: ListWrapperProps) {
        super(props);

        this.state = {
            reportsModalOpen: false,
            manageEstatesModal: false,
            isPrinting: false
        };
    }

    handleSearchOnMap = () => {
        estateStore.toggleSearchOnMap();
    };

    handlePrintBtn = async () => {
        await this.setState({
            isPrinting: true
        });
        await window.print();
        this.setState({
            isPrinting: false
        });
    };

    render() {
        const {
            loadingList,
            list,
            listCount,
            loadingCount,
            nextListLoaded,
            pagination,
            listFilter: { base, enable, propertyType, propertyTypes, showExportMajorUser, byCreateTime, type, regionId },
            listErrors,
            selectedItemsIds
        } = estateStore;
        const { isMobile } = commonStore;

        const { orderBy, orderDirection, handleSort, param } = this.props;
        const { isPrinting } = this.state;

        let footerColsCount = showExportMajorUser ? 7 : 6;
        if ([ESTATE_BASE_MY, ESTATE_BASE_GROUP, ESTATE_BASE_OWNERS, ESTATE_BASE_FAVORITES].includes(base)) {
            footerColsCount++;
        }

        return (
            <Fragment>
                <div className={cs({ 'crm-List': true, 'crm-Print__hidden': true })}>
                    <div className="crm-List__filterType">
                        <Header as="h3">
                            <Icon name="building outline" />
                            <Header.Content className="crm-List__filterType_headerContent">Зарубежная</Header.Content>
                        </Header>

                        <div className="crm-List__filterType_topBtns">
                            {/*<FilterEstateRegion />*/}
                            <ButtonUs
                                startIcon={<MapIcon />}
                                variant="contained"
                                color="secondary"
                                size="small"
                                disableElevation
                                onClick={this.handleSearchOnMap}
                                style={{ marginLeft: '1rem' }}
                            >
                                карта
                            </ButtonUs>
                            {!enable && <span className="crm-Estate__filter_archiveLabel">(Архив)</span>}
                        </div>

                        {listErrors && <ListError errors={listErrors} />}
                    </div>
                </div>

                <PreventSwipe>
                    <div className={cs({ 'crm-Print__hidden': true, 'crm-Estate__chips': true, 'crm-Estate__chips_mobile': isMobile })}>
                        <EstateMapFilterChips type="list" />
                    </div>
                </PreventSwipe>

                <Segment.Group className="crm-Segment" id="crm_ListTop">
                    <FilterEstate>
                        <Segment className="crm-List__segment">
                            {!isMobile ? (
                                <Table structured size="small" sortable compact>
                                    <Table.Header fullWidth className="crm-List__pagination_header crm-Print__hidden">
                                        <ListPagination
                                            loading={false}
                                            colSpan={footerColsCount}
                                            pagination={pagination}
                                            listCount={listCount}
                                            loadingCount={loadingCount}
                                            pageChange={estateStore.pageChange}
                                            pageSizeChange={estateStore.pageSizeChange}
                                            nextListLoaded={nextListLoaded}
                                            showPult={selectedItemsIds.length > 0}
                                        >
                                            <span style={{ marginLeft: '1em' }}>
                                                <MaterialButtonGroup variant="text" color="primary">
                                                    <ButtonUs endIcon={<PrintIcon />} onClick={this.handlePrintBtn}>
                                                        Печать
                                                    </ButtonUs>
                                                    <ShareEstatesButton />
                                                </MaterialButtonGroup>
                                            </span>
                                        </ListPagination>
                                    </Table.Header>
                                    <EstateTableHeader
                                        orderBy={orderBy}
                                        orderDirection={orderDirection}
                                        handleSort={handleSort}
                                        showExportMajorUser={Boolean(showExportMajorUser)}
                                        selectedAll={estateStore.selectedAll}
                                        filterBase={base}
                                        propertyTypes={propertyTypes}
                                    />
                                    {list && (
                                        <EstateTableBody
                                            estateList={list}
                                            showExportMajorUser={Boolean(showExportMajorUser)}
                                            byCreateTime={Boolean(byCreateTime)}
                                            selectedItemsIds={selectedItemsIds}
                                            toggleItemSelected={estateStore.toggleItemSelected.bind(estateStore)}
                                            filterBase={base}
                                            loadingList={loadingList}
                                            isPrinting={isPrinting}
                                            isForeign
                                        />
                                    )}
                                    <ListFooter
                                        loading={loadingList}
                                        colSpan={footerColsCount}
                                        pagination={pagination}
                                        loadingCount={loadingCount}
                                        listCount={listCount}
                                        pageChange={estateStore.pageChange}
                                        pageSizeChange={estateStore.pageSizeChange}
                                        nextListLoaded={nextListLoaded}
                                        scrollToElementId="crm_ListTop"
                                    />
                                </Table>
                            ) : (
                                <Fragment>
                                    {selectedItemsIds.length > 0 && <ShareEstatesButton />}
                                    <EstateMobileCardsView />
                                    {selectedItemsIds.length > 0 && list.length > 2 && <ShareEstatesButton />}
                                    <Table>
                                        <ListFooter
                                            loading={loadingList}
                                            colSpan={footerColsCount}
                                            pagination={pagination}
                                            listCount={listCount}
                                            loadingCount={loadingCount}
                                            pageChange={estateStore.pageChange}
                                            pageSizeChange={estateStore.pageSizeChange}
                                            nextListLoaded={nextListLoaded}
                                            scrollToElementId="crm_ListTop"
                                        />
                                    </Table>
                                </Fragment>
                            )}
                        </Segment>
                    </FilterEstate>
                </Segment.Group>
            </Fragment>
        );
    }
}

export default ListWrapper(estateStore, EstateForeignList);
