import React, { PureComponent, Fragment } from 'react';
import { Popup, Icon } from 'semantic-ui-react';
import { reportOpenCellCallDownload } from '~/api/cell/cellCallsApi';
import { Link } from 'react-router-dom';

import config from '../../../../config/ui.config';
import ButtonGroup from '../../../Base/ButtonGroup';
import { HandleChangeType } from '../../../Items/HandlerEditingBlockWrapper';

type DownloadLinkProps = {
    call_id: number;
    externalId?: string;
    outer_id?: string;
    downloadLink?: string;
    answerTime: number;
};

const DEFAULT_RATE = 1;

class DownloadLink extends PureComponent<DownloadLinkProps, { playbackRate: number }> {
    audio: HTMLAudioElement;

    state = {
        playbackRate: DEFAULT_RATE
    };

    handleSpeedChange: HandleChangeType = (event, { value }) => {
        this.setState({ playbackRate: Number(value) });
        this.audio.playbackRate = Number(value);
    };

    reportOpen = async () => {
        const { call_id, externalId, outer_id } = this.props;
        await reportOpenCellCallDownload({ call_id, externalId, outer_id });
    };

    render() {
        let { call_id, externalId, outer_id, downloadLink, answerTime } = this.props;
        const { playbackRate } = this.state;

        if (outer_id?.indexOf('out_') === 0 || outer_id?.indexOf('in_') === 0) {
            externalId = outer_id;
        }

        const downloadUrl =
            downloadLink ||
            (externalId && answerTime ? `${config.serverUrl}/call/download?call_id=${call_id}&external_id=${externalId}` : null);

        if (!downloadUrl) {
            return null;
        }

        return (
            <Fragment>
                <Popup
                    trigger={<Icon name="play circle" title="Прослушать" link color="blue" />}
                    content={
                        <Fragment>
                            <div style={{ marginBottom: '8px' }}>
                                Скорость&nbsp;
                                <ButtonGroup
                                    size="small"
                                    buttonSet={[
                                        [1, '1.0'],
                                        [1.25, '1.25'],
                                        [1.5, '1.5'],
                                        [2, '2']
                                    ]}
                                    name="type"
                                    value={playbackRate}
                                    handleChange={this.handleSpeedChange}
                                />
                            </div>
                            <audio
                                controls
                                ref={audio => {
                                    if (audio) {
                                        this.audio = audio;
                                    }
                                }}
                            >
                                <source src={downloadUrl} type="audio/mp3" />
                            </audio>
                            <a href={`${downloadUrl}${downloadLink ? '' : '&save=true'}`} title="Скачать" target="_blank" rel="noreferrer">
                                <Icon name="cloud download" />
                                Скачать
                            </a>
                        </Fragment>
                    }
                    on="click"
                    position="top right"
                    onOpen={this.reportOpen}
                />
            </Fragment>
        );
    }
}

export default DownloadLink;
