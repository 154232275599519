import React, { Component, ReactNode } from 'react';
import { Segment } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import './cellCalls.less';

import Container from '@material-ui/core/Container';
import Tabs from '@material-ui/core/Tabs';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import CallIcon from '@material-ui/icons/Call';
import Box from '@material-ui/core/Box';

import CellCallTableBody from './CellCallTableBody';
import CellCallTableHeader from './CellCallTableHeader';
import FilterCellCall from './FilterCellCall';

import ListFooter from '../Common/ListFooterNew';
import { ListWrapperProps } from '../ListWrapper';
import ListWrapper from '../ListWrapper';
import cellCallStore from '~/stores/cell/cellCallStore';
import ListError from '../../ListError';

import Table, { TableHead } from '~ui/Table';
import ListPagination from '~/components/Lists/Common/ListPaginationNew';
import { CALL_TYPE_INCOMING, CellCallFilter } from '~/types/cellCalls.types';
import authStore from '~/stores/authStore';
import userStore from '~/stores/userStore';
import { deltaCreateTimes } from '~/components/Lists/ListFilterWrapper';
import tagStore from '~/stores/tagStore';
import callSourceStore from '~/stores/cell/callSourceStore';

import SearchIcon from '@material-ui/icons/Search';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import PeopleIcon from '@material-ui/icons/People';
import PersonIcon from '@material-ui/icons/Person';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';

type TSearchLabel = {
    avatar?: ReactNode;
    label: string;
    key: CellCallFilterKeyof | CellCallFilterKeyof[];
};

export const cellCallSearchLabels = (filter: Partial<CellCallFilter>): TSearchLabel[] => {
    const {
        search,
        tags_ids,
        enable,
        durationFromSec,
        direction,
        major_user_id,
        deltaStartTime,
        group_id,
        tagsTogether,
        source_id
    } = filter;

    const searchLabels: TSearchLabel[] = [];

    if (enable === false) {
        searchLabels.push({ label: 'Архив', key: 'enable' });
    }

    if (direction) {
        searchLabels.push({ label: direction === CALL_TYPE_INCOMING ? 'Входящие' : 'Исходяшие', key: 'direction' });
    }

    if (group_id.length && !major_user_id.length) {
        const label = group_id
            .map(gId => {
                try {
                    const { name } = userStore.findGroupById(gId);
                    return name;
                } catch (e) {
                    return 'архив';
                }
            })
            .join(', ');
        searchLabels.push({ avatar: <PeopleIcon />, label, key: 'group_id' });
    }

    if (major_user_id.length) {
        const label =
            major_user_id
                .slice(0, 2)
                .map(user_id => {
                    try {
                        const { firstName, lastName } = authStore.findUserById(user_id);
                        return `${firstName} ${lastName}`;
                    } catch (e) {
                        return 'архив';
                    }
                })
                .join(', ') + (major_user_id.length > 2 ? ` +${major_user_id.length - 2}` : '');
        searchLabels.push({ avatar: <PersonIcon />, label, key: 'major_user_id' });
    }

    if (search) {
        searchLabels.push({ avatar: <SearchIcon />, label: `${search}`, key: 'search' });
    }

    if (typeof durationFromSec === 'number') {
        switch (durationFromSec) {
            case -1:
                searchLabels.push({ label: 'Пропущенные', key: 'durationFromSec' });
                break;
            case 0:
                searchLabels.push({ label: 'Состоявшиеся', key: 'durationFromSec' });
                break;
            case 30:
                searchLabels.push({ label: '> 30сек', key: 'durationFromSec' });
                break;
            case 60:
                searchLabels.push({ label: '> 60сек', key: 'durationFromSec' });
                break;
        }
    }

    if (deltaStartTime) {
        const { text } = deltaCreateTimes.find(({ value }) => value === deltaStartTime);
        searchLabels.push({ avatar: <HourglassEmptyIcon />, label: text, key: 'deltaStartTime' });
    }

    if (tags_ids?.length) {
        const tagNames = tags_ids
            .map(tag_id => {
                try {
                    const tag = tagStore.findById(tag_id);
                    return tag.title;
                } catch (error) {}
                return null;
            })
            .filter(tagName => tagName !== null)
            .join(', ');
        searchLabels.push({ avatar: <LoyaltyIcon />, label: tagNames, key: 'tags_ids' });
    }
    if (tagsTogether) {
        searchLabels.push({ label: 'Тэги вместе', key: 'tagsTogether' });
    }
    if (source_id) {
        try {
            const { title } = callSourceStore.findById(source_id);
            searchLabels.push({ label: title, key: 'source_id' });
        } catch (err) {}
    }

    return searchLabels;
};

type CellCallFilterKeyof = keyof CellCallFilter;

const FilterChips = observer(() => {
    const handleDeleteChip = (properties: CellCallFilterKeyof[]): void => {
        properties.forEach(prop => {
            cellCallStore.listFilter = { ...cellCallStore.listFilter, [prop]: cellCallStore.listFilterClear[prop] };
        });
        cellCallStore.fetchList();
    };

    return (
        <Tabs
            value={0}
            indicatorColor="secondary"
            textColor="secondary"
            onChange={() => {}}
            variant="scrollable"
            scrollButtons="auto"
            TabIndicatorProps={{
                style: {
                    display: 'none'
                }
            }}
            style={{ height: '32px', maxHeight: '32px', minHeight: '32px' }}
        >
            {cellCallSearchLabels(cellCallStore.listFilter).map(({ label, key, avatar }) => (
                <Chip
                    key={label}
                    label={label}
                    color="secondary"
                    variant="outlined"
                    onDelete={() => handleDeleteChip(key instanceof Array ? key : [key])}
                    avatar={avatar ? <Avatar>{avatar}</Avatar> : undefined}
                    style={{ margin: '0 0.15rem' }}
                />
            ))}
        </Tabs>
    );
});

@observer
class CellCallList extends Component<ListWrapperProps> {
    render() {
        const { orderBy, orderDirection, handleSort } = this.props;
        const { loadingList, list, listCount, nextListLoaded, pagination, listErrors } = cellCallStore;

        return (
            <Container maxWidth="lg">
                <div className="crm-List">
                    <Box display="flex" alignItems="center">
                        <CallIcon fontSize="large" style={{ paddingRight: '0.5rem' }} />
                        <Typography variant="h5" style={{ paddingRight: '0.5rem' }}>
                            Звонки
                        </Typography>

                        <FilterChips />
                    </Box>

                    {listErrors && <ListError errors={listErrors} />}
                </div>

                <Segment.Group className="crm-Segment" id="crm_ListTop">
                    <FilterCellCall />

                    <Segment className="crm-List__segment">
                        <Table size="small">
                            <TableHead>
                                <ListPagination
                                    loading={loadingList}
                                    colSpan={5}
                                    pagination={pagination}
                                    listCount={listCount}
                                    pageChange={cellCallStore.pageChange}
                                    pageSizeChange={cellCallStore.pageSizeChange}
                                    nextListLoaded={nextListLoaded}
                                />
                            </TableHead>
                            <CellCallTableHeader orderBy={orderBy} orderDirection={orderDirection} handleSort={handleSort} />

                            <CellCallTableBody cellCallList={list} />

                            <ListFooter
                                loading={loadingList}
                                colSpan={5}
                                pagination={pagination}
                                listCount={listCount}
                                pageChange={cellCallStore.pageChange}
                                pageSizeChange={cellCallStore.pageSizeChange}
                                nextListLoaded={nextListLoaded}
                                scrollToElementId="crm_ListTop"
                            />
                        </Table>
                    </Segment>
                </Segment.Group>
            </Container>
        );
    }
}

export default ListWrapper(cellCallStore, CellCallList);
