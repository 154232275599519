import React, { Fragment } from 'react';
import { Label } from 'semantic-ui-react';
import { execDaily } from '~/common/time';

export const DELTA_ONLINE_TIME = 5 * 60000;

const Time = ({ time, status = false, maxDaysToTime = 2000 }: { time?: string | number; status?: boolean; maxDaysToTime?: number }) => {
    if (typeof time !== 'number' && !status) {
        return null;
    }

    const realMicroTime = typeof time === 'number' ? (time < 1000000000000 ? time * 1000 : time) : new Date(String(time)).getTime();
    const { printTime, deltaTime } = execDaily(realMicroTime, maxDaysToTime);
    const printDate = new Date(realMicroTime).toLocaleString();

    if (status && deltaTime < DELTA_ONLINE_TIME) {
        return (
            <Fragment>
                <Label title={printDate} circular color="green" empty size="mini" /> онлайн
            </Fragment>
        );
    } else if (status) {
        return (
            <Fragment>
                <Label title={printDate} circular color="red" empty size="mini" /> {printTime}
            </Fragment>
        );
    }
    return <span title={printDate}>{printTime}</span>;
};

export default Time;
