import fetchApi from '../common/fetchApi';
import { GQUserMainFields } from './userApi';
import { ChatType, ChatFilter, ChatTitle, NotReadMessage, ChatListType } from '~/types/chat.types';
import { objectToGraphql } from '~/common/graphql';
import { OrderDirectionType } from '~/stores/prototypes/ListStore.prototype';
import { GQEstateMainFields } from './estateApi';
import { GQWAMessage, GQWhatsappContact } from '~/api/whatsappApi';
import { GQMainContactFields } from '~/api/contactsApi';
import { WhatsappChatTitle } from '~/types/whatsapp.types';

const GQChatFields = `
    message_id
    message
    sender_id
    messageType
    createTime
    deliveryTime
    readTime  
`;

export const sendPrivateMessage = async (chat_id: number, message: string): Promise<number> => {
    const graphql = `mutation { 
          sendPrivateMessage(chat_id: ${chat_id}, message: ${objectToGraphql(message)})
        }`;

    const data: { sendPrivateMessage: number } = await fetchApi.postGQ(graphql);
    return data.sendPrivateMessage;
};

export const sendAvitoChatMessage = async (avito_chat_id: number, message: string): Promise<number> => {
    const graphql = `mutation { 
          sendAvitoChatMessage(avito_chat_id: ${avito_chat_id}, message: ${objectToGraphql(message)})
        }`;

    const data: { sendAvitoChatMessage: number } = await fetchApi.postGQ(graphql);
    return data.sendAvitoChatMessage;
};

const GQChatUser = `
    ${GQUserMainFields}
    onlineTime
    
    inner_id
    name
    userUrl   
`;

export const fetchItem = async (item_id: number, chatType: ChatListType): Promise<ChatType> => {
    let graphql = `{ 
          ${chatType === 'users' ? 'fetchMessagesUser' : 'fetchMessagesAvito'}(item_id: ${item_id}){
            chat_id
            chatType
            messages {
                ${GQChatFields}
                sender {
                    ${GQChatUser}   
                }
            }
          }
        }`;

    switch (chatType) {
        case 'users':
            const dataUsers = await fetchApi.getGQ<{ fetchMessagesUser: ChatType }>(graphql);
            return dataUsers.fetchMessagesUser;

        case 'avito':
            const dataAvito = await fetchApi.getGQ<{ fetchMessagesAvito: ChatType }>(graphql);
            return dataAvito.fetchMessagesAvito;

        case 'whatsapp':
            graphql = `{ 
                  fetchChatMessagesWhatsapp(whatsapp_chat_id: ${item_id}){
                    chat_id
                    chatType
                    phone
                    messages {
                        ${GQWAMessage}    
                    }
                    waContact {
                        ${GQWhatsappContact}    
                    }
                  }
            }`;

            const dataWA = await fetchApi.getGQ<{ fetchChatMessagesWhatsapp: ChatType }>(graphql);
            return dataWA.fetchChatMessagesWhatsapp;

        default:
            const error: never = chatType;
    }
};

export const fetchWhatsappChatList = async (
    user_id: number,
    limit: number,
    offset: number,
    controller?: AbortController
): Promise<WhatsappChatTitle[]> => {
    const graphql = `{
          fetchWhatsappChatList(user_id: ${user_id}, limit: ${limit}, offset: ${offset}) {
            whatsapp_chat_id
            waContact {
                ${GQWhatsappContact}
            }
            phone
            lastMessage {
                message
                createTime
            }
            countUnreadMessages
            contact_id
            contact {
                ${GQMainContactFields}
            }
          }
        }`;

    const data: { fetchWhatsappChatList: WhatsappChatTitle[] } = await fetchApi.getGQ(graphql, controller);
    return data.fetchWhatsappChatList;
};

export const fetchList = async (
    limit: number,
    offset: number,
    orderBy: string,
    orderDirection: OrderDirectionType,
    { type, user_id }: ChatFilter,
    controller?: AbortController
): Promise<{ list: ChatTitle[]; count: number }> => {
    if (type === 'avito') {
        const graphql = `{
          fetchAvitoChatsList(user_id: ${user_id}, limit: ${limit}, offset: ${offset}) {
            avito_chat_id
            estate_id
            estate {
                ${GQEstateMainFields}
                avatarUrl
            }
            avitoUser {
                name
                avatarUrl
                externalUrl
            }
            lastMessage {
                ${GQChatFields}
            }
            countUnreadMessages
          }
        }`;

        const data: { fetchAvitoChatsList: ChatTitle[] } = await fetchApi.getGQ(graphql, controller);
        return { list: data.fetchAvitoChatsList, count: data.fetchAvitoChatsList.length };
    } else if (type === 'whatsapp') {
        const list = await fetchWhatsappChatList(user_id, limit, offset);
        return { list, count: list.length };
    } else {
        const graphql = `{
          fetchUserChatsList(user_id: ${user_id}, limit: ${limit}, offset: ${offset}) {
            chat_id
            user_id
            user {
                ${GQUserMainFields}
                onlineTime
            }
            lastMessage {
                ${GQChatFields}
            }
            countUnreadMessages
          }
        }`;

        const data: { fetchUserChatsList: ChatTitle[] } = await fetchApi.getGQ(graphql, controller);
        return { list: data.fetchUserChatsList, count: data.fetchUserChatsList.length };
    }
};

export const fetchNotReadMessages = async (): Promise<NotReadMessage[]> => {
    const graphql = `{
          fetchNotReadMessages {
            chat_id
            message_id
            message
            sender_id
            messageType
            sender {
                ${GQChatUser}   
            }
            estate_id
            avito_chat_id
            createTime
          }
        }`;

    const data: { fetchNotReadMessages: NotReadMessage[] } = await fetchApi.getGQ(graphql);
    return data.fetchNotReadMessages;
};

export const readChatMessage = async (message_id: number): Promise<number> => {
    const graphql = `mutation { 
          readChatMessage(message_id: ${message_id})
        }`;

    const data: { readChatMessage: number } = await fetchApi.postGQ(graphql);
    return data.readChatMessage;
};

export const readAllNotReadMessages = async (): Promise<boolean> => {
    const graphql = `mutation { 
          readAllNotReadMessages
        }`;

    const data: { readAllNotReadMessages: boolean } = await fetchApi.postGQ(graphql);
    return data.readAllNotReadMessages;
};

export const fetchUserChatId = async (user_id: number): Promise<number> => {
    const graphql = `{
          fetchUserChatId(user_id: ${user_id})
        }`;

    const data = await fetchApi.getGQ<{ fetchUserChatId: number }>(graphql);
    return data.fetchUserChatId;
};
