import {
    ESTATE_PROPERTY_TYPE_FLAT,
    ESTATE_PROPERTY_TYPE_NEWBUILDING,
    ESTATE_PROPERTY_TYPE_NEWBUILDING_CESSION,
    ESTATE_PROPERTY_TYPE_OFFICE,
    ESTATE_PROPERTY_TYPE_PART,
    ESTATE_PROPERTY_TYPE_ROOM,
    ESTATE_TYPE_RENT_ID,
    ESTATE_TYPE_SELL_ID,
    TRANSPORT_TYPE_TRANSPORT,
    TRANSPORT_TYPE_WALK,
    ESTATE_PROPERTY_TYPE_HOUSE,
    ESTATE_PROPERTY_TYPE_COTTAGE,
    ESTATE_PROPERTY_TYPE_TOWNHOUSE,
    ESTATE_PROPERTY_TYPE_HOUSE_PART,
    ESTATE_PROPERTY_TYPE_LAND,
    ESTATE_PROPERTY_TYPE_GARAGE,
    ESTATE_PROPERTY_TYPE_GARAGE_BOX,
    ESTATE_PROPERTY_TYPE_CAR_PLACEMENT,
    ESTATE_PROPERTY_TYPE_FREE_PURPOSE,
    ESTATE_PROPERTY_TYPE_COMMERCE_LAND,
    ESTATE_PROPERTY_TYPE_WAREHOUSE,
    ESTATE_PROPERTY_TYPE_SHOPPING,
    ESTATE_PROPERTY_TYPE_BUSINESS,
    ESTATE_PROPERTY_TYPE_INDUSTRY,
    ESTATE_PROPERTY_TYPE_BUILDING,
    AGENT_BONUS_TYPE_FIXED,
    AGENT_BONUS_TYPE_PERCENT
} from '~/types/estate.types';
import { proxyL10nHandler } from '~/common/localizations';

const ESTATE_DEAL_TYPE = [
    [ESTATE_TYPE_SELL_ID, 'Продажа'],
    [ESTATE_TYPE_RENT_ID, 'Аренда']
];

const PROPERTY_TYPE = [
    [ESTATE_PROPERTY_TYPE_FLAT, 'Квартира'],
    [ESTATE_PROPERTY_TYPE_NEWBUILDING_CESSION, 'Квартира в новостройке (переуступка)'],
    [ESTATE_PROPERTY_TYPE_NEWBUILDING, 'Квартира в новостройке (от застройщика)'],
    [ESTATE_PROPERTY_TYPE_ROOM, 'Комната'],
    [ESTATE_PROPERTY_TYPE_PART, 'Доля'],

    [ESTATE_PROPERTY_TYPE_HOUSE, 'Дом/Дача'],
    [ESTATE_PROPERTY_TYPE_COTTAGE, 'Коттедж'],
    [ESTATE_PROPERTY_TYPE_TOWNHOUSE, 'Таунхаус'],
    [ESTATE_PROPERTY_TYPE_HOUSE_PART, 'Часть дома'],
    [ESTATE_PROPERTY_TYPE_LAND, 'Участок'],

    [ESTATE_PROPERTY_TYPE_OFFICE, 'Офис'],
    [ESTATE_PROPERTY_TYPE_FREE_PURPOSE, 'Помещение свободного назначения'],
    [ESTATE_PROPERTY_TYPE_COMMERCE_LAND, 'Коммерческая земля'],
    [ESTATE_PROPERTY_TYPE_WAREHOUSE, 'Склад'],
    [ESTATE_PROPERTY_TYPE_SHOPPING, 'Торговая площадь'],
    [ESTATE_PROPERTY_TYPE_BUSINESS, 'Готовый бизнес'],
    [ESTATE_PROPERTY_TYPE_INDUSTRY, 'Производство'],
    [ESTATE_PROPERTY_TYPE_BUILDING, 'Здание'],

    [ESTATE_PROPERTY_TYPE_CAR_PLACEMENT, 'Машиноместо'],
    [ESTATE_PROPERTY_TYPE_GARAGE, 'Гараж'],
    [ESTATE_PROPERTY_TYPE_GARAGE_BOX, 'Гараж-бокс']
];

const PROPERTY_TYPE_GENETIVE = [
    [ESTATE_PROPERTY_TYPE_FLAT, 'Квартиру'],
    [ESTATE_PROPERTY_TYPE_NEWBUILDING, 'Новостройку (от застройщика)'],
    [ESTATE_PROPERTY_TYPE_NEWBUILDING_CESSION, 'Переуступку'],
    [ESTATE_PROPERTY_TYPE_ROOM, 'Комнату'],
    [ESTATE_PROPERTY_TYPE_PART, 'Долю'],

    [ESTATE_PROPERTY_TYPE_HOUSE, 'Дом/Дачу'],
    [ESTATE_PROPERTY_TYPE_COTTAGE, 'Коттедж'],
    [ESTATE_PROPERTY_TYPE_TOWNHOUSE, 'Таунхаус'],
    [ESTATE_PROPERTY_TYPE_HOUSE_PART, 'Часть дома'],
    [ESTATE_PROPERTY_TYPE_LAND, 'Участок'],

    [ESTATE_PROPERTY_TYPE_OFFICE, 'Офис'],
    [ESTATE_PROPERTY_TYPE_FREE_PURPOSE, 'Помещение свободного назначения'],
    [ESTATE_PROPERTY_TYPE_COMMERCE_LAND, 'Коммерческую земля'],
    [ESTATE_PROPERTY_TYPE_WAREHOUSE, 'Склад'],
    [ESTATE_PROPERTY_TYPE_SHOPPING, 'Торговую площадь'],
    [ESTATE_PROPERTY_TYPE_BUSINESS, 'Готовый бизнес'],
    [ESTATE_PROPERTY_TYPE_INDUSTRY, 'Производство'],
    [ESTATE_PROPERTY_TYPE_BUILDING, 'Здание'],

    [ESTATE_PROPERTY_TYPE_CAR_PLACEMENT, 'Машиноместо'],
    [ESTATE_PROPERTY_TYPE_GARAGE, 'Гараж'],
    [ESTATE_PROPERTY_TYPE_GARAGE_BOX, 'Гараж-бокс']
];

const ROOMS_COUNT = [
    [9, 'Студия'],
    [1, '1'],
    [2, '2'],
    [3, '3'],
    [4, '4'],
    [5, '5'],
    [6, '6+'],
    [7, 'Свобод.план.']
];

const ROOMS_COUNT_SHORT = [
    [9, 'Студия'],
    [1, '1 - комн.кв.'],
    [2, '2 - комн.кв.'],
    [3, '3 - комн.кв.'],
    [4, '4 - комн.кв.'],
    [5, '5 - комн.кв.'],
    [6, '6+ - комн.кв.'],
    [7, 'Свобод.план.']
];

const ROOMS_COUNT_LONG = [
    [9, 'Студия'],
    [1, '1-комнатная квартира'],
    [2, '2-комнатная квартира'],
    [3, '3-комнатная квартира'],
    [4, '4-комнатная квартира'],
    [5, '5-комнатная квартира'],
    [6, 'многокомнатная квартира'],
    [7, 'Свободная планировка']
];

const ROOMS_COUNT_LONG_FROM = [
    [1, '1-комнатной квартире'],
    [2, '2-комнатной квартире'],
    [3, '3-комнатной квартире'],
    [4, '4-комнатной квартире'],
    [5, '5-комнатной квартире'],
    [6, 'многокомнатной квартире'],
    [7, 'свободной планировке']
];

const HOUSE_MATERIAL_TYPES = [
    [1, 'Блочный'],
    [2, 'Щитовой'],
    [3, 'Кирпичный'],
    [4, 'Монолитный'],
    [5, 'Монолитно-кирпичный'],
    [6, 'Старый фонд'],
    [7, 'Панельный'],
    [8, 'Деревянный'],
    [9, 'Сталинский']
];

const SHOWING_TYPES = [
    [1, 'Внутренний'],
    /*[2, 'МЛС'], */ [3, 'Внешний']
];

const SALE_TYPES = [
    [1, 'Прямая продажа'],
    [2, 'Альтернатива']
];

const LEASE_TYPES = [
    [1, 'Долгосрочная аренда'],
    [2, 'Аренда несколько месяцев']
];

const HOUSE_PARKING_TYPES = [
    [4, 'Подземная'],
    [3, 'Охраняемая'],
    [2, 'Отдельная'],
    [1, 'Рядом'],
    [0, 'Нет']
];

const CURRENCY_TYPES = [
    [1, 'ք'],
    [2, '$'],
    [3, '€']
];

const REPAIR_TYPES = [
    [1, 'Косметический'],
    [2, 'Евро'],
    [3, 'Дизайнерский'],
    [0, 'Нет']
];

const TRANSPORT_TYPES = [
    [TRANSPORT_TYPE_WALK, 'пешком'],
    [TRANSPORT_TYPE_TRANSPORT, 'трансп.']
];

const PREPAYMENT_TYPES = [
    [0, 'Предоплаты нет'],
    [1, 'Предоплата за 1 месяц'],
    [2, 'Предоплата за 2 месяца'],
    [3, 'Предоплата за 3 месяца'],
    [4, 'Предоплата за 4 месяца'],
    [5, 'Предоплата за 5 месяцев'],
    [6, 'Предоплата за 6 месяцев']
];

const OFFICE_CONDITION_TYPE = [
    [1, 'Требуется косметический ремонт'],
    [2, 'Под чистовую отделку'],
    [3, 'Требуется капитальный ремонт'],
    [4, 'Офисная отделка']
];

const FREE_PURPOSE_CONDITION_TYPE = [
    [1, 'Требуется косметический ремонт'],
    [2, 'Дизайнерский ремонт'],
    [3, 'Под чистовую отделку'],
    [4, 'Требуется капитальный ремонт'],
    [5, 'Офисная отделка'],
    [6, 'Типовой ремонт']
];

const INDUSTRY_CONDITION_TYPE = [
    [1, 'Требуется косметический ремонт'],
    [3, 'Требуется капитальный ремонт'],
    [6, 'Типовой ремонт']
];

const SHOPPING_CONDITION_TYPE = [
    [1, 'Требуется косметический ремонт'],
    [2, 'Под чистовую отделку'],
    [3, 'Требуется капитальный ремонт']
];

const BUILDING_CONDITION_TYPE = [
    [1, 'Требуется косметический ремонт'],
    [2, 'Дизайнерский ремонт'],
    [3, 'Под чистовую отделку'],
    [4, 'Требуется капитальный ремонт'],
    [6, 'Типовой ремонт']
];

const FREE_PURPOSE_INPUT_TYPE = [
    [1, 'Общий с улицы'],
    [2, 'Общий со двора'],
    [3, 'Отдельный с улицы'],
    [4, 'Отдельный со двора']
];

const LAYOUTS = [
    [1, 'Кабинетная'],
    [2, 'Коридорная'],
    [3, 'Смешанная'],
    [4, 'Открытая']
];

const OFFICE_ACCESS_TYPES = [
    [1, 'Свободный'],
    [2, 'Пропускная система']
];

const OFFICE_PARKING_TYPE = [
    [1, 'Наземная'],
    [2, 'Многоуровневая'],
    [3, 'Открытая'],
    [4, 'На крыше'],
    [5, 'Подземная']
];

const OFFICE_BUILDING_TYPE = [
    [1, 'Административное здание'],
    [2, 'Бизнес-центр'],
    [3, 'Деловой центр'],
    [4, 'Деловой дом'],
    [5, 'Бизнес-парк'],
    [6, 'Бизнес-квартал'],
    [7, 'Деловой квартал'],
    [8, 'Объект свободного назначения'],
    [9, 'Производственный комплекс'],
    [10, 'Индустриальный парк'],
    [11, 'Промплощадка'],
    [12, 'Производственно-складской комплекс'],
    [13, 'Логистический центр'],
    [14, 'Логистический комплекс'],
    [15, 'Особняк'],
    [16, 'Производственное здание'],
    [17, 'Производственный цех'],
    [18, 'Модульное здание'],
    [19, 'Многофункциональный комплекс'],
    [20, 'Офисно-гостиничный комплекс'],
    [21, 'Офисно-жилой комплекс'],
    [22, 'Офисно-складское здание'],
    [23, 'Офисно-складской комплекс'],
    [24, 'Офисное здание'],
    [25, 'Офисный центр'],
    [26, 'Офисный комплекс'],
    [27, 'Офисно-производственный комплекс'],
    [28, 'Офисный квартал'],
    [29, 'Старый фонд'],
    [30, 'Другое'],
    [31, 'Аутлет'],
    [32, 'Имущественный комплекс'],
    [33, 'Жилой комплекс'],
    [34, 'Жилой дом'],
    [35, 'Торгово-деловой комплекс'],
    [36, 'Торгово-общественный центр'],
    [37, 'Торгово-развлекательный центр'],
    [38, 'Торговый центр'],
    [39, 'Торговый комплекс'],
    [40, 'Специализированный торговый центр'],
    [41, 'Отдельно стоящее здание'],
    [42, 'Технопарк'],
    [43, 'Торгово-выставочный комплекс'],
    [44, 'Торговый дом'],
    [45, 'Торгово-офисный комплекс'],
    [46, 'Склад'],
    [47, 'Складской комплекс']
];

const OFFICE_CLASS_TYPE = [
    [1, 'A'],
    [2, 'A+'],
    [3, 'B'],
    [4, 'B-'],
    [5, 'B+'],
    [6, 'C']
];

const LAND_AREA_TYPES = [
    [1, 'гектар'],
    [2, 'сотка']
];

const OFFICE_STATUS_TYPE = [
    [1, 'Действующее'],
    [2, 'Проект'],
    [3, 'Строящееся']
];

const OFFICE_VENTILATION_TYPE = [
    [1, 'Приточная'],
    [2, 'Естественная'],
    [3, 'Нет']
];

const OFFICE_CONDITIONING_TYPE = [
    [1, 'Центральное'],
    [2, 'Местное'],
    [3, 'Нет']
];

const OFFICE_HEATING_TYPE = [
    [1, 'Автономное'],
    [2, 'Центральное'],
    [3, 'Нет']
];

const OFFICE_EXTINGUISTING_SYSTEM_TYPE = [
    [1, 'Сигнализация'],
    [2, 'Газовая'],
    [3, 'Гидрантная'],
    [4, 'Нет'],
    [5, 'Порошковая'],
    [6, 'Спринклерная']
];

const WC_TYPE = [
    [0, 'Любой санузел'],
    [1, 'Совмещенный санузел'],
    [2, 'Раздельный санузел'],
    [3, 'Несколько санузлов']
];

const VAT_TYPES = [
    [1, 'НДС включен'],
    [2, 'НДС не включен'],
    [3, 'УСН (упрощенная система налогообложения)']
];

const COUNTRY_MATERIAL_TYPE = [
    [1, 'Деревянный'],
    [2, 'Кирпичный'],
    [3, 'Монолитный'],
    [4, 'Каркасный'],
    [5, 'Щитовой'],
    [6, 'Газосиликатный блок'],
    [7, 'Пенобетонный блок'],
    [8, 'Газобетонный блок']
];

const COUNTRY_HEATING_TYPE = [
    [1, 'Автономное газовое'],
    [2, 'Центральное угольное'],
    [3, 'Центральное газовое'],
    [4, 'Дизельное'],
    [5, 'Электрическое'],
    [6, 'Камин'],
    [7, 'Твердотопливный котел'],
    [8, 'Печь'],
    [9, 'Нет']
];

const LAND_AREA_STATUS_TYPE = [
    [1, 'Фермерское хозяйство'],
    [2, 'Садоводство'],
    [3, 'Индивидуальное жилищное строительство'],
    [4, 'Земля промышленного назначения'],
    [5, 'Инвестпроект'],
    [6, 'Личное подсобное хозяйство'],
    [7, 'Дачное некоммерческое партнерство']
];

const GARAGE_TYPE = [
    [1, 'Встроенный'],
    [2, 'Капитальный'],
    [3, 'Самострой'],
    [4, 'Ракушка']
];

const GARAGE_BOX_MATERIAL_TYPE = [
    [1, 'Кирпичный'],
    [2, 'Металлический']
];

const GARAGE_STATUS = [
    [1, 'По доверенности'],
    [2, 'Кооператив'],
    [3, 'Собственность']
];

const WC_COUNTRY_TYPE = [
    [1, 'На улице'],
    [2, 'В доме']
];

const COMMERCE_LAND_AREA_STATUS_TYPE = [
    [1, 'Участок сельскохозяйственного назначения'],
    [2, 'Участок промышленности, транспорта, связи и иного не сельхоз. назначения'],
    [3, 'Поселений']
];

const COMMERCE_PERMITTED_USE_TYPE = [
    [1, 'Cельскохозяйственное использование'],
    [2, 'Деловое управление'],
    [3, 'Общее пользование территории'],
    [4, 'Высотная застройка'],
    [5, 'Гостиничное обслуживание'],
    [6, 'Индивидуальное жилищное строительство (ИЖС)'],
    [7, 'Промышленность'],
    [8, 'Отдых (рекреация)'],
    [9, 'Малоэтажное жилищное строительство (МЖС)'],
    [10, 'Общественное использование объектов капитального строительства'],
    [11, 'Обслуживание автотранспорта'],
    [12, 'Торговые центры'],
    [13, 'Склады']
];

const ELECTRICITY_LOCATION_TYPE = [
    [1, 'По границе участка'],
    [2, 'На участке'],
    [3, 'Нет']
];

const GAS_PRESSURE_TYPE = [
    [1, 'Высокое'],
    [2, 'Низкое'],
    [3, 'Среднее']
];

const DRAINAGE_TYPE = [
    [1, 'Автономная'],
    [2, 'Центральная']
];

const COMMERCE_LAND_WATER_TYPE = [
    [1, 'Автономное'],
    [2, 'Центральное'],
    [3, 'Водонапорная станция'],
    [4, 'Водозаборный узел'],
    [5, 'Водонапорная башня']
];

const COMMERCE_LAND_DRIVEWAY_TYPE = [
    [1, 'Асфальтированная дорога'],
    [2, 'Грунтовая дорога'],
    [3, 'Нет']
];

const WAREHOUSE_FLOOR_MATERIAL_TYPE = [
    [1, 'Асфальт'],
    [2, 'Бетон'],
    [3, 'Ламинат'],
    [4, 'Линолеум'],
    [5, 'Полимерный'],
    [6, 'Железобетон'],
    [7, 'Наливной'],
    [8, 'Плитка'],
    [9, 'Деревянный']
];

const WAREHOUSE_LIFT_TYPE = [
    [1, 'Грузовой'],
    [2, 'Пассажирский'],
    [3, 'Тельфер']
];

const WAREHOUSE_CRANAGE_TYPE = [
    [1, 'Кран-балка'],
    [2, 'Козловой кран'],
    [3, 'Мостовой кран'],
    [4, 'Ж/д кран']
];

const WAREHOUSE_GATES_TYPE = [
    [1, 'На нулевой отметке'],
    [2, 'Докового типа'],
    [3, 'На пандусе']
];

const PARKING_LOCATION_TYPE = [
    [1, 'За территорией объекта'],
    [2, 'На территории объекта']
];

const PARKING_PURPOSE_TYPE = [
    [1, 'Для грузового транспорта'],
    [2, 'Для легковесного транспорта']
];

const SHOPPING_PLACEMENT_TYPE = [
    [1, 'Помещение в торговом комплексе'],
    [2, 'Street retail']
];

const SHOPPING_WORKING_DAYS = [
    [1, 'Ежедневно'],
    [2, 'Будни'],
    [3, 'Выходные']
];

const SHOPPING_OPENING_HOURS_TYPE = [
    [1, 'Круглосуточно'],
    [2, 'От/До']
];

const SHOPPING_CENTER_SCALE = [
    [1, 'Районный'],
    [2, 'Микрорайонный'],
    [3, 'Окружной'],
    [4, 'Региональный'],
    [5, 'Суперокружной'],
    [6, 'Суперрегиональный']
];

const SHOPPING_HOUSE_LINE_TYPE = [
    [1, 'Первая'],
    [2, 'Вторая'],
    [3, 'Иная']
];

const BUSINESS_PROPERTY_TYPE = [
    [1, 'Здание'],
    [2, 'Помещение свободного назначения'],
    [3, 'Гараж'],
    [4, 'Производство'],
    [5, 'Земля'],
    [6, 'Офис'],
    [7, 'Торговая площадь'],
    [8, 'Склад']
];

const BUSINESS_ESTATE_TYPE = [
    [1, 'В собственности'],
    [2, 'В аренде']
];

const EXPORT_FORMAT_NAME = [
    ['cian', 'Циан'],
    ['yandex', 'Яндекс недвижимость'],
    ['avito', 'Авито'],
    ['irr', 'Из Рук в Руки'],
    ['nebo', 'Небо']
];

const DECORATION_TYPES = [
    [1, 'Чистовая'],
    [2, 'Черновая'],
    [3, 'Без отделки']
];

const DELETE_STATUS = [
    [1, 'Продано'],
    [2, 'Собственник передумал продавать'],
    [3, 'Временно отложена продажа'],
    [4, 'Объект создан по ошибке']
];

const AGENT_BONUS = [
    [AGENT_BONUS_TYPE_FIXED, 'Фикс'],
    [AGENT_BONUS_TYPE_PERCENT, 'Процент']
];

const estateL10n = new Proxy({}, proxyL10nHandler);

estateL10n.HOUSE_MATERIAL_TYPES = HOUSE_MATERIAL_TYPES;
estateL10n.HOUSE_PARKING_TYPES = HOUSE_PARKING_TYPES;
estateL10n.CURRENCY_TYPES = CURRENCY_TYPES;
estateL10n.ROOMS_COUNT = ROOMS_COUNT;
estateL10n.ROOMS_COUNT_SHORT = ROOMS_COUNT_SHORT;
estateL10n.REPAIR_TYPES = REPAIR_TYPES;
estateL10n.ROOMS_COUNT_LONG = ROOMS_COUNT_LONG;
estateL10n.TRANSPORT_TYPES = TRANSPORT_TYPES;
estateL10n.SALE_TYPES = SALE_TYPES;
estateL10n.ROOMS_COUNT_LONG_FROM = ROOMS_COUNT_LONG_FROM;
estateL10n.LEASE_TYPES = LEASE_TYPES;
estateL10n.PREPAYMENT_TYPES = PREPAYMENT_TYPES;
estateL10n.PROPERTY_TYPE = PROPERTY_TYPE;
estateL10n.SHOWING_TYPES = SHOWING_TYPES;
estateL10n.LAYOUTS = LAYOUTS;
estateL10n.OFFICE_CONDITION_TYPE = OFFICE_CONDITION_TYPE;
estateL10n.OFFICE_ACCESS_TYPES = OFFICE_ACCESS_TYPES;
estateL10n.OFFICE_PARKING_TYPE = OFFICE_PARKING_TYPE;
estateL10n.OFFICE_BUILDING_TYPE = OFFICE_BUILDING_TYPE;
estateL10n.OFFICE_CLASS_TYPE = OFFICE_CLASS_TYPE;
estateL10n.LAND_AREA_TYPES = LAND_AREA_TYPES;
estateL10n.OFFICE_STATUS_TYPE = OFFICE_STATUS_TYPE;
estateL10n.OFFICE_VENTILATION_TYPE = OFFICE_VENTILATION_TYPE;
estateL10n.OFFICE_CONDITIONING_TYPE = OFFICE_CONDITIONING_TYPE;
estateL10n.OFFICE_HEATING_TYPE = OFFICE_HEATING_TYPE;
estateL10n.OFFICE_EXTINGUISTING_SYSTEM_TYPE = OFFICE_EXTINGUISTING_SYSTEM_TYPE;
estateL10n.VAT_TYPES = VAT_TYPES;
estateL10n.WC_TYPE = WC_TYPE;
estateL10n.PROPERTY_TYPE_GENETIVE = PROPERTY_TYPE_GENETIVE;
estateL10n.ESTATE_DEAL_TYPE = ESTATE_DEAL_TYPE;
estateL10n.COUNTRY_MATERIAL_TYPE = COUNTRY_MATERIAL_TYPE;
estateL10n.COUNTRY_HEATING_TYPE = COUNTRY_HEATING_TYPE;
estateL10n.LAND_AREA_STATUS_TYPE = LAND_AREA_STATUS_TYPE;
estateL10n.WC_COUNTRY_TYPE = WC_COUNTRY_TYPE;
estateL10n.GARAGE_TYPE = GARAGE_TYPE;
estateL10n.GARAGE_BOX_MATERIAL_TYPE = GARAGE_BOX_MATERIAL_TYPE;
estateL10n.GARAGE_STATUS = GARAGE_STATUS;
estateL10n.FREE_PURPOSE_CONDITION_TYPE = FREE_PURPOSE_CONDITION_TYPE;
estateL10n.FREE_PURPOSE_INPUT_TYPE = FREE_PURPOSE_INPUT_TYPE;
estateL10n.COMMERCE_LAND_AREA_STATUS_TYPE = COMMERCE_LAND_AREA_STATUS_TYPE;
estateL10n.COMMERCE_PERMITTED_USE_TYPE = COMMERCE_PERMITTED_USE_TYPE;
estateL10n.ELECTRICITY_LOCATION_TYPE = ELECTRICITY_LOCATION_TYPE;
estateL10n.GAS_PRESSURE_TYPE = GAS_PRESSURE_TYPE;
estateL10n.DRAINAGE_TYPE = DRAINAGE_TYPE;
estateL10n.COMMERCE_LAND_WATER_TYPE = COMMERCE_LAND_WATER_TYPE;
estateL10n.COMMERCE_LAND_DRIVEWAY_TYPE = COMMERCE_LAND_DRIVEWAY_TYPE;
estateL10n.WAREHOUSE_FLOOR_MATERIAL_TYPE = WAREHOUSE_FLOOR_MATERIAL_TYPE;
estateL10n.WAREHOUSE_LIFT_TYPE = WAREHOUSE_LIFT_TYPE;
estateL10n.WAREHOUSE_CRANAGE_TYPE = WAREHOUSE_CRANAGE_TYPE;
estateL10n.WAREHOUSE_GATES_TYPE = WAREHOUSE_GATES_TYPE;
estateL10n.PARKING_LOCATION_TYPE = PARKING_LOCATION_TYPE;
estateL10n.PARKING_PURPOSE_TYPE = PARKING_PURPOSE_TYPE;
estateL10n.SHOPPING_PLACEMENT_TYPE = SHOPPING_PLACEMENT_TYPE;
estateL10n.SHOPPING_CONDITION_TYPE = SHOPPING_CONDITION_TYPE;
estateL10n.SHOPPING_WORKING_DAYS = SHOPPING_WORKING_DAYS;
estateL10n.SHOPPING_OPENING_HOURS_TYPE = SHOPPING_OPENING_HOURS_TYPE;
estateL10n.SHOPPING_CENTER_SCALE = SHOPPING_CENTER_SCALE;
estateL10n.SHOPPING_HOUSE_LINE_TYPE = SHOPPING_HOUSE_LINE_TYPE;
estateL10n.BUSINESS_PROPERTY_TYPE = BUSINESS_PROPERTY_TYPE;
estateL10n.BUSINESS_ESTATE_TYPE = BUSINESS_ESTATE_TYPE;
estateL10n.INDUSTRY_CONDITION_TYPE = INDUSTRY_CONDITION_TYPE;
estateL10n.EXPORT_FORMAT_NAME = EXPORT_FORMAT_NAME;
estateL10n.DECORATION_TYPES = DECORATION_TYPES;
estateL10n.DELETE_STATUS = DELETE_STATUS;
estateL10n.AGENT_BONUS = AGENT_BONUS;
estateL10n.BUILDING_CONDITION_TYPE = BUILDING_CONDITION_TYPE;

export default estateL10n;
