export type SettingsProperty = {
    settings_key: string;
    value: string;
    enable: boolean;
};

export type AvitoApiType = {
    user_id: number;
    client_id: string;
    client_secret: string;
};
export type AvitoApiConfig = AvitoApiType[];
export const AVITO_API_KEY = 'avito_api_connect';

export type DomclickApiType = {
    token: string;
    reportUrl: string;
};
export type DomclickApiConfig = DomclickApiType[];
export const DOMCLICK_API_KEY = 'domclick_api_connect';

export type YandexApiType = {
    OAuth: string;
};
export type YandexApiConfig = YandexApiType[];
export const YANDEX_API_KEY = 'yandex_api_connect';

export type CianApiType = {
    apiKey: string;
};
export type CianApiConfig = CianApiType[];
export const CIAN_API_KEY = 'cian_api_connect';

export const CRM_SETTINGS_KEY = 'crm_main_settings';

export type CrmMainSettings = {
    enableFakeEstate: boolean;
    enableExpiredCalls: boolean;
    lastOutgoingCallTimesWarning: number | null;
    lastOutgoingCallTimesBan: number | null;
    enableCanonicalExportBasesId: boolean;
    CANONICAL_EXPORT_BASES_ID: number[];
    whetherNeedCallForOwnerAccess: boolean;
    enableWishBooking: boolean;
    wishBookingExpirationDays: number | null;
};

export const MAIN_EXPORT_SETTINGS_KEY = 'main_export_settings';

export type MainExportSettingsConfig = {
    enableOfficePhone: boolean;
    enableAgentPhone: boolean;
    firstPhoneToExport: 'agent' | 'office';
};
