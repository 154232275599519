import React, { Component, Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import { observer } from 'mobx-react';

import { FilePond, registerPlugin } from 'react-filepond';
import '../../../../../node_modules/filepond/dist/filepond.min.css';

import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import '../../../../../node_modules/filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

import '../../../../../node_modules/react-dropzone-component/styles/filepicker.css';
import '../../../../../node_modules/dropzone/dist/min/dropzone.min.css';

import estateStore from '~/stores/estateStore';
import { UploadingPhoto } from '~/types/estate.types';
import config from '../../../../config/ui.config';
import serverConfig from '../../../../config/server.config';
import { authHeaders } from '~/common/fetchApi';
import PreventDblClick from '../../../Base/PreventDblClick';
import { WarningMessage, MessageTitle } from '~ui/Message';
import TextInput from '~ui/TextInput';

import PhotosListBlockEditing from './PhotosListBlockEditing';

import FilePondPluginImageTransform from 'filepond-plugin-image-transform';

registerPlugin(FilePondPluginImagePreview, FilePondPluginImageResize, FilePondPluginImageTransform, FilePondPluginFileValidateType);

const clearYoutubeId = link => {
    let ID = '';
    const url = link.replace(/(>\|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    if (url[2] !== undefined) {
        ID = url[2].split(/[^0-9a-z_-]/i);
        ID = ID[0];
    }
    return ID;
};

const YOUTUBE_URL = 'https://www.youtube.com/watch?v=';

type PhotosBlockEditingProps = {
    estate_id: number;
    photos?: Array<UploadingPhoto>;
    youtubeId?: string | null;
};

const MAX_PHOTOS_COUNT = 20;

@observer
class PhotosBlockEditing extends Component<PhotosBlockEditingProps, { files: string[] }> {
    constructor(props: PhotosBlockEditingProps) {
        super(props);

        const estate = {
            estate_id: props.estate_id,
            photos: props.photos || [],
            youtubeId: props.youtubeId || null
        };
        estateStore.setEditingItem(estate.estate_id, estate);

        this.state = {
            files: []
        };
    }

    handleYoutubeChange = (event: React.SyntheticEvent, { value }: { value: string }) => {
        estateStore.setEditingItem(this.props.estate_id, { youtubeId: clearYoutubeId(value) });
    };

    onLoadFile = (error, file) => {
        if (error) {
            return;
        }
        const { serverId } = file;

        const { response }: { response: UploadingPhoto } = JSON.parse(serverId);
        response.watermark = config.estatePhotos.watermarks instanceof Array ? 0 : null;
        estateStore.addPhotoTemp(this.props.estate_id, response);
    };

    onProcessFiles = () => {
        // if (this.props.estate_id !== CREATING_ITEM_ID) {
        this.setState({ files: [] });
        // }
    };

    onUpdateFiles = fileItems => {
        this.setState({
            files: fileItems.map(fileItem => fileItem.file.name)
        });
    };

    render() {
        const { estate_id } = this.props;
        const { files } = this.state;
        const { youtubeId } = estateStore.getItem(estate_id).editingItem;

        return (
            <PreventDblClick>
                <br />
                <WarningMessage enableClose>
                    <MessageTitle>Внимание! Убедитесь, что:</MessageTitle>
                    <ul>
                        <li>На фотография отсутствуют посторонние водяные знаки или следы их ликвидации</li>
                        <li>На фотографиях нет людей</li>
                        <li>Все фотографии относятся ТОЛЬКО к данному объекту</li>
                        <li>На фотографиях отсутствует текст/надписи и фотографии не являются склейками/коллажами</li>
                    </ul>
                </WarningMessage>

                <FilePond
                    files={files}
                    allowMultiple
                    maxFiles={MAX_PHOTOS_COUNT}
                    server={{
                        url: serverConfig.photoUploadUrl,
                        process: {
                            method: 'POST',
                            withCredentials: false,
                            headers: authHeaders(),
                            timeout: 60000
                        }
                    }}
                    onupdatefiles={this.onUpdateFiles}
                    onactivatefile={file => console.log(file)}
                    name="file"
                    onprocessfile={this.onLoadFile}
                    onprocessfiles={this.onProcessFiles}
                    imageResizeTargetWidth={config.estatePhotos.sizes[0].width}
                    labelIdle={`<div data-filetype=".jpg" class="filepicker-file-icon"></div><div data-filetype=".jpeg" class="filepicker-file-icon"></div><div>Перенесите сюда фотографии или <span class="filepond--label-action"> Выберите в папке </span> Не более ${MAX_PHOTOS_COUNT} файлов за раз</div>`}
                    labelInvalidField={`Поле содержит недопустимые файлы`}
                    labelFileWaitingForSize={`Ожидание размера файла`}
                    labelFileSizeNotAvailable={`Размер файла недопустим`}
                    labelFileLoading={`Загрузка`}
                    labelFileLoadError={`Ошибка при загрузке`}
                    labelFileProcessing={`Отправка`}
                    labelFileProcessingComplete={`Отправка завершена`}
                    labelFileProcessingAborted={`Отправка прервана`}
                    labelFileProcessingError={`Ошибка при отправке`}
                    labelTapToCancel={`Нажмите для отмены`}
                    labelTapToRetry={`Нажмите для повтора`}
                    labelTapToUndo={`Нажмите для отката назад`}
                    labelButtonRemoveItem={`Удалить`}
                    labelButtonAbortItemLoad={`Прервать`}
                    labelButtonRetryItemLoad={`Повторить`}
                    labelButtonAbortItemProcessing={`Отменить`}
                    labelButtonUndoItemProcessing={`Откатить`}
                    labelButtonRetryItemProcessing={`Повторить`}
                    labelButtonProcessItem={`Отправить`}
                    maxParallelUploads={2}
                    imageTransformOutputQuality={100}
                    allowRevert={false}
                    allowRemove={false}
                    itemInsertLocation={'after'}
                    acceptedFileTypes={['image/*']}
                    labelFileTypeNotAllowed="Неверный формат"
                    fileValidateTypeLabelExpectedTypes=""
                />

                <PhotosListBlockEditing estate_id={estate_id} />

                <div className="crm-Item__editingMode">
                    <Grid container alignItems="center">
                        <Grid item xs={4}>
                            <label className="crm-Estate__field_label">Ролик YouTube</label>
                        </Grid>
                        <Grid item xs={8}>
                            <TextInput
                                type="text"
                                label="Ссылка на ролик в YouTube"
                                size="small"
                                value={youtubeId && youtubeId.length > 0 ? `${YOUTUBE_URL}${youtubeId}` : ''}
                                name="buildYear"
                                onChange={this.handleYoutubeChange}
                                fullWidth
                                variant="outlined"
                            />
                        </Grid>
                    </Grid>
                </div>
            </PreventDblClick>
        );
    }
}

export default PhotosBlockEditing;
