import React, { Component, Fragment, ChangeEvent } from 'react';
import DropzoneComponent from 'react-dropzone-component';
import { Modal, ModalActions } from '../Base/ui/Modal';

import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import LinkIcon from '@material-ui/icons/Link';

import commonStore from '~/stores/commonStore';
import config from '../../config/ui.config';
import { authHeaders } from '../../common/fetchApi';
import { observer } from 'mobx-react';
import learningStore from '~/stores/learningStore';
import { LearningMaterial, LearningMaterialFileTemp } from '~/types/learning.types';
import HandlerEditingBlockWrapper, { HandlerEditingWrappedProps } from '../Items/HandlerEditingBlockWrapper';
import { checkFormValid } from '../../common/forms';
import ListError from '../ListError';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import deepCopy from '../../common/deepCopy';
import Progress from '../Base/ui/Progress';

const DZC: any = DropzoneComponent;

type LearningAddModalProps = HandlerEditingWrappedProps & {
    handleClose: () => void;
};

const componentConfig = {
    showFiletypeIcon: false,
    postUrl: config.serverUrl + '/uploadLearningMaterialsHandler'
};

const eventHandlers = (item_id: number) => ({
    drop: () => {},

    success: (file: File, { response }: { response: LearningMaterialFileTemp }) => {
        learningStore.addTempFile(response, item_id);
    }
});

const djsConfig = (item_id: number) => ({
    autoProcessQueue: true,
    addRemoveLinks: false,
    clickable: true,
    dictDefaultMessage: 'перетащите материал(ы) сюда',
    chunksUploaded: () => {
        console.log('chunksUploaded');
    },
    headers: authHeaders(),
    params: {
        item_id
    }
});

@observer
class LearningAddModal extends Component<LearningAddModalProps> {
    constructor(props: LearningAddModalProps) {
        super(props);
        const { item_id } = props;

        const item = {};

        let learningMaterialEditing: Partial<LearningMaterial> = {
            title: '',
            description: null,
            url: null,
            enable: true,
            tempFiles: []
        };

        if (item_id !== CREATING_ITEM_ID) {
            learningMaterialEditing = learningStore.findById(item_id);
            item['item'] = deepCopy(learningMaterialEditing);
        }

        learningStore.setItem(props.item_id, {
            ...item,
            property: {},
            history: {},
            editingItem: learningMaterialEditing,
            loadingItem: false,
            errors: []
        });
    }

    handleChangeTitle = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        this.props.handleChange(event, { value: value.slice(0, 250), name: 'title' });
    };

    handleChangeDescription = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.currentTarget;
        this.props.handleChange(event, { value, name: 'description' });
    };

    handleChangeUrl = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.currentTarget;
        this.props.handleChange(event, { value, name: 'url' });
    };

    $form: HTMLFormElement;

    handleSubmit = async () => {
        const { item_id } = this.props;

        if (checkFormValid(this.$form, true)) {
            if (item_id === CREATING_ITEM_ID) {
                if (await learningStore.createItem()) {
                    this.props.handleClose();
                }
            } else {
                if (await learningStore.saveItem(item_id)) {
                    this.props.handleClose();
                }
            }
        }
    };

    render() {
        const { handleClose, item_id } = this.props;
        const {
            editingItem: { title, description, url },
            loadingItem,
            errors
        } = learningStore.getItem(item_id);

        return (
            <Modal onClose={!loadingItem ? handleClose : null} fullScreen={commonStore.isMobile} header="Загрузка обучаещего материала">
                <form ref={$form => (this.$form = $form)}>
                    <DialogContent>
                        <DialogContentText>
                            Добавьте новый обучающий материал. Это может быть как ссылка, так и прикрепленные файлы любого формата.
                        </DialogContentText>

                        <ListError errors={errors} />

                        <TextField
                            autoFocus
                            margin="normal"
                            label="Заголовок"
                            fullWidth
                            required
                            value={title}
                            onChange={this.handleChangeTitle}
                            error={!title}
                        />

                        <TextField
                            label="Описание"
                            multiline
                            rowsMax="4"
                            rows={2}
                            margin="normal"
                            fullWidth
                            value={description || ''}
                            onChange={this.handleChangeDescription}
                        />

                        <TextField
                            label="Ссылка на материалы"
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <LinkIcon />
                                    </InputAdornment>
                                )
                            }}
                            margin="normal"
                            value={url || ''}
                            onChange={this.handleChangeUrl}
                            type="url"
                            error={url && !url.match(/^https?:\/\//)}
                        />

                        <DZC config={componentConfig} eventHandlers={eventHandlers(item_id)} djsConfig={djsConfig(item_id)} />
                    </DialogContent>

                    <ModalActions>
                        <Button disabled={loadingItem} onClick={handleClose} color="secondary">
                            Отменить
                        </Button>
                        <div style={{ position: 'relative' }}>
                            <Button disabled={loadingItem} variant="outlined" onClick={this.handleSubmit} color="primary">
                                {item_id === CREATING_ITEM_ID ? 'Добавить' : 'Сохранить'}
                            </Button>

                            <Progress show={loadingItem} size={24} />
                        </div>
                    </ModalActions>
                </form>
            </Modal>
        );
    }
}

export default HandlerEditingBlockWrapper(learningStore, LearningAddModal);
