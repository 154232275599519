import React, { Fragment, useState, ReactElement } from 'react';
import { observer } from 'mobx-react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

import commonStore from '~/stores/commonStore';

import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import Popover from '@material-ui/core/Popover';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import ErrorIcon from '@material-ui/icons/Error';
import { InfoMessage, ErrorMessage, WarningMessage } from '~ui/Message';
import Progress from '~ui/Progress';

import OutsideClick from '~/components/Base/OutsideClick';
import whatsappStore from '~/stores/whatsappStore';
import authStore from '~/stores/authStore';

import HeaderAnimatedIconButton from '~/components/Header/Notifications/HeaderAnimatedIconButton';
import { adjustPhoneNumber } from '~/components/Base/PhonesList';

const WhatsappQrCode = ({ QRCode }: { QRCode: string }) => {
    const sizePx = 228;

    return (
        <>
            <InfoMessage>QR код для подключения WhatsApp</InfoMessage>
            <div
                style={{
                    background: `url('${QRCode}')`,
                    width: sizePx,
                    minWidth: sizePx,
                    maxWidth: sizePx,
                    height: sizePx,
                    minHeight: sizePx,
                    maxHeight: sizePx,
                    margin: '0 auto'
                }}
            />
        </>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        error: {
            backgroundColor: theme.palette.error.dark
        },
        message: {
            display: 'flex',
            alignItems: 'center'
        },
        icon: {
            fontSize: 20,
            opacity: 0.9,
            marginRight: theme.spacing(1)
        }
    })
);

const WhatsappError = ({ error }: { error: string }) => {
    const classes = useStyles();

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}
            open
            autoHideDuration={6000}
        >
            <SnackbarContent
                className={classes['error']}
                message={
                    <span className={classes.message}>
                        <ErrorIcon className={classes.icon} />
                        {error}
                    </span>
                }
            />
        </Snackbar>
    );
};

const HeaderWhatsappStatus = () => {
    const [$triggerRef, setRef] = useState<HTMLElement | null>(null);
    const [popupIsOpen, setOpenPopup] = useState(false);
    const toggleOpenPopup = () => {
        setOpenPopup(!popupIsOpen);
    };

    const { whatsappQRCode, whatsappStatus, whatsappConnectedPhone, whatsappError } = whatsappStore;

    // null выводим после useState, иначе может быть ошибка
    if (commonStore.isMobile || !authStore.currentUser.whatsappIsEnable) {
        return null;
    }

    let WAElement: ReactElement = null;

    switch (whatsappStatus) {
        case 'init':
            WAElement = (
                <ErrorMessage header="WhatsApp">
                    Ожидание qr кода <Progress show linear color="secondary" />
                </ErrorMessage>
            );
            break;
        case 'qr':
            WAElement = <WhatsappQrCode QRCode={whatsappQRCode} />;
            break;
        case 'ready':
            WAElement = <InfoMessage header="WhatsApp">Подключен номер {adjustPhoneNumber(whatsappConnectedPhone || '')}</InfoMessage>;
            break;
        case 'phoneNotConnected':
            WAElement = <InfoMessage header="WhatsApp">Телефон с WhatsApp не подключен к интернет</InfoMessage>;
            break;
        case 'authInProgress':
            WAElement = (
                <InfoMessage header="WhatsApp">
                    Подключение к WhatsApp
                    <Progress show linear color="secondary" />
                </InfoMessage>
            );
            break;
        case 'phoneNotMatched':
            WAElement = (
                <ErrorMessage header="WhatsApp">
                    Номер {adjustPhoneNumber(whatsappConnectedPhone || '')} не соответсвует привязанному к вашему профилю
                </ErrorMessage>
            );
            break;
        case 'disconnect':
            WAElement = <WarningMessage header="WhatsApp">Пропало соединение с Whatsapp</WarningMessage>;
            break;
        default:
            const error: never = whatsappStatus;
    }

    return (
        <Fragment>
            {whatsappError && <WhatsappError error={whatsappError} />}

            <HeaderAnimatedIconButton
                defaultColor={whatsappStatus === 'ready' ? 'primary' : 'secondary'}
                count={whatsappQRCode ? '!' : 0}
                icon={<WhatsAppIcon />}
                onToggle={toggleOpenPopup}
                setRef={setRef}
            />

            <Popover
                open={popupIsOpen}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                anchorEl={$triggerRef}
            >
                <OutsideClick onOutsideClick={toggleOpenPopup} style={{ padding: '1em' }}>
                    <div>{WAElement}</div>
                </OutsideClick>
            </Popover>
        </Fragment>
    );
};

export default observer(HeaderWhatsappStatus);
