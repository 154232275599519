import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Card, Grid, Header, Label, Placeholder } from 'semantic-ui-react';

import EditingBlockWrapper from '../../EditingBlockWrapper';
import dealStore from '~/stores/dealStore';

import ShadowFragment from '../../../Base/ShadowFragment';
import DealPriceEditing, { deadTimeTitle } from '../EditBlocks/DealPriceEditing';

import { EditingWrappedProps } from '../../EditingBlockWrapper';
import estateL10n from '../../../../L10n/estate.L10n';
import { ESTATE_TYPE_RENT_ID, ESTATE_TYPE_SELL_ID } from '~/types/estate.types';
import { DEAL_TYPE_EXCLUSIVE_ID, DEAL_TYPE_MORTGAGE_ID } from '~/types/deals.types';

@observer
class DealPriceShowing extends Component<EditingWrappedProps> {
    componentDidMount() {
        dealStore.fetchBanks();
    }

    render() {
        const { item_id, editing } = this.props;
        const { item, loadingItem } = dealStore.getItem(item_id);

        if (!item || loadingItem) {
            return (
                <Placeholder>
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                </Placeholder>
            );
        }

        const {
            price,
            type,
            currency,
            deposit,
            depositDeadTime,
            exclusiveDeadTime,
            depositTime,
            isMortgage,
            bank_id,
            mortgagePercent,
            mortgageDuration,
            firstDeposit
        } = item;

        const { banks } = dealStore;
        const foundBank = banks.find(({ value }) => value === bank_id);

        const DEAD_TIME_TITLE = deadTimeTitle(type);

        return (
            <Card fluid color="green">
                <Card.Content>
                    <Header size="tiny">Стоимость (+Аванс)</Header>
                    {!editing && (
                        <Fragment>
                            <Grid>
                                <Grid.Row stretched>
                                    <Grid.Column width={7}>Стоимость объекта</Grid.Column>
                                    <Grid.Column width={9}>
                                        <ShadowFragment
                                            shadow={loadingItem}
                                            print={price && `${price.toLocaleString()} ${estateL10n.CURRENCY_TYPES_FIND(currency)}`}
                                        />
                                        {type === ESTATE_TYPE_RENT_ID && ` / в месяц`}
                                    </Grid.Column>
                                    {![DEAL_TYPE_EXCLUSIVE_ID, DEAL_TYPE_MORTGAGE_ID].includes(type) && (
                                        <Fragment>
                                            <Grid.Column width={7}>Аванс</Grid.Column>
                                            <Grid.Column width={9}>
                                                {!deposit && (
                                                    <Label horizontal color="red" size="tiny">
                                                        Аванс не внесен
                                                    </Label>
                                                )}
                                                {deposit && `${deposit.toLocaleString()} ${estateL10n.CURRENCY_TYPES_FIND(1)}`}
                                                {depositTime && (
                                                    <Fragment>&nbsp;внесен {new Date(depositTime * 1000).toLocaleDateString()}</Fragment>
                                                )}
                                            </Grid.Column>
                                        </Fragment>
                                    )}
                                    {type === ESTATE_TYPE_SELL_ID && depositDeadTime && (
                                        <Fragment>
                                            <Grid.Column width={7}>{DEAD_TIME_TITLE}</Grid.Column>
                                            <Grid.Column width={9}>{new Date(depositDeadTime * 1000).toLocaleDateString()}</Grid.Column>
                                        </Fragment>
                                    )}
                                    {[DEAL_TYPE_EXCLUSIVE_ID, DEAL_TYPE_MORTGAGE_ID].includes(type) && exclusiveDeadTime && (
                                        <Fragment>
                                            <Grid.Column width={7}>{DEAD_TIME_TITLE}</Grid.Column>
                                            <Grid.Column width={9}>{new Date(exclusiveDeadTime * 1000).toLocaleDateString()}</Grid.Column>
                                        </Fragment>
                                    )}
                                </Grid.Row>
                            </Grid>
                        </Fragment>
                    )}
                    {editing && <DealPriceEditing item_id={item_id} editing={editing} {...item} />}
                </Card.Content>
                {isMortgage && !editing && (
                    <Card.Content>
                        <Header size="tiny">Ипотека{foundBank && <> ({foundBank.text})</>}</Header>
                        <Grid>
                            <Grid.Row stretched>
                                <Grid.Column width={7}>Ставка</Grid.Column>
                                <Grid.Column width={9}>
                                    <ShadowFragment shadow={loadingItem} print={mortgagePercent && `${mortgagePercent}, %`} />
                                </Grid.Column>
                                <Grid.Column width={7}>Срок</Grid.Column>
                                <Grid.Column width={9}>
                                    <ShadowFragment shadow={loadingItem} print={mortgageDuration && `${mortgageDuration} лет`} />
                                </Grid.Column>
                                <Grid.Column width={7}>Перв. Взнос</Grid.Column>
                                <Grid.Column width={9}>
                                    <ShadowFragment shadow={loadingItem} print={firstDeposit && `${firstDeposit.toLocaleString()} ք`} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Card.Content>
                )}
            </Card>
        );
    }
}

export default EditingBlockWrapper(dealStore, DealPriceShowing);
