import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Card, Button, Icon, Divider, Header, SemanticICONS, SemanticCOLORS } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import contactStore from '~/stores/contactStore';
import InsertedItemsList from './SubBlocks/InsertedItemsList';
import estateStore from '~/stores/estateStore';
import showingStore from '~/stores/showingStore';
import requestStore from '~/stores/requestStore';
import dealStore from '~/stores/dealStore';
import wishStore from '~/stores/wishStore';
import meetingEventStore from '~/stores/meetingEventStore';
import { ListStoreInterface } from '~/stores/prototypes/ListStore.prototype';
import LoaderAwait from '../../../Base/LoaderAwait';

type InsertedItemsWrapperProps = {
    item_id: number;
    module: ListStoreInterface;
    title: string;
    color: SemanticCOLORS;
    icon: SemanticICONS;
    createPath: string | null;
    emptySetTitle: string;
    creatingTitle: string;
};

type InsertedItemsWrapperState = {
    item_id: number;
    module: ListStoreInterface;
};

@observer
class InsertedItemsWrapper extends Component<InsertedItemsWrapperProps, InsertedItemsWrapperState> {
    state = {
        item_id: 0,
        module: estateStore
    };

    static getDerivedStateFromProps(props: InsertedItemsWrapperProps, state: InsertedItemsWrapperState) {
        const { item_id, module } = props;

        if (item_id !== state.item_id || module !== state.module) {
            switch (module) {
                case estateStore:
                    contactStore.estatesByContact(item_id);
                    break;
                case showingStore:
                    contactStore.showingsByContact(item_id);
                    break;
                case requestStore:
                    contactStore.requestsByContact(item_id);
                    break;
                case dealStore:
                    contactStore.dealsByContact(item_id);
                    break;
                case wishStore:
                    contactStore.wishsByContact(item_id);
                    break;
                case meetingEventStore:
                    contactStore.meetingEventsByContact(item_id);
                    break;
                default:
                    break;
            }
        }

        return {
            item_id,
            module
        };
    }

    render() {
        const { item_id, module, title, color, icon, createPath, emptySetTitle, creatingTitle } = this.props;
        const property = contactStore.getItem(item_id).property;

        let items = [];
        let itemsLoading = false;

        switch (module) {
            case estateStore:
                items = property.estates;
                itemsLoading = property.estatesLoading;
                break;
            case showingStore:
                items = property.showings;
                itemsLoading = property.showingsLoading;
                break;
            case requestStore:
                items = property.requests;
                itemsLoading = property.requestsLoading;
                break;
            case dealStore:
                items = property.deals;
                itemsLoading = property.wishesLoading;
                break;
            case wishStore:
                items = property.wishes;
                itemsLoading = property.wishesLoading;
                break;
            case meetingEventStore:
                items = property.meetingEvents;
                itemsLoading = property.meetingEventsLoading;
                break;
            default:
                return null;
        }

        if (!itemsLoading && !(items instanceof Array)) {
            return null;
        }

        return (
            <Fragment>
                <Card.Content className="crm-Contacts__itemList_cardContent">
                    <Card.Header>
                        {createPath && (
                            <Button
                                size="tiny"
                                animated
                                color={color}
                                title={creatingTitle}
                                as={Link}
                                to={{
                                    pathname: createPath,
                                    state: {
                                        modal: true,
                                        from: { storeName: 'contactStore', item_id },
                                        callback: { storeName: 'contactStore', method: module.moduleName + 'sByContact', item_id }
                                    }
                                }}
                                floated="right"
                            >
                                <Button.Content hidden>
                                    <Icon name={icon} />
                                </Button.Content>
                                <Button.Content visible>
                                    <Icon name="plus" />
                                </Button.Content>
                            </Button>
                        )}

                        <Divider horizontal>
                            <Header as="h5" className="crm-Item__listDivider">
                                <Icon name={icon} color={color} />
                                {title}
                            </Header>
                        </Divider>
                    </Card.Header>
                    <Card.Description>
                        <InsertedItemsList
                            items={items}
                            itemsLoading={itemsLoading}
                            icon={icon}
                            module={module}
                            emptySetTitle={emptySetTitle}
                        />
                    </Card.Description>
                    <LoaderAwait active={itemsLoading} size="tiny" />
                </Card.Content>
            </Fragment>
        );
    }
}

export default InsertedItemsWrapper;
