import React, { useState, useMemo } from 'react';
import { observer } from 'mobx-react';

import kpiStore from '~/stores/kpiStore';
import statisticsL10n from '~/L10n/statistics.L10n';

import ListError from '~/components/ListError';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import LoaderAwait from '~/components/Base/LoaderAwait';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';

import KpiItemPointEditing from './KpiItemPointEditing';
import KpiItemEditing from './KpiItemEditing';

const KpiItemListEditing = ({ kpi_id }: { kpi_id: number }) => {
    useMemo(() => {
        kpiStore.fetchItem(kpi_id);
    }, [kpi_id]);

    const { loadingItem, errors, item } = kpiStore.getItem(kpi_id);

    const [editingPointId, setEditingPointId] = useState<number | null>(null);

    return (
        <List dense disablePadding>
            <ListError errors={errors} />

            <KpiItemEditing kpi_id={kpi_id} />

            {item?.points
                .filter(({ enable }) => enable)
                .map(({ point_id, pointType, weight, days }) =>
                    editingPointId !== point_id ? (
                        <ListItem key={point_id}>
                            <ListItemText
                                primary={`${statisticsL10n.KPI_POINT_TYPE_FIND(pointType)} (Дней: ${days})`}
                                secondary={`Вес: ${weight}`}
                            />
                            <ListItemSecondaryAction>
                                <Tooltip title="Редактировать">
                                    <IconButton edge="end" onClick={() => setEditingPointId(point_id)}>
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>
                            </ListItemSecondaryAction>
                        </ListItem>
                    ) : (
                        <KpiItemPointEditing
                            key={editingPointId}
                            kpi_id={kpi_id}
                            point_id={editingPointId}
                            onClose={() => setEditingPointId(null)}
                        />
                    )
                )}

            {editingPointId === CREATING_ITEM_ID && (
                <KpiItemPointEditing kpi_id={kpi_id} point_id={editingPointId} onClose={() => setEditingPointId(null)} />
            )}

            {!loadingItem && editingPointId !== CREATING_ITEM_ID && (
                <ListItem dense disableGutters>
                    <Button color="secondary" variant="text" startIcon={<AddIcon />} onClick={() => setEditingPointId(CREATING_ITEM_ID)}>
                        Добавить показатель
                    </Button>
                </ListItem>
            )}

            <LoaderAwait active={loadingItem} dimmer />
        </List>
    );
};

export default observer(KpiItemListEditing);
