import React, { Fragment, MouseEvent, useState, useMemo, useEffect, PureComponent, ReactNode } from 'react';
import { useParams } from 'react-router-dom';
import history from '~/history';
import { observer } from 'mobx-react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import commonStore from '~/stores/commonStore';
import exportStatisticsStore from '~/stores/export/exportStatisticsStore';

import Paper from '@material-ui/core/Paper';
import ToggleButton from '@material-ui/lab/ToggleButton';
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';

import Export from './Export';
import ExportReportsTable from './ExportReportsTable';
import authStore from '~/stores/authStore';
import settingStore from '~/stores/settingStore';
import { ACCESS_COMPANY, ACCESS_READ } from '~/types/access.types';
import Scrollbar from '../Base/Scrollbar';
import ExportStatisticsShow from './Export/ExportStatisticsShow';
import { StyledToggleButtonGroup, PultDivider, PultGrow } from '~ui/FilterPult';
import ExportReportsFilter from '~/components/Ads/ExportReportsTable/ExportReportsFilter';
import Button from '~ui/Button';
import SettingsIcon from '@material-ui/icons/Settings';

import AdsSettingsModal from './AdsSettingsModal';
import ExportStatisticsFilter from './ExportStatisticsFilter';
import GlobalError from '~/components/Base/GlobalError';

const useStyles = makeStyles(({ spacing }: Theme) => ({
    appBar: {
        width: '100%',
        marginLeft: 0,
        padding: spacing(1, 0),
        marginBottom: spacing(1)
    },
    container: {
        marginTop: '1em',
        marginBottom: '1em'
    }
}));

const TAB_STAT = 'stat';
const TAB_PRICE = 'price';
const TAB_REPORTS = 'reports';

const TABS_ARR = [TAB_STAT, TAB_PRICE, TAB_REPORTS] as const;
type TABS_ENUM = typeof TABS_ARR[number];

const Ads = () => {
    const { isMobile } = commonStore;
    const { param }: { param: TABS_ENUM } = useParams();

    const classes = useStyles({});
    const [activeTab, setActiveTab] = useState<TABS_ENUM>(param || TAB_STAT);
    const handleActiveTab = (event: MouseEvent, activeTab: TABS_ENUM) => {
        setActiveTab(activeTab);
        history.push(`/ads/${activeTab}`);
    };

    useEffect(() => {
        // Если через роутер перешли:
        if (param !== activeTab) {
            setActiveTab(param);
        }
    }, [param]);

    const [showModal, setShowModal] = useState(false);
    const toggleModal = () => {
        setShowModal(!showModal);
    };

    const canEdit = authStore.getPermission(settingStore.moduleName, ACCESS_READ) === ACCESS_COMPANY;
    const canShowFeeds = authStore.getPermission(exportStatisticsStore.moduleName, ACCESS_READ) === ACCESS_COMPANY;

    return (
        <Scrollbar disableOnMobile suppressScrollX>
            <Paper className={classes.appBar}>
                <Toolbar variant="dense">
                    {!isMobile && (
                        <StyledToggleButtonGroup exclusive size="small" value={activeTab} onChange={handleActiveTab}>
                            <ToggleButton value={TAB_STAT}>Статистика</ToggleButton>
                            <ToggleButton value={TAB_REPORTS}>Отчеты</ToggleButton>
                            {canShowFeeds && <ToggleButton value={TAB_PRICE}>Цены / Фиды</ToggleButton>}
                        </StyledToggleButtonGroup>
                    )}

                    {activeTab === TAB_STAT && (
                        <>
                            {!isMobile && <PultDivider />}
                            <ExportStatisticsFilter />
                        </>
                    )}

                    {activeTab === TAB_REPORTS && (
                        <>
                            <PultDivider />
                            <ExportReportsFilter />
                        </>
                    )}

                    <PultGrow />

                    {canEdit && <Button icon={<SettingsIcon />} color="inherit" tooltip="Настройка баз" onClick={toggleModal} />}
                    {showModal && <AdsSettingsModal onClose={toggleModal} />}
                </Toolbar>
            </Paper>

            <Container maxWidth="lg" fixed className={classes.container}>
                {activeTab === TAB_STAT && <ExportStatisticsShow />}

                {activeTab === TAB_PRICE && (
                    <Paper>
                        <Export />
                    </Paper>
                )}

                {activeTab === TAB_REPORTS && <ExportReportsTable />}
            </Container>
        </Scrollbar>
    );
};

export default observer(Ads);
