import React, { Component, Fragment } from 'react';
import estateStore from '~/stores/estateStore';
import ItemHistoryModeWrapper from '../ItemHistoryModeWrapper';
import { ReactNode } from 'react';
import estateL10n from '../../../L10n/estate.L10n';
import config from '../../../config/ui.config';
import { nl2br } from '~/api/commentsApi';
import deepCopy from '~/common/deepCopy';

const estateKeys = {
    enable: 'Архив',
    lastName: 'Фамилия',
    middleName: 'Отчество',
    firstName: 'Имя',
    companyName: 'Название компании',

    about: 'Описание',
    phones: 'Телефон(ы)',

    contact_id: 'Номер контакта',
    updateTime: 'Время обновления',

    address: 'Адрес',
    fias: 'Код ФИАС',
    kladr: 'КЛАДР',
    geo_lat: 'Широта',
    geo_lon: 'Долгота',

    street: 'Улица',
    streetType: 'Тип улицы',
    houseNumber: '№ дома',
    houseNumberType: 'Тип № дома',
    blockNumber: '№ блока',
    blockNumberType: 'Тип № блока',

    town: 'Город',
    townType: 'Тип города',
    country: 'Страна',
    region: 'Регион',
    regionType: 'Тип региона',
    district: 'Район',
    districtType: 'Тип района',
    cityDistrict: 'Район города',
    cityDistrictType: 'Тип района города',
    settlement: 'Поселение',
    settlementType: 'Тип поселения',
    postcode: 'Почтовый код',

    flatNumber: '№ квартиры',

    estate_id: 'estate_id',
    type: 'Тип',
    roomsCount: 'Количество комнат',
    propertyType: 'Тип недвижимости',

    minArea: 'Минимальная площадь',
    conditionType: 'Состояние',
    isOccupied: 'Занято',
    layout: 'Планировка',
    furniturePresence: 'Мебель',
    isLegalAddressProvided: 'Юридический адрес',
    availableFrom: 'Дата освобождения',
    waterPipesCount: 'Количество мокрых точек (водопровод)',
    taxNumber: 'Номер налоговой',

    parkingType: 'Тип парковки',
    parkingPlacesCount: 'Количество мест',
    parkingPriceMonthly: 'Стоимость в месяц за место',
    parkingCurrency: 'Валюта парковка',
    parkingIsFree: 'Бесплатная',

    totalAreaBuilding: 'Общая площадь здания',
    buildingName: 'Название',
    buildingType: 'Тип здания',
    buildingClassType: 'Класс',
    developer: 'Застройщик',
    managementCompany: 'Управляющая компания',
    ventilationType: 'Вентиляция',
    conditioningType: 'Кондиционирование',
    extinguishingSystemType: 'Система пожаротушения',
    statusType: 'Категория',
    accessType: 'Вход',
    businesshouse_id: 'businesshouse_id',
    ceilingHeight: 'Высота потолков',

    hasCarWash: '',
    hasBuffet: '',
    hasCarService: '',
    hasCanteen: '',
    hasCentralReception: '',
    hasHotel: '',
    hasAtm: '',
    hasExhibitionAndWarehouseComplex: '',
    hasPharmacy: '',
    hasBankDepartment: '',
    hasCinema: '',
    hasCafe: '',
    hasMedicalCenter: '',
    hasBeautyShop: '',
    hasStudio: '',
    hasNotaryOffice: '',
    hasClothesStudio: '',
    hasWarehouse: '',
    hasPark: '',
    hasRestaurant: '',
    hasFitnessCentre: '',
    hasSupermarket: '',
    hasMinimarket: '',
    hasShoppingArea: '',
    hasConferenceRoom: '',

    landAreaUnitType: 'Единица измерения',
    landType: 'Тип участка',

    vatType: 'НДС',
    contractType: 'Тип договора',

    settlementName: 'Название коттеджного поселка',

    landArea: 'Площадь участка',
    landStatus: 'Категория земли',

    highway_id: 'highway_id',
    distanceFromMKAD: 'Расяние от МКАД, км',

    hasGas: 'газ',
    hasDrainage: 'канализация',
    hasElectricity: 'электричество',
    hasWater: 'водоснабжение',

    totalArea: 'Площадь',
    bedroomsCount: 'Количество спален',
    wcLocationType: 'Тип санузла',
    repairType: 'Ремонт',

    floorsCount: 'Количество этажей',
    buildYear: 'Год постройки',
    heatingType: 'Отопление',

    hasPhone: 'Есть телефон',
    hasSecurity: 'Есть охрана',
    hasBathhouse: 'Есть баня',
    hasGarage: 'Есть гараж',
    hasPool: 'Есть бассейн',

    description: 'Описание',
    metros: 'Метро',
    livingArea: 'Жилая площадь',
    kitchenArea: 'Площадь кухни',
    allRoomsArea: 'Площадь комнат',
    floorNumber: 'Номер этажа',
    materialType: 'Тип дома',
    major_users: '',
    export_users: '',
    price: 'Цена',
    currency: 'Валюта',
    phone: '',
    agency: '',
    photosCount: '',
    youtubeId: 'youtubeId',
    exportingBaseCount: '',
    main_metro_id: 'Основная станция метро',

    buildingIsNotReadyYet: 'Дом не сдан',

    owner_source_id: '',

    newbuilding_id: 'ЖК id',
    newbuilding: '',
    newbuilding_house_id: 'Корпус ЖК id',
    newbuildingHouse: '',

    showingsCount: '',
    callsCount: '',
    comments: '',

    isFake: 'Фейк',
    exclusiveDealId: '',
    base: '',

    shareAmount: 'Доля',
    roomsForSaleCount: 'Комнат на продажу',
    roomType: 'Тип комнат',

    buildingSeries: 'Дом серия',
    passengerLiftsCount: 'Пассажирских лифтов',
    cargoLiftsCount: 'Грузовых лифтов',
    hasGarbageChute: 'Мусоропровод',
    hasRamp: 'Пандус',
    parking: 'Тип парковки',

    cadastralNumber: 'Кадастровый номер',

    isApartments: 'Апартаменты',
    isPenthouse: 'Пентхаус',
    windowsView: 'Вид из окон',
    loggiasCount: 'Лоджий',
    balconiesCount: 'Балконов',
    separateWcsCount: 'Раздельных с/у',
    combinedWcsCount: 'Смежных с/у',

    mortgage: 'Ипотека',
    saleType: 'Тип продажи',

    photos: 'Фотографии',

    major_user_ids: '',
    export_user_ids: '',
    inner_id: '',

    hasInternet: 'Интернет',
    hasFurniture: 'Мебель',
    hasKitchenFurniture: 'Мебель на кухне',
    hasTv: 'Телевизор',
    hasWasher: 'Стиральная машина',
    hasConditioner: 'Кондиционер',
    hasBathtub: 'Есть ванная',
    hasShower: 'Душевая кабина',
    hasDishwasher: '',
    hasFridge: 'Посудомоечная машина',
    petsAllowed: 'Можно с животными',
    childrenAllowed: 'Можно с детьми',

    utilitiesIncluded: 'коммуналка в аренде включена',
    operationalCostsIncluded: 'операционные расходы в аренде включены',
    bargainAllowed: 'Возможен торг',
    bargainPrice: 'Цена с учетом торга',
    bargainConditions: 'Условия торга',
    leaseTermType: '1 - долгосрочная, 2 - несколько месяцев',
    prepayMonths: 'Предоплата, от 1 до 12 месяцев',
    deposit: 'Залог собственнику',
    clientFee: 'Клиентская комиссия',
    agentFee: 'Агентская комиссия',

    priceHistory: '',

    documentsCount: '',
    assignOwnersHistory: '',

    takeToWork: 'Взял(а) в работу',

    duplicateEstateFrom: 'Дублика с объекта',
    duplicateEstateTo: 'Объект дублирован в',

    floorMaterialTypeType: 'Материал пола',
    parkingPurposeType: 'Назначение парковки',
    parkingPriceEntry: 'Стоимость въезда',
    liftType: 'Тип лифта',
    liftAdditionalType: 'Дополнительный тип',
    liftCount: 'Количество лифтов',
    liftLoadCapacity: 'Грузоподъёмность лифтов',
    cranageType: 'Тип кранового оборудования',
    cranageLoadCapacity: 'Грузоподъёмность крана',
    cranageCount: 'Количество кранов',
    gatesType: 'Тип ворот',
    columnGrid: 'Сетка колонн',
    hasSafeCustody: 'Ответственное хранение',
    isCustoms: 'Таможня',
    hasTransportServices: 'Транспортные услуги',
    hasOfficeSpace: 'Офисные помещения',

    soldPrice: 'Цена продажи',
    deleteStatus: 'Причина удаления'
};

const {
    estatePhotos: { estatePhotoUrl, sizes }
} = config;

const printEstateVal = (key: string, value: unknown): string | ReactNode => {
    switch (key) {
        case 'enable':
            return value ? 'нет' : 'да';
        case 'updateTime':
        case 'createTime':
            return Number(value) > 0 ? new Date(Number(value) * 1000).toLocaleString() : 'н/д';
        case 'photos':
            if (value instanceof Array) {
                return value.map(photo => {
                    let url = photo?.filename || photo;

                    return (
                        <Fragment key={url}>
                            <a
                                href={url.includes('://') ? url : estatePhotoUrl + sizes[2].dir + '/' + sizes[2].prefix + url}
                                target="_blank"
                                className="crm-Item__itemHistory_photo"
                                rel="noreferrer"
                            >
                                {url}
                            </a>
                            <br />
                        </Fragment>
                    );
                });
            }
            return '';
        case 'metros':
            if (value instanceof Array) {
                return value.map(({ metro_id }) => metro_id).join(', ');
            }
            return '';
        case 'propertyType':
            return estateL10n.PROPERTY_TYPE_FIND(value);
        case 'roomsCount':
            return estateL10n.ROOMS_COUNT_SHORT_FIND(value);
        case 'materialType':
            return estateL10n.HOUSE_MATERIAL_TYPES_FIND(value);
        case 'saleType':
            return estateL10n.SALE_TYPES_FIND(value);
        case 'deleteStatus':
            return estateL10n.DELETE_STATUS_FIND(value);
        case 'description':
            return nl2br(String(value));
        default:
            break;
    }

    if (value instanceof Array) {
        return value.join(', ');
    } else if (typeof value === 'boolean') {
        return value ? 'да' : 'нет';
    } else if (value === null) {
        return 'н/д';
    }

    return String(value);
};

export default ItemHistoryModeWrapper(estateStore, estateKeys, printEstateVal);
