import fetchApi from '../common/fetchApi';
import { AvitoHouse, YandexHouse, Newbuilding } from '~/types/newbuildings.types';

export const GQAddressFields = `
    street
    streetType
    houseNumber
    houseNumberType
    blockNumber
    blockNumberType

    town
    townType
    region
    regionType
    district
    cityDistrict
    districtType
    cityDistrictType
    settlement
    settlementType
    postcode
    
    address
    fias
    kladr
    geo_lat
    geo_lon
`;

const GQYandexHouse = `
    yandex_house_id
    yandex_newobject_id
    newbuilding_id
    newbuilding_house_id
    
    name
    address
    url
    
    buildYear
    readyQuarter
    buildingPhase
`;

const GQAvitoHouse = `
    avito_house_id
    avito_newobject_id
    newbuilding_id
    newbuilding_house_id
    
    name
    address
    developer
`;

export const GQNewBuildingFields = `
    newbuilding_id
    name
    
    ${GQAddressFields}
`;

export const GQNewBuildingHouseFields = `
    newbuilding_house_id
    newbuilding_id
    name
    
    ${GQAddressFields}
`;

const GQNewbuildingWithHouses = `
    ${GQNewBuildingFields}
            
    houses {
      ${GQNewBuildingHouseFields}
    }
`;

export const fetchNewbuildingsSuggestions = async (query: string, region: string | null, limit: number): Promise<Newbuilding[]> => {
    const graphql = `{
        findNewBuildngByName(name: "${query}", region: "${region || ''}", limit: ${limit}) {
            ${GQNewbuildingWithHouses}
          }
      }`;

    const data: { findNewBuildngByName: Newbuilding[] } = await fetchApi.getGQ(graphql);
    return data.findNewBuildngByName;
};

export const fetchNearestNewbuildings = async (geo_lat: number, geo_lon: number, limit: number): Promise<Newbuilding[]> => {
    const graphql = `{
        fetchNearestNewbuildings(geo_lat: ${geo_lat}, geo_lon: ${geo_lon}, limit: ${limit}) {
            ${GQNewbuildingWithHouses}
          }
      }`;

    const data: { fetchNearestNewbuildings: Newbuilding[] } = await fetchApi.getGQ(graphql);
    return data.fetchNearestNewbuildings;
};

export const fetchNewbuildingById = async (newbuilding_id: number): Promise<Newbuilding> => {
    const graphql = `{
        fetchNewbuildingById(newbuilding_id: ${newbuilding_id}) {
            ${GQNewbuildingWithHouses}
          }
      }`;

    const data: { fetchNewbuildingById: Newbuilding } = await fetchApi.getGQ(graphql);
    return data.fetchNewbuildingById;
};

export const matchYandexHouse = async (
    newbuilding_house_id: number
): Promise<{ yandexHouse: YandexHouse; suggestedYandexHouses: YandexHouse[] }> => {
    const graphql = `{
        matchYandexHouse(newbuilding_house_id: ${newbuilding_house_id}) {
            yandexHouse {
                ${GQYandexHouse}
            }
            suggestedYandexHouses {
                ${GQYandexHouse}
            }
        } 
      }`;

    const data: { matchYandexHouse: { yandexHouse: YandexHouse; suggestedYandexHouses: YandexHouse[] } } = await fetchApi.getGQ(graphql);
    return data.matchYandexHouse;
};

export const matchAvitoHouse = async (
    newbuilding_house_id: number
): Promise<{ avitoHouse: AvitoHouse; suggestedAvitoHouses: AvitoHouse[] }> => {
    const graphql = `{
        matchAvitoHouse(newbuilding_house_id: ${newbuilding_house_id}) {
            avitoHouse {
                ${GQAvitoHouse}
            }
            suggestedAvitoHouses {
                ${GQAvitoHouse}
            }
        } 
      }`;

    const data: { matchAvitoHouse: { avitoHouse: AvitoHouse; suggestedAvitoHouses: AvitoHouse[] } } = await fetchApi.getGQ(graphql);
    return data.matchAvitoHouse;
};

export const findYandexHouses = async (search: string): Promise<YandexHouse[]> => {
    const graphql = `{
        findYandexHouses(search: "${search}", limit: 30) {
            ${GQYandexHouse}
        } 
      }`;

    const data: { findYandexHouses: YandexHouse[] } = await fetchApi.getGQ(graphql);
    return data.findYandexHouses;
};

export const updateYandexHouse = async (yandex_house_id: number, newbuilding_house_id: number): Promise<number> => {
    const graphql = `mutation { 
          updateYandexHouse(yandex_house_id: ${yandex_house_id}, newbuilding_house_id: ${newbuilding_house_id})
        }`;

    const data: { updateYandexHouse: number } = await fetchApi.postGQ(graphql);
    return data.updateYandexHouse;
};

export const findAvitoHouses = async (search: string): Promise<AvitoHouse[]> => {
    const graphql = `{
        findAvitoHouses(search: "${search}", limit: 30) {
            ${GQAvitoHouse}
        } 
      }`;

    const data: { findAvitoHouses: AvitoHouse[] } = await fetchApi.getGQ(graphql);
    return data.findAvitoHouses;
};

export const updateAvitoHouse = async (avito_house_id: number, newbuilding_house_id: number): Promise<number> => {
    const graphql = `mutation { 
          updateAvitoHouse(avito_house_id: ${avito_house_id}, newbuilding_house_id: ${newbuilding_house_id})
        }`;

    const data: { updateAvitoHouse: number } = await fetchApi.postGQ(graphql);
    return data.updateAvitoHouse;
};
