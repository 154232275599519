import React, { PureComponent } from 'react';
import { Grid } from 'semantic-ui-react';
import EstateInfoBlock from './ShowBlocks/EstateInfoBlock';
import PriceBlockShow from './ShowBlocks/PriceBlockShow';
import ExternalBlockShow from './ShowBlocks/ExternalBlockShow';
import PreventEditing from '../../Base/PreventEditing';
import Comments from '../Common/Comments';

type EstateExternalModeProps = {
    estate_id: number;
};

class EstateExternalMode extends PureComponent<EstateExternalModeProps> {
    render() {
        const { estate_id } = this.props;

        return (
            <PreventEditing>
                <Grid stackable reversed="mobile">
                    <Grid.Column width={10}>
                        <EstateInfoBlock estate_id={estate_id} />
                    </Grid.Column>
                    <Grid.Column width={6}>
                        <PriceBlockShow item_id={estate_id} />
                        <ExternalBlockShow item_id={estate_id} />
                        <Comments item_id={estate_id} item_type="externalEstate" />
                    </Grid.Column>
                </Grid>
            </PreventEditing>
        );
    }
}

export default EstateExternalMode;
