import React from 'react';
import { Label, Popup, Icon } from 'semantic-ui-react';
import {
    EXPORT_REPORT_STATUS_ERROR,
    CIAN_EXPORT_BASE_ID,
    EXPORT_REPORT_STATUS_WARNING,
    YANDEX_EXPORT_BASE_ID,
    AVITO_EXPORT_BASE_ID,
    WINNER_EXPORT_BASE_ID,
    IRR_EXPORT_BASE_ID,
    GDEETOTDOM_EXPORT_BASE_ID,
    DOMCLICK_EXPORT_BASE_ID
} from '~/types/exports.types';
import Time from '../../../../Base/Time';
import { ExportExternalStatus } from '~/types/exports.types';
import { nl2br } from '~/api/commentsApi';

const matchExternalUrl = (export_base_id: number, externalUrl: string): string => {
    switch (export_base_id) {
        case CIAN_EXPORT_BASE_ID:
            return `https://cian.ru/sale/flat/${externalUrl}/`;
        case YANDEX_EXPORT_BASE_ID:
            return `https://realty.yandex.ru/offer/${externalUrl}/`;
        case AVITO_EXPORT_BASE_ID:
            return `https://avito.ru/${externalUrl}/`;
        case WINNER_EXPORT_BASE_ID:
            return `https://online.baza-winner.ru/dip/${externalUrl}/`;
        case IRR_EXPORT_BASE_ID:
            return `http://irr.ru/advert${externalUrl}.html`;
        case GDEETOTDOM_EXPORT_BASE_ID:
            return `https://www.gdeetotdom.ru/obj/country/msk/${externalUrl}/`;
        case DOMCLICK_EXPORT_BASE_ID:
            return `https://domclick.ru/card/${externalUrl}/`;
        default:
            return '';
    }
};

const PrintExportExternalStatus = ({
    export_base_id,
    externalStatus: { status, externalId, message, reportTime }
}: {
    export_base_id: number;
    externalStatus: ExportExternalStatus;
}) => (
    <span className="crm-Estate__export_externalInfo">
        {externalId && (
            <a target="_blank" href={matchExternalUrl(export_base_id, externalId)} rel="noreferrer">
                ссылка <Icon name="external" />
            </a>
        )}
        {status === EXPORT_REPORT_STATUS_ERROR && (
            <Popup
                trigger={
                    <Label color="red">
                        <Icon name="warning" /> Ошибка
                    </Label>
                }
                hideOnScroll
                size="tiny"
                wide="very"
                hoverable
            >
                <span style={{ color: 'red' }} dangerouslySetInnerHTML={{ __html: message && nl2br(message) }} />
            </Popup>
        )}
        {status === EXPORT_REPORT_STATUS_WARNING && (
            <Popup
                trigger={
                    <Label color="yellow">
                        <Icon name="warning" /> Предупреждение
                    </Label>
                }
                hideOnScroll
                size="tiny"
                wide="very"
                hoverable
            >
                <span dangerouslySetInnerHTML={{ __html: message && nl2br(message) }} />
            </Popup>
        )}
        &nbsp;
        <div className="crm-Estate__fieldNowrap">
            <Time time={reportTime} />
        </div>
    </span>
);

export default PrintExportExternalStatus;
