import React, { Component, PureComponent, Fragment } from 'react';
import { Input, Form, Button, Icon } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import cs from 'classnames';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

import RemoveButton from '../../../Base/RemoveButton';
import { Phone } from '~/types/users.types';
import { ItemContactsProps } from './index';
import contactStore from '~/stores/contactStore';
import userStore from '~/stores/userStore';
import ListWithContactsStorePrototype from '~/stores/prototypes/listWithContactsStore.prototype';
import authStore from '~/stores/authStore';
import { ACCESS_SELF, ACCESS_WRITE } from '~/types/access.types';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';

import TextInput from '~ui/TextInput';
import Grid from '~ui/Grid';

const copyPhones = (phones: Phone[]): Phone[] => phones.map(({ phone, phone_id }) => ({ phone, phone_id }));

type ItemContactsEditingType = {
    phones: Array<Phone>;
    email?: string;
    innerPhones?: string[];
};

type ItemContactsEditingProps = ItemContactsEditingType & ItemContactsProps;

export const adjustPhoneString = (phone: string): string => {
    let adjustedPhone = phone.replace(/\D/g, '');
    if (adjustedPhone.length > 0) {
        if (adjustedPhone[0] === '8') {
            adjustedPhone = '7' + adjustedPhone.slice(1);
        } else if (adjustedPhone[0] !== '8' && adjustedPhone[0] !== '7') {
            adjustedPhone = '7' + adjustedPhone;
        }
    }

    return adjustedPhone
        .slice(0, 11)
        .split('')
        .reduce((strPhone, currentNumber, index) => {
            if (index === 0) {
                strPhone = '+7';
            } else if (index === 1) {
                strPhone += ` (${currentNumber}`;
            } else if (index === 4) {
                strPhone += `) ${currentNumber}`;
            } else if ([7, 9].includes(index)) {
                strPhone += `-${currentNumber}`;
            } else {
                strPhone += `${currentNumber}`;
            }
            return strPhone;
        }, '');
};

type SortablePhoneElementProps = {
    i: number; // there is "i" instead of "index" because "index" will remove by the wrapper component "SortableElement"
    phone: string;
    disable: boolean;
    sortable: boolean;
    store: ListWithContactsStorePrototype<any, any, any, any>;
    handleRemovePhone: (index: number) => void;
    handleEditPhone: (event: React.SyntheticEvent, data: { value: string }) => void;
};

class SortablePhoneElement extends PureComponent<SortablePhoneElementProps> {
    render() {
        const { i, disable, sortable, phone, store, handleRemovePhone, handleEditPhone } = this.props;
        const PhoneDragHandle = SortableHandle(() => <Icon link name="resize vertical" title="Поменять местами" />);

        return (
            <Form.Field key={`phone_${i}`} className="crm-FluentButtons" inline>
                {!disable && <RemoveButton onClick={() => handleRemovePhone(i)} />}
                <Input
                    name={i}
                    size="mini"
                    placeholder="+7 (XXX) XXX-XX-XX"
                    value={adjustPhoneString(phone)}
                    onChange={handleEditPhone}
                    className={cs({
                        'crm-User__contactBlock_phoneInput': true,
                        'crm-User__contactBlock_firstPhone': i === 0 && store === userStore
                    })}
                    maxLength={18}
                    required
                    pattern="\+7 \(\d{3}\) \d{3}-\d\d-\d\d"
                    title="Телефон должен содержать 11 цифр"
                    disabled={disable}
                />
                {!disable && sortable && <PhoneDragHandle />}
                {i === 0 && store === userStore && <label className="crm-User__contactBlock_firstPhoneLabel">— в экспорт</label>}
            </Form.Field>
        );
    }
}

const SortablePhone = SortableElement(SortablePhoneElement);

const SortablePhones = SortableContainer(
    ({
        phones,
        store,
        handleRemovePhone,
        handleEditPhone
    }: {
        phones: Phone[];
        store: ListWithContactsStorePrototype<any, any, any, any>;
        handleRemovePhone: (index: number) => void;
        handleEditPhone: (event: React.SyntheticEvent, data: { value: string }) => void;
    }) => {
        return (
            <div>
                {phones.map(({ phone, phone_id }, index) => {
                    const disabled = phone_id > CREATING_ITEM_ID && authStore.getPermission(store.moduleName, ACCESS_WRITE) === ACCESS_SELF;
                    return (
                        <SortablePhone
                            key={phone_id || index}
                            index={index}
                            i={index}
                            disable={disabled}
                            sortable={phones.length > 1 && phone_id > CREATING_ITEM_ID}
                            phone={phone}
                            store={store}
                            handleRemovePhone={handleRemovePhone}
                            handleEditPhone={handleEditPhone}
                        />
                    );
                })}
            </div>
        );
    }
);

@observer
class ItemContactsEditing extends Component<ItemContactsEditingProps> {
    constructor(props: ItemContactsEditingProps) {
        super(props);

        const { item_id, store, phones, email, innerPhones } = this.props;

        const item: ItemContactsEditingType = {
            phones: phones || [],
            email: email || ''
        };

        if (store === userStore) {
            item.innerPhones = innerPhones || [];
        }

        store.setEditingItem(item_id, item);
    }

    handleRemovePhone = (i: number) => {
        const { item_id, store } = this.props;

        const phones = copyPhones(store.getItem(item_id).editingItem.phones);
        phones.splice(i, 1);
        store.setEditingItem(item_id, { phones });
    };

    handleAddPhone = () => {
        const { item_id, store } = this.props;

        const phones = copyPhones(store.getItem(item_id).editingItem.phones);
        phones.push({
            phone_id: 0,
            phone: ''
        });
        store.setEditingItem(item_id, { phones });
    };

    handleEditEmail = (event: React.SyntheticEvent, { value }: { value: string }) => {
        const { item_id, store } = this.props;

        store.setEditingItem(item_id, {
            email: value
        });
    };

    handleEditPhone = (event: React.SyntheticEvent, data: { value: string; name: number }) => {
        const { item_id, store } = this.props;

        let phone = data.value.replace(/\D/g, '').slice(0, 11);
        if (phone.length === 10 && phone[0] === '9') {
            phone = `7${phone}`;
        }
        if (phone.length && phone[0] === '8') {
            phone = `7${phone.slice(1)}`;
        }

        if (store === contactStore && phone.length === 11) {
            contactStore.checkWhetherPhoneIsNotUsed(item_id, phone);
        }

        const phones = copyPhones(store.getItem(item_id).editingItem.phones);
        phones[data.name].phone = phone;
        store.setEditingItem(item_id, { phones });
    };

    handleInnerPhone = (event: React.SyntheticEvent, { value, name: index }: { value: string; name: number }) => {
        const { item_id, store } = this.props;
        store.changeArrayValue(item_id, 'innerPhones', index, null, value);
    };

    handleAddInnerPhone = () => {
        const { item_id, store } = this.props;
        const innerPhones = [...store.getItem(item_id).editingItem.innerPhones];
        innerPhones.push('');
        store.setEditingItem(item_id, { innerPhones });
    };

    removeInnerPhone = (index: number) => {
        const { item_id, store } = this.props;

        const innerPhones = [...store.getItem(item_id).editingItem.innerPhones];
        innerPhones.splice(index, 1);
        store.setEditingItem(item_id, { innerPhones });
    };

    onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
        this.props.store.movePhones(this.props.item_id, oldIndex, newIndex);
    };

    render() {
        const { store, item_id } = this.props;
        const { email, innerPhones, phones } = store.getItem(item_id).editingItem;

        return (
            <div>
                <Grid columns={item_id === CREATING_ITEM_ID ? 2 : 1} stackable alignItems="flex-start">
                    <Grid.Column>
                        <SortablePhones
                            phones={phones}
                            store={store}
                            useDragHandle
                            handleRemovePhone={this.handleRemovePhone}
                            handleEditPhone={this.handleEditPhone}
                            onSortEnd={this.onSortEnd}
                        />

                        <Button size="mini" color="green" onClick={this.handleAddPhone}>
                            + телефон
                        </Button>
                    </Grid.Column>
                    <Grid.Column>
                        <TextInput
                            type="email"
                            name="email"
                            size="small"
                            label="Email"
                            value={email}
                            onChange={this.handleEditEmail}
                            required={store === userStore}
                            fullWidth
                            variant="classic"
                        />
                    </Grid.Column>
                </Grid>

                {store === userStore && (
                    <Fragment>
                        {innerPhones.map((innerPhone, index) => (
                            <Form.Field className="crm-FluentButtons" inline key={index}>
                                <RemoveButton onClick={this.removeInnerPhone.bind(this, index)} />
                                <Input
                                    type="text"
                                    placeholder="Внутренний ID"
                                    size="mini"
                                    value={innerPhone || ''}
                                    onChange={this.handleInnerPhone}
                                    style={{ width: '130px' }}
                                    name={index}
                                    className="crm-User__contactBlock_phoneInput"
                                />
                                <label className="crm-User__contactBlock_firstPhoneLabel">— Внутренний ID</label>
                            </Form.Field>
                        ))}
                        <Button size="mini" title="Добавить внутренний ID" onClick={this.handleAddInnerPhone}>
                            + Внутренний ID
                        </Button>
                    </Fragment>
                )}
            </div>
        );
    }
}

export default ItemContactsEditing;
