import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Card, Grid, Header, Divider } from 'semantic-ui-react';

import EditingBlockWrapper from '../../EditingBlockWrapper';
import estateStore from '~/stores/estateStore';

import OfficeDetailsBlockEditing from '../EditBlocks/OfficeDetailsBlockEditing';
import AvailableFromEditing from '../EditBlocks/AvailableFromEditing';

import { EditingWrappedProps } from '../../EditingBlockWrapper';
import { nl2br } from '~/api/commentsApi';
import estateL10n from '../../../../L10n/estate.L10n';
import {
    ESTATE_PROPERTY_TYPE_BUILDING,
    ESTATE_PROPERTY_TYPE_FREE_PURPOSE,
    ESTATE_PROPERTY_TYPE_INDUSTRY,
    ESTATE_PROPERTY_TYPE_OFFICE,
    ESTATE_PROPERTY_TYPE_SHOPPING
} from '~/types/estate.types';
import OfficeBuildingBlockEditing from '~/components/Items/Estate/EditBlocks/OfficeBuildingBlockEditing';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';

export const printParkingsCount = (count: number): string => {
    if (count === 1) {
        return `1 место`;
    } else if (count < 4) {
        return `${count} места`;
    } else {
        return `${count} мест`;
    }
};

@observer
class OfficeDetailsBlockShow extends Component<EditingWrappedProps> {
    render() {
        const { item_id, editing } = this.props;
        const { item, loadingItem } = estateStore.getItem(item_id);
        const {
            description,
            totalArea,
            floorNumber,
            floorsCount,
            buildingClassType,
            isOccupied,
            availableFrom,
            taxNumber,
            minArea,
            isLegalAddressProvided,
            ceilingHeight,
            layout,
            waterPipesCount,
            conditionType,
            furniturePresence,
            accessType,
            parkingType,
            parkingPlacesCount,
            parkingPriceMonthly,
            parkingCurrency,
            parkingIsFree,
            propertyType,
            inputType,
            hasShopWindows,
            hasFurniture
        } = item || {};

        let conditionTypeFind;

        switch (propertyType) {
            case ESTATE_PROPERTY_TYPE_SHOPPING:
                conditionTypeFind = estateL10n.SHOPPING_CONDITION_TYPE_FIND;
                break;
            case ESTATE_PROPERTY_TYPE_FREE_PURPOSE:
                conditionTypeFind = estateL10n.FREE_PURPOSE_CONDITION_TYPE_FIND;
                break;
            case ESTATE_PROPERTY_TYPE_INDUSTRY:
                conditionTypeFind = estateL10n.INDUSTRY_CONDITION_TYPE_FIND;
                break;
            default:
                conditionTypeFind = estateL10n.OFFICE_CONDITION_TYPE_FIND;
                break;
        }

        return (
            <Card fluid color="blue">
                <Card.Content>
                    {!editing && (
                        <Fragment>
                            <Card.Content>
                                <Grid columns={propertyType === ESTATE_PROPERTY_TYPE_OFFICE ? 4 : 3}>
                                    <Grid.Row textAlign="center">
                                        <Grid.Column>
                                            <div className="crm-Estate__about_title">Площадь</div>
                                            <span className="crm-Estate__about_value">{totalArea} м²</span>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <div className="crm-Estate__about_title">Этаж</div>
                                            <span className="crm-Estate__about_value">
                                                {floorNumber} из {floorsCount}
                                            </span>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <div className="crm-Estate__about_title">Занятость</div>
                                            <span className="crm-Estate__about_value">
                                                {typeof isOccupied === 'boolean' ? (isOccupied ? 'занято' : 'свободно') : 'н/д'}
                                                {isOccupied && availableFrom && (
                                                    <div>до {new Date(availableFrom * 1000).toLocaleDateString()}</div>
                                                )}
                                            </span>
                                        </Grid.Column>
                                        {propertyType === ESTATE_PROPERTY_TYPE_OFFICE && (
                                            <Grid.Column>
                                                <div className="crm-Estate__about_title">Класс</div>
                                                <span className="crm-Estate__about_value">
                                                    {estateL10n.OFFICE_CLASS_TYPE_FIND(buildingClassType)}
                                                </span>
                                            </Grid.Column>
                                        )}
                                    </Grid.Row>
                                </Grid>
                            </Card.Content>
                            <Card.Content>
                                <div
                                    style={{ padding: '15px 0' }}
                                    dangerouslySetInnerHTML={{ __html: description && nl2br(description) }}
                                />
                            </Card.Content>
                            <Card.Content>
                                <Header size="tiny">{propertyType === ESTATE_PROPERTY_TYPE_OFFICE ? 'Офис' : 'Помещение'}</Header>
                                <Grid columns={2}>
                                    <Grid.Row>
                                        {propertyType !== ESTATE_PROPERTY_TYPE_BUILDING && (
                                            <>
                                                <Grid.Column>Минимальная площадь</Grid.Column>
                                                <Grid.Column>{minArea}</Grid.Column>
                                            </>
                                        )}

                                        <Grid.Column>Номер налоговой</Grid.Column>
                                        <Grid.Column>{taxNumber}</Grid.Column>

                                        {propertyType !== ESTATE_PROPERTY_TYPE_BUILDING && (
                                            <>
                                                <Grid.Column>Юридический адрес</Grid.Column>
                                                <Grid.Column>
                                                    {typeof isLegalAddressProvided === 'boolean'
                                                        ? isLegalAddressProvided
                                                            ? 'да'
                                                            : 'нет'
                                                        : 'н/д'}
                                                </Grid.Column>
                                            </>
                                        )}

                                        <Grid.Column>Высота потолков</Grid.Column>
                                        <Grid.Column>{ceilingHeight} см</Grid.Column>

                                        <Grid.Column>Планировка</Grid.Column>
                                        <Grid.Column>{estateL10n.LAYOUTS_FIND(layout)}</Grid.Column>

                                        <Grid.Column>Кол-во мокрых точек</Grid.Column>
                                        <Grid.Column>{waterPipesCount}</Grid.Column>

                                        <Grid.Column>Состояние</Grid.Column>
                                        <Grid.Column>{conditionTypeFind(conditionType)}</Grid.Column>

                                        {propertyType === ESTATE_PROPERTY_TYPE_BUILDING && (
                                            <>
                                                <Grid.Column>Мебель в комнатах</Grid.Column>
                                                <Grid.Column>
                                                    {typeof hasFurniture === 'boolean' ? (hasFurniture ? 'есть' : 'нет') : 'н/д'}
                                                </Grid.Column>
                                            </>
                                        )}

                                        {[ESTATE_PROPERTY_TYPE_FREE_PURPOSE, ESTATE_PROPERTY_TYPE_BUILDING].includes(propertyType) && (
                                            <Fragment>
                                                <Grid.Column>Вход</Grid.Column>
                                                <Grid.Column>
                                                    {estateL10n.FREE_PURPOSE_INPUT_TYPE_FIND(inputType)}
                                                    {hasShopWindows && (
                                                        <Fragment>
                                                            &nbsp;&nbsp;<b>Витринные окна</b>
                                                        </Fragment>
                                                    )}
                                                </Grid.Column>
                                            </Fragment>
                                        )}

                                        {propertyType === ESTATE_PROPERTY_TYPE_OFFICE && (
                                            <Fragment>
                                                <Grid.Column>Мебель</Grid.Column>
                                                <Grid.Column>
                                                    {typeof furniturePresence === 'boolean' ? (furniturePresence ? 'есть' : 'нет') : 'н/д'}
                                                </Grid.Column>

                                                <Grid.Column>Доступ</Grid.Column>
                                                <Grid.Column>{estateL10n.OFFICE_ACCESS_TYPES_FIND(accessType)}</Grid.Column>
                                            </Fragment>
                                        )}
                                        {[ESTATE_PROPERTY_TYPE_OFFICE, ESTATE_PROPERTY_TYPE_BUILDING].includes(propertyType) && (
                                            <Fragment>
                                                <Grid.Column>Парковка</Grid.Column>
                                                <Grid.Column>
                                                    {estateL10n.OFFICE_PARKING_TYPE_FIND(parkingType)}
                                                    &nbsp;
                                                    {typeof parkingPlacesCount === 'number' &&
                                                        parkingPlacesCount > 0 &&
                                                        printParkingsCount(parkingPlacesCount)}
                                                    &nbsp;
                                                    {typeof parkingPriceMonthly === 'number' && parkingPriceMonthly > 0 && (
                                                        <span className="crm-Estate__fieldNowrap">{`${parkingPriceMonthly} ${estateL10n.CURRENCY_TYPES_FIND(
                                                            parkingCurrency
                                                        )}/мес`}</span>
                                                    )}
                                                    &nbsp;
                                                    {parkingIsFree && 'бесплатно'}
                                                </Grid.Column>
                                            </Fragment>
                                        )}
                                    </Grid.Row>
                                </Grid>
                            </Card.Content>
                        </Fragment>
                    )}
                    {editing && (
                        <Fragment>
                            {ESTATE_PROPERTY_TYPE_BUILDING === propertyType && (
                                <>
                                    <OfficeBuildingBlockEditing item_id={item_id} {...item} />
                                    <Divider />
                                </>
                            )}
                            <AvailableFromEditing item_id={item_id} {...item} />
                            <Divider />
                            <OfficeDetailsBlockEditing item_id={item_id} {...item} />
                        </Fragment>
                    )}
                </Card.Content>
            </Card>
        );
    }
}

export default EditingBlockWrapper(estateStore, OfficeDetailsBlockShow);
