import React, { createRef, Fragment } from 'react';
import { Popup } from 'semantic-ui-react';
import UserLink from './UserLink';
import { UserLinkType } from '~/types/users.types';
import UserLinkWithPreview from './UserLinkWithPreview';

const UsersLinkList = ({ usersList, avatar = false }: { usersList?: Array<UserLinkType>; avatar?: boolean }) => {
    return (
        <Fragment>
            {usersList && usersList.length === 1 ? (
                <UserLinkWithPreview icon={!avatar} avatar={avatar} key={usersList[0].user_id} user={usersList[0]} />
            ) : null}
            {usersList && usersList.length > 1 && (
                <Popup
                    size="small"
                    hoverable
                    trigger={
                        <span className="crm-User__linkList_trigger">
                            <UserLink icon={true} key={usersList[0].user_id} user={usersList[0]} />
                            <b>{`+${usersList.length - 1}`}</b>
                        </span>
                    }
                >
                    <div>
                        {usersList.map(user => (
                            <UserLink icon={!avatar} key={user.user_id} user={user} avatar={avatar} />
                        ))}
                    </div>
                </Popup>
            )}
        </Fragment>
    );
};

export default React.memo(UsersLinkList);
