import React, { useMemo, useState, MouseEvent } from 'react';
import { observer } from 'mobx-react';
import config from '~/config/ui.config';
import median from '~/common/median';

import ToggleButton from '@material-ui/lab/ToggleButton';
import Button from '@material-ui/core/Button';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import AddIcon from '@material-ui/icons/Add';

import { Pult, StyledToggleButtonGroup, PultDivider } from '~ui/FilterPult';

import estateSMAStore from '~/stores/estateSMAStore';
import ListError from '../../../ListError';
import Progress from '../../../Base/ui/Progress';

import EstateSmaTable from './EstateSmaTable';
import FindEstatesModal from './FindEstatesModal';
import { SmaEstateItemType, SmaOptionsType } from '~/types/estateSma.types';
import UserDropdown from '~/components/ItemsDropdowns/UserDropdown';
import { InfoMessage } from '~ui/Message';
import authStore from '~/stores/authStore';
import estateStore from '~/stores/estateStore';
import commonStore from '~/stores/commonStore';

const SmaUserId = ({ estate_id, user_id, disabled }: { estate_id: number; user_id: number; disabled: boolean }) => {
    const [userId, setUserId] = useState(user_id);
    const handleChange = (newUserId: number) => {
        estateSMAStore.updateListOptions(estate_id, { user_id: newUserId });
        setUserId(newUserId);
    };

    return <UserDropdown onChange={handleChange} user_id={userId} disableClearable disabled={disabled} />;
};

type ActualButtonsType = 1 | 2 | 3;

const filterEstate = (isActual: ActualButtonsType) => (estate: SmaEstateItemType): boolean => {
    switch (isActual) {
        case 1:
            return estate.enable === true;
        case 2:
            return estate.enable === false;
    }
    return true;
};

const APPROX_PRICE = 1.02;

const medianValues = (prices: number[]): string => {
    if (!prices.length) {
        return '...';
    }
    if (prices.length < 5) {
        return 'Мало объек.';
    }

    const medianPrice = median(prices) / 10 ** 6;

    let fracs = 2;
    if (medianPrice > 100) {
        fracs = 0;
    } else if (medianPrice > 20) {
        fracs = 1;
    }

    const minValue = (medianPrice / APPROX_PRICE).toFixed(fracs);
    const maxValue = (medianPrice * APPROX_PRICE).toFixed(fracs);
    return `${minValue} - ${maxValue}`;
};

export const matchSmaUrl = (options: SmaOptionsType): string => `${config.shareUrl}?sma=${options ? options.uuid : ''}`;

const EstateSMAShow = ({ estate_id }: { estate_id: number }) => {
    const { isMobile } = commonStore;
    const [isError, setIsError] = useState(false);

    useMemo(() => {
        estateSMAStore.fetchItemList(estate_id);

        estateSMAStore.fetchOptions(estate_id).catch(error => {
            setIsError(true);
        });
    }, []);

    const [isActual, setIsActual] = useState<ActualButtonsType>(3);
    const handleActiveTab = (event: MouseEvent, activeTab: ActualButtonsType) => {
        setIsActual(activeTab);
    };

    const [showFindEstate, setFindEstate] = useState(false);
    const handleToggleFindEstate = () => {
        setFindEstate(!showFindEstate);
    };

    const { loadingList, errors, list, options } = estateSMAStore.getItemList(estate_id);

    if (isError) {
        return <InfoMessage>Добавьте подходящие объекты для СМА во вкладке "Похожие"</InfoMessage>;
    }

    const enableEditing = options && options.user_id === authStore.currentUser.user_id;
    const canEdit = authStore.canEdit(estateStore.moduleName, estateStore.getItem(estate_id)?.item?.major_user_ids);

    return (
        <>
            <Pult>
                <div style={{ position: 'relative' }}>
                    {/*<Button disabled={!property} variant="contained" startIcon={<GetAppIcon />} className={classes.divider}>*/}
                    {/*    pdf*/}
                    {/*</Button>*/}

                    <Button
                        href={matchSmaUrl(options)}
                        target="_blank"
                        variant="contained"
                        color="primary"
                        endIcon={<OpenInNewIcon />}
                        disableRipple
                        disabled={!options}
                    >
                        Открыть
                    </Button>

                    {!options && <Progress show size={24} />}
                </div>

                <TextField
                    label="Оценка стоимости"
                    value={medianValues(list.map(({ price }) => price))}
                    InputProps={{
                        readOnly: true,
                        endAdornment: <InputAdornment position="end">млн.ք</InputAdornment>
                    }}
                    variant="outlined"
                    style={{ width: '170px' }}
                />
                {/*<TextField*/}
                {/*    label="Оценка сроков"*/}
                {/*    value={'3 - 6'}*/}
                {/*    InputProps={{*/}
                {/*        readOnly: true,*/}
                {/*        endAdornment: <InputAdornment position="end">мес</InputAdornment>*/}
                {/*    }}*/}
                {/*    variant="outlined"*/}
                {/*    style={{ width: '110px' }}*/}
                {/*/>*/}

                <PultDivider />

                {options && <SmaUserId estate_id={estate_id} user_id={options.user_id} disabled={!canEdit} />}

                <PultDivider />

                <StyledToggleButtonGroup exclusive size="small" value={isActual} onChange={handleActiveTab}>
                    <ToggleButton value={1}>Актуальные</ToggleButton>
                    <ToggleButton value={2}>Проданные</ToggleButton>
                    <ToggleButton value={3}>Все</ToggleButton>
                </StyledToggleButtonGroup>

                {!isMobile && (
                    <>
                        <PultDivider />
                        <Button variant="outlined" startIcon={<AddIcon />} onClick={handleToggleFindEstate}>
                            Добавить
                        </Button>
                    </>
                )}

                {showFindEstate && <FindEstatesModal estate_id={estate_id} onClose={handleToggleFindEstate} />}
            </Pult>

            <ListError errors={errors} />
            <Progress show={loadingList} />

            {list && (
                <EstateSmaTable estateList={list.filter(filterEstate(isActual))} sma_estate_id={estate_id} enableEditing={enableEditing} />
            )}
        </>
    );
};

export default observer(EstateSMAShow);
