import React, { ReactNode, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';

import kpiStore from '~/stores/kpiStore';
import LoaderAwait from '~/components/Base/LoaderAwait';

import Table, { TableCell, TableHead, TableRow, TableBody, SortDirection } from '~ui/Table';
import statisticsL10n from '~/L10n/statistics.L10n';
import UserLinkWithPreview from '~/components/Lists/Users/UserLinkWithPreview';
import ListError from '~/components/ListError';

import Tooltip from '@material-ui/core/Tooltip';
import { KPI_TYPE, KpiStatistics } from '~/types/kpi.types';

const useStyles = makeStyles(({ palette, spacing }) => ({
    red: {
        backgroundColor: palette.error.light
    },
    green: {
        backgroundColor: palette.success.light
    }
}));

export const StatisticsCell = React.memo(
    ({ value, redValue, greenValue }: { value: number; redValue: number | null; greenValue: number | null }) => {
        const classes = useStyles();
        let className = '';
        if (greenValue && value >= greenValue) {
            className = classes.green;
        } else if (redValue && value < redValue) {
            className = classes.red;
        }

        return (
            <TableCell align="right" className={className}>
                {value.toLocaleString()}
            </TableCell>
        );
    }
);

const POINT_KPI = 0;

const takeSortPointValue = (stat: KpiStatistics, sortPoint: number): number => {
    if (sortPoint === POINT_KPI) {
        return stat['kpi'];
    }
    const point = stat.pointsValue.find(({ point_id }) => point_id === sortPoint);
    return point?.value;
};

const sortStat = (statistics: KpiStatistics[], sortDirection: SortDirection, sortPoint: number): KpiStatistics[] => {
    if (!sortDirection) {
        return statistics;
    }
    return statistics.sort((s1, s2) => {
        if (sortDirection === 'asc') {
            return takeSortPointValue(s1, sortPoint) > takeSortPointValue(s2, sortPoint) ? 1 : -1;
        } else {
            return takeSortPointValue(s1, sortPoint) > takeSortPointValue(s2, sortPoint) ? -1 : 1;
        }
    });
};

const TableKpi = ({ kpi_id }: { kpi_id: number }) => {
    const [sortPoint, setSortPoint] = useState(POINT_KPI);

    useEffect(() => {
        setSortPoint(POINT_KPI);
        kpiStore.fetchItem(kpi_id);
    }, [kpi_id]);

    const [sortDirection, setSortDirection] = useState<SortDirection>(false);
    const setSort = (point_id: number) => {
        setSortPoint(point_id);
        setSortDirection(sortDirection === 'desc' ? 'asc' : 'desc');
    };

    const { item, loadingItem, property } = kpiStore.getItem(kpi_id);

    if (loadingItem || !item) {
        return <LoaderAwait active />;
    }

    const { title, points, redValue, greenValue, kpiType } = item;
    const { statistics, statisticsErrors, statisticsLoading } = property;

    return (
        <>
            <ListError errors={statisticsErrors} />
            <Table maxHeight="calc(100vh - 150px)">
                <TableHead>
                    <TableRow>
                        <TableCell>№</TableCell>
                        <TableCell>{kpiType === KPI_TYPE.group ? 'Отдел' : 'Сотрудник'}</TableCell>
                        {kpiType === KPI_TYPE.group && (
                            <>
                                <TableCell>Руководитель</TableCell>
                                <TableCell>
                                    <Tooltip title="Количество сотрудников в статистике (всего в отделе)">
                                        <span>Сотр.</span>
                                    </Tooltip>
                                </TableCell>
                            </>
                        )}
                        <TableCell
                            align="right"
                            sortDirection={sortPoint === POINT_KPI ? sortDirection : false}
                            onClick={() => setSort(POINT_KPI)}
                        >
                            {title}
                        </TableCell>
                        {points?.map(({ point_id, days, pointType }) => (
                            <TableCell
                                key={point_id}
                                align="right"
                                sortDirection={sortPoint === point_id ? sortDirection : false}
                                onClick={() => setSort(point_id)}
                            >
                                <Tooltip title={statisticsL10n.KPI_POINT_TYPE_FIND(pointType)}>
                                    <span>
                                        <span className="crm-Estate__fieldNowrap">
                                            {statisticsL10n.KPI_POINT_TYPE_SHORT_FIND(pointType)}
                                        </span>
                                        {![41, 42, 70].includes(pointType) && <span className="crm-Estate__fieldNowrap">({days} д.)</span>}
                                    </span>
                                </Tooltip>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {statistics &&
                        sortStat(statistics, sortDirection, sortPoint)?.map((stat, index) => {
                            const { kpi, pointsValue } = stat;

                            let AboutCell: ReactNode;
                            let key: number;

                            if (stat.kpiType === KPI_TYPE.single) {
                                const { major_user, major_user_id } = stat;
                                key = major_user_id;
                                AboutCell = (
                                    <TableCell>
                                        <UserLinkWithPreview avatar user={major_user} />
                                    </TableCell>
                                );
                            } else {
                                const { group, group_id, usersAccessCount } = stat;
                                key = group_id;
                                AboutCell = (
                                    <>
                                        <TableCell>{group.name}</TableCell>
                                        <TableCell>{group.chief ? <UserLinkWithPreview icon user={group.chief} /> : '---'}</TableCell>
                                        <TableCell className="crm-Estate__fieldNowrap">
                                            {usersAccessCount} ({group.usersCount})
                                        </TableCell>
                                    </>
                                );
                            }

                            return (
                                <TableRow key={key}>
                                    <TableCell>{index + 1}</TableCell>
                                    {AboutCell}
                                    <StatisticsCell value={kpi} greenValue={greenValue} redValue={redValue} />
                                    {pointsValue.map(({ value, point_id, pointType }, index) => {
                                        const { greenValue, redValue } = points[index];
                                        return <StatisticsCell key={point_id} value={value} greenValue={greenValue} redValue={redValue} />;
                                    })}
                                </TableRow>
                            );
                        })}
                </TableBody>
            </Table>
            <LoaderAwait active={statisticsLoading} />
        </>
    );
};

export default observer(TableKpi);
