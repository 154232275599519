import { observable, action, computed, reaction } from 'mobx';
import * as exportsApi from '~/api/export/exportsApi';
import settingStore from '~/stores/settingStore';
import exportingBaseStore from '~/stores/export/exportingBaseStore';

import { ExportingEstate, ExportingBaseFiltered, ExportedEstate } from '~/types/exports.types';
import ItemStorePrototype from '../prototypes/ItemStore.prototype';

import estateStore from '../estateStore';
import { ExportReportsAnswer } from '~/types/exports.types';
import { nProgress, nProgressItem } from '../helpers/decorators.helpers';
import authStore from '../authStore';
import { ACCESS_COMPANY, ACCESS_WRITE } from '~/types/access.types';
import { EstateAddressStrict } from '~/types/estate.types';

import exportRegionId from '../../common/exportRegionId.js';

export type ExportItemProperty = {
    exportingBaseNames: string[];
};

class ExportStore extends ItemStorePrototype<ExportingEstate, ExportItemProperty> {
    constructor() {
        super('estate_id', 'export', exportsApi);
    }

    @action
    setEditingItem(id: number, editingProps: Partial<ExportingEstate>) {
        super.setEditingItem(id, editingProps);
    }

    @action
    async fetchItem(estate_id: number) {
        try {
            await super.fetchItem(estate_id, null, true);
            this.matchBasesName(estate_id);
        } catch (error) {}
    }

    getItemForAccess(id: number): any {
        return estateStore.getItem(id);
    }

    @action
    matchBasesName(estate_id: number) {
        const { item } = this.getItem(estate_id);
        if (item) {
            const { bases } = item;

            const exportingBaseNames = bases
                .map(base => {
                    const foundBase = exportingBaseStore.exportingBases.find(
                        ({ export_base_id }) => export_base_id === base.export_base_id
                    );
                    if (!foundBase) return '';
                    return base.price_id ? foundBase.name : '';
                })
                .filter(name => name !== '');

            this.setProperty(estate_id, { exportingBaseNames });
        }
    }

    @action
    async saveItem(id: number): Promise<any> {
        const item = this.getItem(id);
        item.loadingItem = true;

        const result = await super.saveItem(id);
        item.loadingItem = false;

        this.matchBasesName(id);

        return result;
    }

    findBase(bases: Array<ExportedEstate>, base_id: number): ExportedEstate | null {
        const base = bases.find(({ export_base_id }) => export_base_id === base_id);
        if (base) {
            return base;
        } else {
            return null;
        }
    }

    @action
    changeTariff(estate_id: number, base_id: number, price_id: number) {
        const { bases } = this.getItem(estate_id).editingItem;
        const base = bases.find(({ export_base_id }) => export_base_id === base_id);
        if (base) {
            base.price_id = price_id;
            base.extra = null;
            base.bet = null;
            base.title = null;
        }

        if (!this.isEnableAllBasesToExporting(estate_id)) {
            bases.forEach(base => {
                base.price_id = 0;
                base.extra = null;
                base.bet = null;
                base.title = null;
            });
        }
    }

    @action
    changeExtra(id: number, base_id: number, extra: boolean) {
        const { bases } = this.getItem(id).editingItem;
        const base = bases.find(({ export_base_id }) => export_base_id === base_id);
        if (base) {
            base.extra = extra;
        }
    }

    @action
    changeExpireTime(id: number, base_id: number, time: number) {
        const { bases } = this.getItem(id).editingItem;
        const base = bases.find(({ export_base_id }) => export_base_id === base_id);
        if (base) {
            base.expireTime = time;
        }
    }

    @action
    changeBet(id: number, base_id: number, bet: number) {
        const { bases } = this.getItem(id).editingItem;
        const base = bases.find(({ export_base_id }) => export_base_id === base_id);
        if (base) {
            base.bet = bet;
        }
    }

    @action
    changeTitle(id: number, base_id: number, title: string) {
        const { bases } = this.getItem(id).editingItem;
        const base = bases.find(({ export_base_id }) => export_base_id === base_id);
        if (base) {
            base.title = title;
        }
    }

    @nProgressItem
    @action
    async disableExport(estate_id: number) {
        await exportsApi.disableExport(estate_id);
        this.mergeItem(estate_id, { bases: [] });
        this.matchBasesName(estate_id);
    }

    @observable
    loadingError: string;

    @action
    setLoadingError(error: string) {
        this.loadingError = error;
    }

    @observable
    loadingFile = false;

    @action
    startLoadingOwners() {
        this.loadingFile = true;
        this.loadingError = '';
        this.loadingAnswer = null;
    }

    @action
    stopLoadingOwners() {
        this.loadingFile = false;
    }

    @observable
    loadingAnswer: ExportReportsAnswer | null = null;

    @action
    setAnswer(answer: ExportReportsAnswer) {
        this.loadingAnswer = answer;
    }

    isEnableAllBasesToExporting(estate_id: number): boolean {
        const { dealId } = estateStore.getItem(estate_id).item;
        if (dealId) {
            // Если есть сделка, то можно включать любой экспорт
            return true;
        }

        if (
            !settingStore.mainConfig.enableCanonicalExportBasesId ||
            authStore.getPermission(this.moduleName, ACCESS_WRITE) === ACCESS_COMPANY
        ) {
            return true;
        }

        const CANONICAL_BASES_ID = settingStore.mainConfig['CANONICAL_EXPORT_BASES_ID'];

        const { editingItem } = this.getItem(estate_id);
        const { bases } = editingItem;

        if (bases.some(({ export_base_id, price_id }) => price_id > 0 && CANONICAL_BASES_ID.includes(export_base_id))) {
            return true;
        }
    }

    isEnableExportBase(estate_id: number, export_base_id: number): boolean {
        return (
            this.isEnableAllBasesToExporting(estate_id) ||
            (settingStore.mainConfig.enableCanonicalExportBasesId &&
                settingStore.mainConfig['CANONICAL_EXPORT_BASES_ID'].includes(export_base_id))
        );
    }

    @nProgress
    @action
    async redeemTransaction(estate_id: number, transaction_id: number): Promise<void> {
        await exportsApi.redeemTransaction(transaction_id);
        estateStore.fetchEstateExportHistory(estate_id);
    }

    filterTariffs(type: number, propertyType: number, address: Partial<EstateAddressStrict>): ExportingBaseFiltered[] {
        return exportingBaseStore.exportingBases
            .map(base => {
                const { tariffs } = base;
                let index;
                let current_region_id;
                const { region_id, regionName } = this.findRegionId(base.export_base_id, address);

                for (current_region_id of [region_id, 0]) {
                    index = tariffs.findIndex(
                        tariff => tariff.propertyType === propertyType && tariff.type === type && tariff.region_id === current_region_id
                    );
                    if (index > -1) {
                        break;
                    }
                }

                return {
                    export_base_id: index !== -1 ? base.export_base_id : -1,
                    name: base.name,
                    image: base.image,
                    tariff: tariffs[index],
                    format: base.format,
                    feedurl: base.feedurl,
                    config: base.config,
                    regionName: current_region_id !== 0 ? regionName : '',

                    createTime: base.createTime,
                    updateTime: base.updateTime,
                    enable: base.enable
                };
            })
            .filter(({ export_base_id, enable }) => export_base_id > -1 && enable);
    }

    findRegionId(export_base_id: number, address: Partial<EstateAddressStrict>): { region_id: number; regionName: string } {
        return exportRegionId(export_base_id, exportingBaseStore.regions, address);
    }
}

export default new ExportStore();
