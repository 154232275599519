import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Loader, List, Grid, Icon } from 'semantic-ui-react';
import contactStore from '~/stores/contactStore';
import authStore from '~/stores/authStore';
import ownerStore from '~/stores/ownerStore';
import ContactLinkWithPreview from '../../../Lists/Contacts/ContactLinkWithPreview';
import PhonesList from '../../../Base/PhonesList';
import { OwnerPhone } from '~/types/owners.types';
import { matchSourceUrl } from '~/components/Items/Estate/ShowBlocks/ExternalBlockShow';

type OwnerContactBlockProps = {
    contact_id?: number;
    estate_id: number;
    ownerName?: string | null;
    ownerPhones: OwnerPhone[];
    source?: string;
    sourceInnerId?: string;
};

@observer
class OwnerContactBlock extends Component<OwnerContactBlockProps> {
    componentDidMount() {
        const contact_id = Number(this.props.contact_id);

        if (contact_id > 0) {
            contactStore.fetchItem(contact_id);
        }
    }

    componentDidUpdate(prevProps: OwnerContactBlockProps) {
        const contact_id = Number(this.props.contact_id);

        if (Number(prevProps.contact_id) !== contact_id && contact_id > 0) {
            contactStore.fetchItem(contact_id);
        }
    }

    render() {
        const { contact_id, estate_id, ownerName, ownerPhones, source, sourceInnerId } = this.props;

        const { item: owner } = ownerStore.getItem(estate_id);
        if (!owner) {
            return null;
        }

        const { assignOwnersHistory } = owner;

        let theOwnerHasAssigned = false;

        if (assignOwnersHistory) {
            theOwnerHasAssigned = !!assignOwnersHistory.some(({ user_id }) => user_id === authStore.currentUser.user_id);
        }

        const SourceTitle = source ? (
            <Fragment>
                <Grid.Column>Источник</Grid.Column>
                <Grid.Column>
                    {!theOwnerHasAssigned ? (
                        <b>{source}</b>
                    ) : (
                        <a
                            title="Открыть ссылку на объявление в новом окне"
                            target="_blank"
                            href={matchSourceUrl(source, sourceInnerId)}
                            rel="noreferrer"
                        >
                            {source} <Icon name="external" />
                        </a>
                    )}
                </Grid.Column>
            </Fragment>
        ) : null;

        if (!theOwnerHasAssigned) {
            return (
                <>
                    <Grid columns={2} stretched>
                        <Grid.Row>{SourceTitle}</Grid.Row>
                    </Grid>
                    <b>Вы еще не открывали этого собственника</b>
                </>
            );
        }

        if (!contact_id && ownerPhones.length > 0) {
            return (
                <Grid columns={2} stretched>
                    <Grid.Row>
                        <Grid.Column>
                            <List>
                                <PhonesList phones={ownerPhones.map(({ phone }) => ({ phone, phone_id: 0 }))} />
                            </List>
                        </Grid.Column>
                        <Grid.Column>{ownerName && <span className="crm-Estate__fieldNowrap">{ownerName}</span>}</Grid.Column>
                        {SourceTitle}
                    </Grid.Row>
                </Grid>
            );
        }

        if (!contact_id) {
            return null;
        }

        let contact;
        let loadingItem;

        try {
            const contactItem = contactStore.getItem(contact_id);
            contact = contactItem.item;
            loadingItem = contactItem.loadingItem;
        } catch (e) {}
        if (!contact) {
            return null;
        }

        return loadingItem ? (
            <Loader active />
        ) : (
            <Grid columns={2} stretched>
                <Grid.Row>{SourceTitle}</Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <b>Собственник (Контакт): </b>
                    </Grid.Column>
                    <Grid.Column>
                        <ContactLinkWithPreview contact={contact} />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

export default OwnerContactBlock;
