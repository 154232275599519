import React, { Component, Fragment, SyntheticEvent } from 'react';
import { Menu, Label } from 'semantic-ui-react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import InfiniteScroll from 'react-infinite-scroller';

import Button from '@material-ui/core/Button';
import TuneIcon from '@material-ui/icons/Tune';
import CloseIcon from '@material-ui/icons/Close';
import TocIcon from '@material-ui/icons/Toc';
import IconButton from '@material-ui/core/IconButton';

import { observer } from 'mobx-react';
import './map.less';

import estateStore from '~/stores/estateStore';
import MapOrderBy from './MapOrderBy';
import ButtonGroup from '~/components/Base/ButtonGroup';
import { HandleChangeType } from '~/components/Items/HandlerEditingBlockWrapper';
import { ESTATE_BASE_EXTERNAL, ESTATE_BASE_FOREIGN, ESTATE_BASE_OWNERS, ESTATE_BASES, EstateFilter } from '~/types/estate.types';
import { EstatePreviewItem } from '~/components/Items/Estate/EstatePreview';
import LoadingItemPreviewContent from '~/components/Items/Common/ItemPreview/LoadingItemPreviewContent';
import EstateMapList from './EstateMapList';
import EstateMapFilter from './EstateMapFilter';
import EstateMapFilterChips from './EstateMapFilterChips';
import { adjustEstateListBtns } from '~/components/Lists/Estate';

type EstatePreviewProps = {
    estate_id: number;
    base: ESTATE_BASES;
};

@observer
class EstatePreviewMap extends Component<EstatePreviewProps> {
    constructor(props: EstatePreviewProps) {
        super(props);
        estateStore.fetchItem(props.estate_id, props.base);
    }

    render() {
        const { estate_id, base } = this.props;
        const { loadingItem } = estateStore.getItem(estate_id);
        if (loadingItem) {
            return <LoadingItemPreviewContent />;
        }

        return <EstatePreviewItem item_id={estate_id} base={base} handleClose={() => {}} onImgLoad={() => {}} />;
    }
}

@observer
class EstatesMapNewView extends Component<{}> {
    handleChangeBase: HandleChangeType = (event, { value }) => {
        estateStore.changeFilter('base', value);
        if ([ESTATE_BASE_OWNERS, ESTATE_BASE_EXTERNAL].includes(value)) {
            estateStore.changeFilter('deltaTime', 90 * 86400);
        }
    };

    handleShowFilterOnMap = () => {
        estateStore.setFilterOnMap(!estateStore.showFilterOnMap);
    };

    handleSearchOnTable = () => {
        estateStore.toggleSearchOnMap();
    };

    handleChange = (event: SyntheticEvent, { type, name, value }: { type: string; name: keyof EstateFilter; value: string }) => {
        let val = value;
        if (type === 'clearable' && value === '') {
            val = null;
        }

        estateStore.changeFilter(name, ['localNumber', 'number'].includes(type) ? Number(String(value).replace(/\D/g, '')) : val);
    };

    handleCheckbox = (event: SyntheticEvent, { name, checked }: { name: keyof EstateFilter; checked: boolean }) => {
        event.preventDefault();
        estateStore.changeFilter(name, checked);
    };

    componentWillUnmount() {
        estateStore.unmountSearchOnMap();
    }

    render() {
        const {
            listCount,
            loadingCount,
            listFilter: { base, propertyType },
            showFilterOnMap
        } = estateStore;

        return (
            <Fragment>
                <div className="crm-Map__estateMap" id="mapSearch">
                    <div className="crm-Map__chips">
                        <EstateMapFilterChips type="map" />
                    </div>
                </div>

                <div className="crm-Map__estateMap_list">
                    <Menu pointing secondary>
                        <Menu.Item name="list" active onClick={() => {}} color="yellow">
                            Найдено
                            <Label color="teal">{loadingCount ? '...' : listCount}</Label>
                        </Menu.Item>

                        {base !== ESTATE_BASE_FOREIGN && (
                            <Menu.Item>
                                <div className="form ui mini">
                                    <ButtonGroup
                                        buttonSet={adjustEstateListBtns(propertyType)}
                                        name="base"
                                        value={base}
                                        handleChange={this.handleChangeBase}
                                        stackable
                                    />
                                </div>
                            </Menu.Item>
                        )}

                        {!showFilterOnMap && (
                            <Fragment>
                                <Menu.Item>
                                    <Button variant="outlined" size="small" startIcon={<TuneIcon />} onClick={this.handleShowFilterOnMap}>
                                        Фильтр
                                    </Button>
                                </Menu.Item>

                                <Menu.Item position="right">
                                    <MapOrderBy onChange={(orderBy, orderDirection) => estateStore.fetchList(orderBy, orderDirection)} />
                                    <IconButton size="small" onClick={this.handleSearchOnTable} title="Перейти в таблицу">
                                        <TocIcon />
                                    </IconButton>
                                </Menu.Item>
                            </Fragment>
                        )}

                        {showFilterOnMap && (
                            <Menu.Item position="right">
                                <IconButton size="small" onClick={this.handleShowFilterOnMap} title="Закрыть фильтр">
                                    <CloseIcon />
                                </IconButton>
                            </Menu.Item>
                        )}
                    </Menu>

                    {!showFilterOnMap && (
                        <PerfectScrollbar>
                            <InfiniteScroll
                                pageStart={0}
                                initialLoad={false}
                                loadMore={estateStore.showNextOnMap.bind(estateStore)}
                                hasMore={estateStore.availableMoreOnMap}
                                loader={<div key={estateStore.selectedMapIds.length}>Загрузка ...</div>}
                                useWindow={false}
                            >
                                <div className="crm-Map__estateMap_estates">
                                    <EstateMapList />
                                </div>
                            </InfiniteScroll>
                        </PerfectScrollbar>
                    )}
                    {showFilterOnMap && <EstateMapFilter onCloseFilter={this.handleShowFilterOnMap} />}
                </div>
            </Fragment>
        );
    }
}

export default EstatesMapNewView;
