import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react';

import estateStore from '~/stores/estateStore';
import commonStore from '~/stores/commonStore';

import Modal, { ModalContent } from '~ui/Modal';
import Grid from '~ui/Grid';
import { InfoMessage, SuccessMessage } from '~ui/Message';
import Button from '~ui/Button';
import TextInput, { checkValidPattern } from '~ui/TextInput';
import Paper from '@material-ui/core/Paper';

import ContactDropdown from '~/components/ItemsDropdowns/ContactDropdown';
import PhoneIcon from '@material-ui/icons/Phone';
import contactStore from '~/stores/contactStore';
import mlsFixPriceStore from '~/stores/mlsFixPriceStore';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import ListError from '~/components/ListError';
import Price from '~/components/Base/Price';
import AgentBonus from '~/components/Base/AgentBonus';

const phoneRegExp = new RegExp(/^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/);

const FixPriceModal = ({ estate_id, onClose }: { estate_id: number; onClose: () => void }) => {
    useMemo(() => {
        mlsFixPriceStore.fetchItem(CREATING_ITEM_ID);
        mlsFixPriceStore.setEditingItem(CREATING_ITEM_ID, { phone: '', estate_id });
    }, []);

    const [loadingContact, setLoadingContact] = useState(false);

    const { price, currency, agentBonusType, agentBonusCurrency, agentBonusValue } = estateStore.getItem(estate_id).item;
    const [contact_id, setContactId] = useState<number | null>(null);

    const changeContactId = (contact_id: number) => {
        setContactId(contact_id);
        setLoadingContact(true);
        contactStore
            .fetchItem(contact_id)
            .then(() => {
                const { phones } = contactStore.getItem(contact_id).item;
                mlsFixPriceStore.setEditingItem(CREATING_ITEM_ID, { phone: phones[0].phone });
            })
            .finally(() => setLoadingContact(false));
    };

    const handleChangePhone = (event, { value }: { value: string }) => {
        mlsFixPriceStore.setEditingItem(CREATING_ITEM_ID, { phone: value });
    };

    const handleCreate = async () => {
        if (await mlsFixPriceStore.createItem()) {
            estateStore.fetchOwnFixedPriceEstates(estate_id);
            onClose();
        }
    };

    const {
        editingItem: { phone },
        loadingItem,
        errors
    } = mlsFixPriceStore.getItem(CREATING_ITEM_ID);

    return (
        <Modal header="Зафиксировать цену" onClose={onClose} maxWidth="xs" fullScreen={commonStore.isMobile} loading={loadingItem}>
            <ModalContent>
                <Grid>
                    <Grid.Column>
                        <ContactDropdown onChange={changeContactId} item_id={contact_id} />
                    </Grid.Column>
                    <Grid.Column>
                        <Paper style={{ padding: '1rem' }} elevation={2}>
                            <TextInput
                                mask="+7 (000) 000-00-00"
                                startLabel={<PhoneIcon />}
                                value={phone}
                                label="Телефон покупателя"
                                onChange={handleChangePhone}
                                pattern={phoneRegExp}
                                required
                                loading={loadingContact}
                            />
                        </Paper>
                    </Grid.Column>
                    <Grid.Column>
                        <InfoMessage header="Цена объекта">
                            <Price price={price} currency={currency} />
                        </InfoMessage>
                        <SuccessMessage header="Бонус агенту">
                            <AgentBonus
                                agentBonusCurrency={agentBonusCurrency}
                                agentBonusType={agentBonusType}
                                agentBonusValue={agentBonusValue}
                            />
                        </SuccessMessage>
                    </Grid.Column>
                    <Grid.Column>
                        <ListError errors={errors} />
                        <Button
                            fullWidth
                            color="primary"
                            variant="contained"
                            disabled={!phone || phone.replace(/\D/g, '').length !== 11}
                            loading={loadingItem}
                            onClick={handleCreate}
                        >
                            Зафиксировать
                        </Button>
                    </Grid.Column>
                </Grid>
            </ModalContent>
        </Modal>
    );
};

export default observer(FixPriceModal);
