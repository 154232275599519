import Nprogress from 'nprogress';

export const catchItemError = (target: any, property: string, descriptor: { value?: (...args: any) => any }) => {
    const originalMethod = descriptor.value;

    descriptor.value = async function (...args) {
        Nprogress.start();
        const item_id = args[0];
        this.getItem(item_id).errors = [];
        this.getItem(item_id).loadingItem = true;

        try {
            return await originalMethod.call(this, ...args);
        } catch (errors) {
            this.getItem(item_id).errors = errors;
            return 0;
        } finally {
            this.getItem(item_id).loadingItem = false;
            Nprogress.done();
        }
    };

    return descriptor;
};

export const catchOnlyError = (target: any, property: string, descriptor: { value?: (...args: any) => any }) => {
    const originalMethod = descriptor.value;

    descriptor.value = async function (...args) {
        const item_id = args[0];
        this.getItem(item_id).errors = [];

        try {
            return await originalMethod.call(this, ...args);
        } catch (errors) {
            this.getItem(item_id).errors = errors;
            return 0;
        }
    };

    return descriptor;
};

export const nProgress = (target: any, property: string, descriptor: { value?: (...args: any) => any }) => {
    const originalMethod = descriptor.value;

    descriptor.value = async function (...args) {
        Nprogress.start();

        try {
            return await originalMethod.call(this, ...args);
        } catch (errors) {
            console.log(errors);
            throw errors;
        } finally {
            Nprogress.done();
        }
    };

    return descriptor;
};

export const nProgressItem = (target: any, property: string, descriptor: { value?: (...args: any) => any }) => {
    const originalMethod = descriptor.value;

    descriptor.value = async function (...args) {
        Nprogress.start();

        const item_id = args[0];
        this.getItem(item_id).errors = [];

        try {
            return await originalMethod.call(this, ...args);
        } catch (errors) {
            this.getItem(item_id).errors = errors;
            return 0;
        } finally {
            Nprogress.done();
        }
    };

    return descriptor;
};

export const withAbortController = (abortControllerName: string) => (
    target: any,
    property: string,
    descriptor: { value?: (...args: any) => any }
) => {
    const originalMethod = descriptor.value;

    descriptor.value = async function (...args) {
        const abortedKey = `${property}_aborted`;
        this[abortedKey] = false;

        if (this[abortControllerName]) {
            this[abortedKey] = true;
            this[abortControllerName].abort();
        }

        const controller = window.AbortController ? new window.AbortController() : null;
        this[abortControllerName] = controller;

        try {
            await originalMethod.apply(this, args);
            this[abortControllerName] = null;
        } catch (error) {
            if (!this[abortedKey]) {
                console.log(error);
                throw error;
            }
        }
    };

    return descriptor;
};
