import { EstateAddressStrict } from './estate.types';

export const NEWBUILDING_BASE_YANDEX = 'yandex';
export const NEWBUILDING_BASE_AVITO = 'avito';

export type NEWBUILDING_BASES = 'yandex' | 'avito';

export type YandexHouse = {
    yandex_house_id: number;
    yandex_newobject_id: number;
    newbuilding_id: number;
    newbuilding_house_id: number;

    address: string;
    name: string;
    url: string;

    buildYear: number;
    readyQuarter: number;
    buildingPhase: string;
};

export type AvitoHouse = {
    avito_house_id: number;
    avito_newobject_id: number;
    newbuilding_id: number;
    newbuilding_house_id: number;

    address: string;
    name: string;
    developer: string;

    enable: boolean;
};

export type NewbuildingInBase = EstateAddressStrict & {
    readonly newbuilding_id: number;
    name: string;
};

export type Newbuilding = NewbuildingInBase & {
    houses?: NewBuildingHouse[];
};

export type NewBuildingHouse = EstateAddressStrict & {
    readonly newbuilding_house_id: number;
    newbuilding_id: number;
    name: string;
};
