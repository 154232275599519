import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { toDateString } from '~/common/time';

import Grid from '@material-ui/core/Grid';

import exportStatisticsStore from '~/stores/export/exportStatisticsStore';
import Progress from '~ui/Progress';
import ListError from '../../../ListError';
import CardWithStatPie from '../SubBlocks/StatisticsMode/CardWithStatPie';
import EstateStatisticsChart from '../SubBlocks/StatisticsMode/EstateStatisticsChart';
import StatisticsPult from '../SubBlocks/StatisticsMode/StatisticsPult';
import EstateStatisticsTable from '../SubBlocks/StatisticsMode/EstateStatisticsTable';
import { DAY_MS } from '~/common/time';
import estateStore from '~/stores/estateStore';
import { WarningMessage } from '~ui/Message';

const EstateStatisticsMode = ({ estate_id }: { estate_id: number }) => {
    useMemo(() => {
        exportStatisticsStore.fetchItem(estate_id);
        exportStatisticsStore.setInitFilter(estate_id);
        exportStatisticsStore.fetchEstateStatistics(estate_id);
    }, []);

    const { chartStatistics, statisticsChartPult, sumStatisticsMatched, statisticsErrors } = exportStatisticsStore.getItem(
        estate_id
    ).property;

    if (statisticsErrors) {
        return <ListError errors={statisticsErrors} />;
    }

    if (!chartStatistics) {
        return <Progress show />;
    }

    const isStatChartEnable = Boolean(chartStatistics && chartStatistics.length > 1);
    const { views, phoneShows } = sumStatisticsMatched;

    const deltaDays = chartStatistics.length;
    const timeDuration = `За ${deltaDays} ${deltaDays === 1 ? 'день' : deltaDays < 5 ? 'дня' : 'дней'}`;

    const { priceHistory } = estateStore.getItem(estate_id).item;

    const reversePrice = [...priceHistory].reverse();
    const priceAcc = reversePrice.slice(1).reduce((acc, { price, time }, index) => {
        // Убираем засечки больше года, иначе они попадают на текцющий год
        if (Date.now() - time * 1000 > 365 * DAY_MS) {
            return acc;
        }

        const key = toDateString(new Date(time * 1000));
        const deltaPrice = price - reversePrice[index].price;
        if (!acc[key]) {
            acc[key] = 0;
        }
        acc[key] += deltaPrice;
        return acc;
    }, {});

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <StatisticsPult estate_id={estate_id} />

                {isStatChartEnable && (
                    <div style={{ height: '400px' }}>
                        <EstateStatisticsChart
                            prices={priceAcc}
                            chartStatistics={chartStatistics}
                            statisticsChartPult={statisticsChartPult}
                        />
                    </div>
                )}
                {!isStatChartEnable && <WarningMessage>Статистика пока недоступна</WarningMessage>}
            </Grid>

            <Grid item xs={12} sm={6}>
                <CardWithStatPie data={views} title="Просмотры" subTitle={timeDuration} />
            </Grid>

            <Grid item xs={12} sm={6}>
                <CardWithStatPie data={phoneShows} title="Показы телефона" subTitle={timeDuration} />
            </Grid>

            <Grid item xs={12}>
                <EstateStatisticsTable sumStatisticsMatched={sumStatisticsMatched} />
            </Grid>
        </Grid>
    );
};

export default observer(EstateStatisticsMode);
