import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Card, Grid, Header } from 'semantic-ui-react';

import EditingBlockWrapper from '../../EditingBlockWrapper';
import estateStore from '~/stores/estateStore';

import ShadowFragment from '../../../Base/ShadowFragment';
import CommerceLandEditing from '../EditBlocks/CommerceLandEditing';

import { EditingWrappedProps } from '../../EditingBlockWrapper';
import estateL10n from '../../../../L10n/estate.L10n';
import { nl2br } from '~/api/commentsApi';
import { printLandAreaUnitType } from './EstateInfoBlock';

const PossibleChange = () => (
    <Fragment>
        <br />
        <b>! возможно подключить</b>
    </Fragment>
);

@observer
class CommerceLandShow extends Component<EditingWrappedProps> {
    render() {
        const { item_id, editing } = this.props;
        const { item, loadingItem } = estateStore.getItem(item_id);
        if (!item) {
            return null;
        }
        const {
            taxNumber,
            availableFrom,
            landArea,
            landAreaUnitType,
            landStatus,
            description,
            possibleToChangeLandStatus,
            permittedUseType,
            possibleToChangePermittedUseType,
            hasInvestmentProject,
            hasEncumbrances,
            electricityLocationType,
            electricityPossibleToConnect,
            electricityPower,
            gasLocationType,
            gasPossibleToConnect,
            gasCapacity,
            gasPressureType,
            drainageLocationType,
            drainagePossibleToConnect,
            drainageCapacity,
            drainageType,
            waterLocationType,
            waterPossibleToConnect,
            waterCapacity,
            waterType,
            drivewayType,
            distanceFromMKAD,
            highway_id
        } = item;
        const { highwaysListLoading } = estateStore;

        const highway = !highwaysListLoading && estateStore.highwaysList.find(highway => highway.highway_id === highway_id);

        let highwayName = '';
        if (highway) {
            highwayName = highway.name;
        }

        return (
            <Card fluid color="blue">
                {!editing && (
                    <Fragment>
                        <Card.Content>
                            <span dangerouslySetInnerHTML={{ __html: description && nl2br(description) }} />
                        </Card.Content>
                        <Card.Content>
                            <Grid columns={2}>
                                <Grid.Row>
                                    <Grid.Column>Номер налоговой</Grid.Column>
                                    <Grid.Column>{taxNumber}</Grid.Column>
                                </Grid.Row>
                                {availableFrom && (
                                    <Grid.Row>
                                        <Grid.Column>Освобождается</Grid.Column>
                                        <Grid.Column>{new Date(availableFrom * 1000).toLocaleDateString()}</Grid.Column>
                                    </Grid.Row>
                                )}
                            </Grid>
                            {highway && (
                                <Fragment>
                                    <Header size="tiny">Шоссе</Header>
                                    <Grid columns={1}>
                                        <Grid.Row>
                                            <Grid.Column>
                                                {highwayName} шоссе, {distanceFromMKAD} км от МКАД
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Fragment>
                            )}
                            <Header size="tiny">Участок</Header>
                            <Grid columns={2}>
                                <Grid.Row>
                                    <Grid.Column>Площадь</Grid.Column>
                                    <Grid.Column>
                                        <ShadowFragment
                                            shadow={loadingItem}
                                            print={`${landArea || '-'} ${printLandAreaUnitType(landArea, landAreaUnitType)}`}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>Категория земли</Grid.Column>
                                    <Grid.Column>
                                        <ShadowFragment
                                            shadow={loadingItem}
                                            print={estateL10n.COMMERCE_LAND_AREA_STATUS_TYPE_FIND(landStatus)}
                                        />
                                        {possibleToChangeLandStatus && <PossibleChange />}
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>Вид разрешённого использования</Grid.Column>
                                    <Grid.Column>
                                        <ShadowFragment
                                            shadow={loadingItem}
                                            print={estateL10n.COMMERCE_PERMITTED_USE_TYPE_FIND(permittedUseType)}
                                        />
                                        {possibleToChangePermittedUseType && <PossibleChange />}
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>{hasInvestmentProject && 'Есть инвестпроект'}</Grid.Column>
                                    <Grid.Column>{hasEncumbrances && 'Есть обременение'}</Grid.Column>
                                </Grid.Row>
                            </Grid>

                            <Header size="tiny">Инфраструктура на участке</Header>
                            <Grid columns={2}>
                                <Grid.Row>
                                    <Grid.Column>Электроснабжение</Grid.Column>
                                    <Grid.Column>
                                        <ShadowFragment
                                            shadow={loadingItem}
                                            print={estateL10n.ELECTRICITY_LOCATION_TYPE_FIND(electricityLocationType)}
                                        />
                                        {electricityPower && `, ${electricityPower} кВТ`}
                                        {electricityPossibleToConnect && <PossibleChange />}
                                    </Grid.Column>
                                </Grid.Row>

                                <Grid.Row>
                                    <Grid.Column>Газоснабжение</Grid.Column>
                                    <Grid.Column>
                                        <ShadowFragment
                                            shadow={loadingItem}
                                            print={estateL10n.ELECTRICITY_LOCATION_TYPE_FIND(gasLocationType)}
                                        />
                                        {gasCapacity && `, ${gasCapacity} м³/час`}
                                        {gasPressureType && <div>Давление: {estateL10n.GAS_PRESSURE_TYPE_FIND(gasPressureType)}</div>}
                                        {gasPossibleToConnect && <PossibleChange />}
                                    </Grid.Column>
                                </Grid.Row>

                                <Grid.Row>
                                    <Grid.Column>Канализация</Grid.Column>
                                    <Grid.Column>
                                        <ShadowFragment
                                            shadow={loadingItem}
                                            print={estateL10n.ELECTRICITY_LOCATION_TYPE_FIND(drainageLocationType)}
                                        />
                                        {drainageCapacity && `, ${drainageCapacity} м³/сутки`}
                                        {drainageType && <div>Давление: {estateL10n.DRAINAGE_TYPE_FIND(drainageType)}</div>}
                                        {drainagePossibleToConnect && <PossibleChange />}
                                    </Grid.Column>
                                </Grid.Row>

                                <Grid.Row>
                                    <Grid.Column>Водоснабжение</Grid.Column>
                                    <Grid.Column>
                                        <ShadowFragment
                                            shadow={loadingItem}
                                            print={estateL10n.ELECTRICITY_LOCATION_TYPE_FIND(waterLocationType)}
                                        />
                                        {waterCapacity && `, ${waterCapacity} м³/сутки`}
                                        {waterType && <div>Тип водоснабжения: {estateL10n.COMMERCE_LAND_WATER_TYPE_FIND(waterType)}</div>}
                                        {waterPossibleToConnect && <PossibleChange />}
                                    </Grid.Column>
                                </Grid.Row>

                                <Grid.Row>
                                    <Grid.Column>Подъездные пути</Grid.Column>
                                    <Grid.Column>{estateL10n.COMMERCE_LAND_DRIVEWAY_TYPE_FIND(drivewayType)}</Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Card.Content>
                    </Fragment>
                )}
                {editing && (
                    <Card.Content>
                        <CommerceLandEditing item_id={item_id} editing={editing} {...item} />
                    </Card.Content>
                )}
            </Card>
        );
    }
}

export default EditingBlockWrapper(estateStore, CommerceLandShow);
