import { objectToGraphql } from '~/common/graphql';
import fetchApi from '~/common/fetchApi';
import { MlsEstateFixPrice } from '~/types/estate.types';
import { OrderDirectionType } from '~/stores/prototypes/ListStore.prototype';

export const GQMlsFixPriceMainFields = `
    estate_fix_price_id
    major_user_id
    database

    estate_id
    estate {
        estate_id
        type
        address
        houseNumberAdjusted
        roomsCount
        propertyType
        enable
    }
    status
    phone

    price
    currency

    agentBonusType
    agentBonusValue
    agentBonusCurrency

    createTime
    updateTime
    enable 
`;

export const fetchItem = async (estate_fix_price_id: number): Promise<MlsEstateFixPrice> => {
    const graphql = `{
        fetchMlsEstateFixPrice(estate_fix_price_id: ${estate_fix_price_id}) {
            ${GQMlsFixPriceMainFields}
        } 
      }`;

    const data: { fetchMlsEstateFixPrice: MlsEstateFixPrice } = await fetchApi.getGQ(graphql);
    return data.fetchMlsEstateFixPrice;
};

export const createItem = async (estateFixPriceInput: MlsEstateFixPrice): Promise<number> => {
    const graphql = `mutation { 
          createMlsEstateFixPrice(estateFixPriceInput: ${objectToGraphql(estateFixPriceInput)})
        }`;

    const data: { createMlsEstateFixPrice: number } = await fetchApi.postGQ(graphql);
    return data.createMlsEstateFixPrice;
};

export const fetchList = async (
    limit: number,
    offset: number,
    orderBy: string,
    orderDirection: OrderDirectionType,
    fixPriceFilter: {},
    controller?: AbortController
): Promise<{ list: MlsEstateFixPrice[]; count: number }> => {
    const graphql = `{
            fetchMlsEstateFixPrices(limit: ${limit}, offset: ${offset}, orderBy: "${orderBy}", 
            sort: "${orderDirection}", filter: ${objectToGraphql(fixPriceFilter)}) {
                list {
                    ${GQMlsFixPriceMainFields}
                    agentFirstName
                    agentLastName
                    agentPhone
                }
                count
            } 
          }`;

    const data: { fetchMlsEstateFixPrices: { list: MlsEstateFixPrice[]; count: number } } = await fetchApi.getGQ(graphql, controller);
    return data.fetchMlsEstateFixPrices;
};

export const saveItem = async (estate_fix_price_id: number, estateFixPriceDiff: MlsEstateFixPrice): Promise<number> => {
    const graphql = `mutation { 
        updateMlsEstateFixPrice(estate_fix_price_id: ${estate_fix_price_id}, estateFixPriceDiff: ${objectToGraphql(estateFixPriceDiff)})
    }`;

    const data: { updateMlsEstateFixPrice: number } = await fetchApi.postGQ(graphql);
    return data.updateMlsEstateFixPrice;
};
