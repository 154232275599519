import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Divider from '~ui/Divider';

import ItemContactsEditing from '../Common/ItemContacts/ItemContactsEditing';

import ItemAddModeWrapper, { ItemAddModeWrapperProps } from '../ItemAddModeWrapperNew';
import { ContactLinkState } from '../../Lists/Contacts/ContactLink';
import contactStore from '~/stores/contactStore';

import PersonalBlockEditing from './EditBlocks/PersonalBlockEditing';
import TagsBlockEditing from './EditBlocks/TagsBlockEditing';
import AdditionalInfoBlockEditing from './EditBlocks/AdditionalInfoBlockEditing';
import history from '../../../history';
import { Phone } from '~/types/users.types';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import CreationButtonsSet from '../Common/CreationButtonsSet';
import KanbanListBlockEditing from '~/components/Items/Contact/EditBlocks/KanbanListBlockEditing';

type ContactAddModeProps = RouteComponentProps<{}> & ItemAddModeWrapperProps;

@observer
class ContactAddMode extends Component<ContactAddModeProps, { phones: Phone[] }> {
    phones: Phone[];

    constructor(props: ContactAddModeProps) {
        super(props);

        const {
            location: {
                state: { editingData }
            }
        } = history;

        const phones = [];

        if (editingData && 'phone' in editingData) {
            phones.push({ phone: editingData['phone'], phone_id: CREATING_ITEM_ID });
        } else {
            phones.push({ phone: '', phone_id: CREATING_ITEM_ID });
        }

        this.phones = phones;
    }

    render() {
        const { loadingItem } = contactStore.getItem(CREATING_ITEM_ID);

        return (
            <Fragment>
                <PersonalBlockEditing item_id={CREATING_ITEM_ID} />
                <Divider />
                <ItemContactsEditing store={contactStore} phones={this.phones} item_id={CREATING_ITEM_ID} />
                <Divider />
                <TagsBlockEditing item_id={CREATING_ITEM_ID} />
                <Divider />
                <KanbanListBlockEditing contact_id={CREATING_ITEM_ID} kanban_list_ids_default={[]} />
                <Divider />

                <AdditionalInfoBlockEditing item_id={CREATING_ITEM_ID} />
                <Divider />

                <CreationButtonsSet loading={loadingItem} onCreate={this.props.handleCreate} onClearForm={this.props.clearForm} />
            </Fragment>
        );
    }
}

export default ItemAddModeWrapper(contactStore, ContactLinkState, withRouter(ContactAddMode));
