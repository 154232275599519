import React, { Component, Fragment, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Card, Grid, Image, Embed, Accordion, Icon, Header } from 'semantic-ui-react';
import Lightbox from 'react-images';
import ImageGallery from 'react-image-gallery';

import estateStore from '~/stores/estateStore';
import EditingBlockWrapper, { EditingWrappedProps } from '../../EditingBlockWrapper';
import PhotosBlockEditing from '../EditBlocks/PhotosBlockEditing';
import deepCopy from '../../../../common/deepCopy';
import { matchEstateThumbnailPhotoUrl } from '~/stores/helpers/estate.store.helpers';
import LoaderAwait from '../../../Base/LoaderAwait';
import commonStore from '~/stores/commonStore';
import { UploadingPhoto } from '~/types/estate.types';
import { WarningMessage, MessageTitle } from '~ui/Message';

const AWAIT_FOR_PHOTOS_SHOWING_MS = 400;

type PhotoGalleryProps = {
    photos: UploadingPhoto[];
    updatingPhotos: boolean;
    loadingItem: boolean;
};

const PhotoGalleryDesktop = ({ photos, updatingPhotos, loadingItem }: PhotoGalleryProps) => {
    const [waitingForShowing, setWaitingForShowing] = useState(true); // wait while the card will be opened (for good performance)
    const waitingInterval = window.setTimeout(() => {
        setWaitingForShowing(false);
    }, AWAIT_FOR_PHOTOS_SHOWING_MS);

    useEffect(() => {
        return () => {
            clearInterval(waitingInterval);
        };
    }, []);

    const [photosModalShow, setPhotosModalShow] = useState(false);
    const togglePhotosModalShow = () => {
        setPhotosModalShow(!photosModalShow);
    };

    const [currentImage, setCurrentImage] = useState(0);
    const handleImageNumber = (index: number) => {
        setPhotosModalShow(true);
        setCurrentImage(index);
    };

    const handlePrevImage = () => {
        setCurrentImage(currentImage - 1);
    };
    const handleNextImage = () => {
        setCurrentImage(currentImage + 1);
    };

    const photoSet = !updatingPhotos ? photos && photos.map(({ filename }) => matchEstateThumbnailPhotoUrl(filename, updatingPhotos)) : [];

    const loadingHasDone = !waitingForShowing && !loadingItem && !updatingPhotos;

    return (
        <Fragment>
            <Grid columns={3} className="crm-Estate__photolist">
                <Grid.Row>
                    {(waitingForShowing || updatingPhotos || loadingItem) &&
                        [0, 1, 2].map(key => (
                            <Grid.Column key={key}>
                                <LoaderAwait active size="medium" />
                            </Grid.Column>
                        ))}

                    {loadingHasDone &&
                        photoSet.slice(0, 3).map(({ src, tempSrc }, i) => {
                            const label = i === 2 &&
                                photos.length > 3 && {
                                    as: 'button',
                                    color: 'brown',
                                    content: `Всего: ${photos.length} фото`,
                                    icon: 'plus',
                                    ribbon: 'right'
                                };

                            return (
                                <Grid.Column key={i}>
                                    {!photos[i].isLoading ? (
                                        <Image
                                            src={src}
                                            bordered
                                            label={label}
                                            onClick={() => handleImageNumber(i)}
                                            style={{ cursor: 'zoom-in' }}
                                        />
                                    ) : (
                                        <Fragment>
                                            <Image src={tempSrc} bordered label={label} />
                                            <LoaderAwait active size="medium" />
                                        </Fragment>
                                    )}
                                </Grid.Column>
                            );
                        })}
                </Grid.Row>
            </Grid>
            {loadingHasDone && photos.length > 0 && (
                <Lightbox
                    images={photoSet}
                    isOpen={photosModalShow}
                    onClose={togglePhotosModalShow}
                    onClickPrev={handlePrevImage}
                    onClickNext={handleNextImage}
                    currentImage={currentImage}
                    imageCountSeparator=" из "
                    leftArrowTitle="Предыдущая (клавиша: стрелка влево)"
                    rightArrowTitle="Следующая (клавиша: стрелка вправо)"
                    showThumbnails={true}
                    onClickThumbnail={handleImageNumber}
                />
            )}
        </Fragment>
    );
};

const PhotoGalleryMobile = ({ photos, updatingPhotos, loadingItem }: PhotoGalleryProps) => {
    const [waitingForShowing, setWaitingForShowing] = useState(true); // wait while the card will be opened (for good performance)
    const waitingInterval = window.setTimeout(() => {
        setWaitingForShowing(false);
    }, AWAIT_FOR_PHOTOS_SHOWING_MS);

    useEffect(() => {
        return () => {
            clearInterval(waitingInterval);
        };
    }, []);

    const images = photos
        ? photos.map(photo => ({
              original: matchEstateThumbnailPhotoUrl(photo.filename, false, 2).src,
              fullscreen: matchEstateThumbnailPhotoUrl(photo.filename, false, 0).src
          }))
        : [];

    return (
        <div className="crm-Estate__photoMobile">
            <ImageGallery
                items={images}
                showNav={!commonStore.isMobile && photos.length > 1}
                showFullscreenButton={true}
                showThumbnails={false}
                showPlayButton={false}
                showBullets={images.length > 1}
                lazyLoad
            />

            <LoaderAwait active={waitingForShowing || loadingItem || updatingPhotos} size="medium" />
        </div>
    );
};

type PhotosBlockShowState = {
    showYoutubeAccordion: boolean;
};

@observer
class PhotosBlockShow extends Component<EditingWrappedProps, PhotosBlockShowState> {
    constructor(props: EditingWrappedProps) {
        super(props);

        this.state = {
            showYoutubeAccordion: false
        };
    }

    handleToggleYoutube = () => {
        this.setState({ showYoutubeAccordion: !this.state.showYoutubeAccordion });
    };

    render() {
        const { isMobile } = commonStore;
        const { item_id, editing } = this.props;
        const {
            item,
            loadingItem,
            property: { updatingPhotos }
        } = estateStore.getItem(item_id);

        const { photos, youtubeId } = item || {};
        const { showYoutubeAccordion } = this.state;

        return (
            <div className="crm-Item__editingMode">
                {!editing && !loadingItem && photos.length === 0 && (
                    <WarningMessage>
                        <MessageTitle>У данного объекта нет фотографий!</MessageTitle>
                    </WarningMessage>
                )}

                {(updatingPhotos || loadingItem || photos.length > 0) && !editing && (
                    <Fragment>
                        {isMobile && <PhotoGalleryMobile photos={photos} loadingItem={loadingItem} updatingPhotos={updatingPhotos} />}
                        {!isMobile && <PhotoGalleryDesktop photos={photos} loadingItem={loadingItem} updatingPhotos={updatingPhotos} />}
                    </Fragment>
                )}

                {editing && (
                    <Card.Content>
                        <PhotosBlockEditing estate_id={item_id} photos={deepCopy(Array.from(photos))} youtubeId={youtubeId} />
                    </Card.Content>
                )}
                {!editing && youtubeId && (
                    <Accordion>
                        <Accordion.Title active={showYoutubeAccordion} onClick={this.handleToggleYoutube}>
                            <Header size="small">
                                <Header.Content>
                                    <Icon name="dropdown" />
                                    <Icon name="youtube" color="red" /> YouTube ролик
                                </Header.Content>
                            </Header>
                        </Accordion.Title>
                        <Accordion.Content active={showYoutubeAccordion}>
                            {showYoutubeAccordion && (
                                <Embed
                                    id={youtubeId}
                                    placeholder={`https://img.youtube.com/vi/${youtubeId}/hqdefault.jpg`}
                                    source="youtube"
                                />
                            )}
                        </Accordion.Content>
                    </Accordion>
                )}
            </div>
        );
    }
}

export default EditingBlockWrapper(estateStore, PhotosBlockShow, null, { disableSwapping: true });
