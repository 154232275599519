import React from 'react';

import ItemHistoryModeWrapper from '../../ItemHistoryModeWrapper';
import exportStore from '~/stores/export/exportStore';
import { ExportedEstate } from '~/types/exports.types';
import exportingBaseStore from '~/stores/export/exportingBaseStore';

const exportKeys = {
    bases: 'Базы',
    major_user_ids: 'На кого установлен экспорт',
    enableExportInBasesIds: 'Включен экспорт в базу(ы) с таблицы',
    disableExportInBasesIds: 'Отменен экспорт в базу(ы) с таблицы',
    changeCallTransactionPrice: 'Изменение цены звонка',
    price: 'Цена',
    disableExporting: 'Отключение экспорта',
    changeDomclickTariffByDomckick: 'Тариф на ДомКлик изменен автоматически',
    redeemTransactionId: 'Обнуление транзакции',
    disableExportingBase: 'Отключен фид',
    getErrorByApiReport: 'Отключен автоматически из-за ошибки в экспорте',
    applyAvitoXLPrice: 'Подключение пакета Авито'
};

const printExportBases = (bases: ExportedEstate[]): string => {
    const arrayOfBasses = bases.map(({ export_base_id, price_id, extra, bet, title, expireTime }) => {
        const foundBase = exportingBaseStore.exportingBases.find(base => base.export_base_id === export_base_id);
        if (foundBase) {
            const foundPrice = foundBase.tariffs
                .reduce((acc, tariff) => {
                    acc.push(...tariff.prices);
                    return acc;
                }, [])
                .find(price => price.price_id === price_id) || { name: '' };
            if (price_id === 0) {
                return `${foundBase['name']} - выкл.`;
            }

            return `${foundBase['name']} 
                        (тариф: ${foundPrice['name']}
                        ${extra ? '; +экстра ' : ''}
                        ${bet ? `; ставка: ${bet} ` : ''}
                        ${title ? `; заголовок: ${title} ` : ''}
                        ${expireTime ? `; до ${new Date(expireTime * 1000).toLocaleDateString()} ` : ''})`;
        } else {
            return String(export_base_id);
        }
    });

    return arrayOfBasses.join('; ');
};

const getBaseName = (baseId: number): string => {
    const foundBase = exportingBaseStore.exportingBases.find(base => base.export_base_id === baseId);
    if (foundBase) {
        return foundBase['name'];
    } else {
        return String(baseId);
    }
};

const printBasesNames = (basesIds: number[]): string => {
    const arrayOfBasses = basesIds.map(export_base_id => getBaseName(export_base_id));
    return arrayOfBasses.join('; ');
};

const printExportVal = (key: string, value: unknown): string => {
    switch (key) {
        case 'bases':
            return value instanceof Array ? printExportBases(value) : '';
        case 'enableExportInBasesIds':
            return value instanceof Array ? printBasesNames(value) : '';
        case 'disableExportInBasesIds':
            return value instanceof Array ? printBasesNames(value) : '';
        case 'disableExportingBase':
            return typeof value === 'number' ? getBaseName(value) : '';
        case 'getErrorByApiReport':
            return value instanceof Array ? printBasesNames(value) : '';
        case 'major_user_ids':
            return value instanceof Array ? value.join(', ') : '';
        default:
            break;
    }

    if (typeof value === 'boolean') {
        return value ? 'да' : 'нет';
    } else if (value === null) {
        return 'н/д';
    }

    return String(value);
};

export default ItemHistoryModeWrapper(exportStore, exportKeys, printExportVal);
