import React, { Component, SyntheticEvent } from 'react';
import { observer } from 'mobx-react';
import { Modal, ModalBtnAction, ModalContent } from '~ui/Modal';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';

import TextInput from '~ui/TextInput';
import Grid from '~ui/Grid';

import { AccessModeType, ACCESS_NONE } from '~/types/access.types';
import accessStore, { ACCESS_MODES_KEYS, ACCESS_MODULE_NAME } from '~/stores/accessStore';
import { checkFormValid } from '~/common/forms';
import HandlerEditingBlockWrapper from '../../Items/HandlerEditingBlockWrapper';
import { HandlerEditingWrappedProps } from '../../Items/HandlerEditingBlockWrapper';
import ListError from '../../ListError';
import { CREATING_ITEM_ID } from '~/stores/prototypes/ItemStore.prototype';
import commonStore from '~/stores/commonStore';

import AccessTableEditing from './EditingBlocks/AccessTableEditing';
import AccessExportEditing from './EditingBlocks/AccessExportEditing';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

type AccessModalProps = HandlerEditingWrappedProps & { handleClose: () => void };

@observer
class AccessModal extends Component<AccessModalProps, { tabId: number }> {
    $form: HTMLFormElement | null = null;

    constructor(props: AccessModalProps) {
        super(props);
        const { item_id } = this.props;
        accessStore.fetchItem(item_id);
        this.state = { tabId: 0 };

        if (item_id === CREATING_ITEM_ID) {
            const { accessModes } = accessStore;

            const access = {
                access_id: item_id,
                title: '',
                modules: accessModes.map(({ name }) => ({
                    name,
                    access: ACCESS_MODES_KEYS.reduce((obj, mode) => {
                        obj[mode] = ACCESS_NONE;
                        return obj;
                    }, {} as AccessModeType)
                })),
                enable: true
            };

            accessStore.setEditingItem(item_id, access);
        }
    }

    handleCreateAccess = async (event: SyntheticEvent) => {
        if (checkFormValid(this.$form, true)) {
            event.preventDefault();
            event.stopPropagation();

            const { item_id } = this.props;

            if (item_id === 0) {
                await accessStore.createItem();
                const { errors } = accessStore.getItem(item_id);
                if (errors && errors.length) {
                    return;
                }
            } else {
                if (!(await accessStore.saveItem(item_id))) {
                    return;
                }
            }

            this.props.handleClose();
            accessStore.fetchList();
        }
    };

    handleDelete = async () => {
        const { item_id } = this.props;
        accessStore.setEditingItem(item_id, {
            enable: false
        });
        if (await accessStore.saveItem(item_id)) {
            accessStore.fetchList();
            this.props.handleClose();
        }
    };

    render() {
        const { handleClose, item_id, handleChange } = this.props;
        const { editingItem, loadingItem, errors } = accessStore.getItem(item_id);
        const { title } = editingItem;

        const { tabId } = this.state;

        const modalButtons: ModalBtnAction[] = [
            { onClick: handleClose, label: 'Отмена', disabled: loadingItem, variant: 'text' },
            item_id > CREATING_ITEM_ID
                ? {
                      onClick: this.handleDelete,
                      label: 'Удалить',
                      disabled: loadingItem,
                      variant: 'text',
                      color: 'secondary',
                      startIcon: <DeleteIcon />
                  }
                : null,
            {
                onClick: this.handleCreateAccess,
                label: item_id === CREATING_ITEM_ID ? 'Создать' : 'Сохранить',
                disabled: loadingItem,
                color: 'primary',
                startIcon: <SaveIcon />,
                variant: 'outlined'
            }
        ];

        return (
            <Modal
                fullScreen={commonStore.isMobile}
                onClose={handleClose}
                maxWidth="md"
                header="Профиль Доступа"
                actions={modalButtons.filter(item => item !== null)}
                loading={loadingItem}
            >
                <ModalContent>
                    <ListError errors={errors} />

                    {editingItem && (
                        <form ref={$el => (this.$form = $el)}>
                            <Grid columns={2}>
                                <Grid.Column>
                                    <b>Должность</b>
                                </Grid.Column>
                                <Grid.Column>
                                    <TextInput
                                        variant="classic"
                                        size="small"
                                        name="title"
                                        type="text"
                                        label="Должность"
                                        required
                                        pattern=".{2,}"
                                        title="Введите название должности"
                                        value={String(title || '')}
                                        onChange={handleChange}
                                        fullWidth
                                    />
                                </Grid.Column>
                            </Grid>

                            <Tabs
                                value={tabId}
                                indicatorColor="secondary"
                                textColor="secondary"
                                onChange={(event, tabId) => this.setState({ tabId })}
                                variant="fullWidth"
                            >
                                <Tab label="Модули доступа" value={0} />
                                <Tab label="Экспорт" value={1} />
                            </Tabs>

                            <div style={{ padding: '1rem 0', borderTop: '1px solid rgba(0, 0, 0, 0.12)' }}>
                                {tabId === 0 && <AccessTableEditing item_id={item_id} />}
                                {tabId === 1 && <AccessExportEditing item_id={item_id} />}
                            </div>
                        </form>
                    )}
                </ModalContent>
            </Modal>
        );
    }
}

export default HandlerEditingBlockWrapper(accessStore, AccessModal);
