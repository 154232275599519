import React from 'react';
import Time from '~/components/Base/Time';
import TwoAvatars from '~/components/Base/ui/TwoAvatars';

import { AvitoChatTitle, ChatListType } from '~/types/chat.types';

const AvitoCell = ({
    chat,
    active,
    onSelectUser
}: {
    chat: AvitoChatTitle;
    active: boolean;
    onSelectUser: (chatType: ChatListType, avito_chat_id: number, estate_id: number) => void;
}) => {
    const { avitoUser, estate, lastMessage, countUnreadMessages, avito_chat_id } = chat;

    return (
        <div
            className={`crm-Chat__user_item  ${active ? 'active' : ''}`}
            onClick={onSelectUser.bind(this, 'avito', avito_chat_id, estate.estate_id)}
        >
            <div className="crm-Chat__user_row">
                <div className="crm-Chat__item_avatar">
                    <div className="crm-Chat__item_avatar_mode">
                        <TwoAvatars
                            mainAvatarUrl={estate.avatarUrl}
                            mainTitle={estate.address}
                            secondAvatarUrl={avitoUser.avatarUrl}
                            secondTitle={avitoUser.name}
                        />
                    </div>
                </div>

                <div className="crm-Chat__info">
                    <span className="crm-Chat__info_name" style={{ fontSize: '0.925em' }}>
                        {estate.address?.substring(0, 25) + '...'}
                    </span>
                    <div className="crm-Chat__info_des">{lastMessage.message?.substring(0, 25) + '...'}</div>
                    <div className="crm-Chat__info_time">
                        <Time time={lastMessage.createTime} />
                    </div>
                </div>

                <div className="crm-Chat__unread">
                    {countUnreadMessages > 0 && <div className="crm-Chat__unread_badge">{countUnreadMessages}</div>}
                </div>
            </div>
        </div>
    );
};

export default React.memo(AvitoCell);
