import React, { Fragment } from 'react';
import { Popup, Icon, Button } from 'semantic-ui-react';
import isEqual from '../../../../common/isEqual';

import { ESTATE_PROPERTY_FLATS_ARR } from '~/types/estate.types';

import estateStore from '~/stores/estateStore';
import { AWAIT_POPUP_TIME_CLOSE_MS, AWAIT_POPUP_TIME_MS } from '../../../Items/Common/ItemPreview';
import { Newbuilding } from '~/types/newbuildings.types';

type EstateTableNewbuildingProps = {
    newbuilding?: Newbuilding;
    simpleMode?: boolean;
};

const setFilterNewbuildningName = (name: string) => {
    estateStore.changeFilter('propertyTypes', ESTATE_PROPERTY_FLATS_ARR);
    estateStore.changeFilter('newbuildingContains', name);
};

const EstateTableNewbuilding = ({ newbuilding, simpleMode }: EstateTableNewbuildingProps) => {
    if (!newbuilding) {
        return null;
    }

    return (
        <span style={{ paddingRight: '15px' }}>
            <Icon name="building" color="grey" />
            ЖК «
            {!simpleMode && (
                <Popup
                    mouseEnterDelay={AWAIT_POPUP_TIME_MS}
                    mouseLeaveDelay={AWAIT_POPUP_TIME_CLOSE_MS}
                    hoverable
                    on="hover"
                    trigger={<b style={{ cursor: 'context-menu', fontWeight: 400 }}>{newbuilding.name}</b>}
                    size="mini"
                >
                    <Button
                        basic
                        size="mini"
                        icon="search"
                        color="red"
                        onClick={setFilterNewbuildningName.bind(setFilterNewbuildningName, newbuilding.name)}
                        content={`Добавить ЖК в фильтр`}
                    />
                </Popup>
            )}
            {simpleMode && <b style={{ fontWeight: 400 }}>{newbuilding.name}</b>}»
        </span>
    );
};

export default React.memo(EstateTableNewbuilding, isEqual);
