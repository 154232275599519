import React, { ChangeEvent, Fragment, useState } from 'react';
import { Card, Statistic, Icon, Button } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import statisticStore from '~/stores/statisticStore';
import { matchColor } from '~/components/Home/HomeStatistics';
import { KpiStatistics } from '~/types/kpi.types';
import Modal from '~ui/Modal';
import Table, { TableCell, TableRow, TableHead, TableBody } from '~ui/Table';
import statisticsL10n from '~/L10n/statistics.L10n';
import { StatisticsCell } from '~/components/PultToolbar/Kpi/TableKpi';

const StatisticsModal = ({ title, statistics: { kpi, pointsValue } }: { title: string; statistics: KpiStatistics }) => {
    const [showModal, setShowModal] = useState(false);
    const toggleModal = () => {
        setShowModal(!showModal);
    };

    return (
        <>
            <Icon link size="small" circular color="blue" name="question" onClick={toggleModal} />
            {showModal && (
                <Modal onClose={toggleModal} header={`Информация по kpi (${title})`}>
                    <Table size="medium">
                        <TableHead>
                            <TableRow>
                                <TableCell>Показатель</TableCell>
                                <TableCell>Кол-во</TableCell>
                                <TableCell>Вес</TableCell>
                                <TableCell>Итог</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {pointsValue.map(({ point_id, value, pointType, redValue, greenValue, weight }) => (
                                <TableRow key={point_id}>
                                    <TableCell>{statisticsL10n.KPI_POINT_TYPE_FIND(pointType)}</TableCell>
                                    <StatisticsCell value={value} greenValue={greenValue} redValue={redValue} />
                                    <TableCell>{weight.toFixed(4)}</TableCell>
                                    <TableCell>{(value * weight).toLocaleString()}</TableCell>
                                </TableRow>
                            ))}
                            <TableRow>
                                <TableCell colSpan={3}>
                                    Итого коэффициент <b>{title}</b>
                                </TableCell>
                                <TableCell>{kpi}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Modal>
            )}
        </>
    );
};

const UserKpisStat = ({ user_id }: { user_id: number }) => {
    const { loadingItem, item } = statisticStore.getItem(user_id);
    if (!item || loadingItem) {
        return null;
    }

    const { kpis } = item;
    if (kpis.length === 0) {
        return null;
    }

    return (
        <Card.Content>
            <Statistic.Group horizontal size="tiny">
                {kpis.map(({ kpi: { kpi_id, title, greenValue, redValue }, statistics }) => (
                    <Statistic key={kpi_id} color={matchColor(statistics.kpi, { green: greenValue, red: redValue })}>
                        <Statistic.Value>
                            <Icon name="dashboard" />
                            {statistics.kpi}
                        </Statistic.Value>
                        <Statistic.Label>
                            {title} <StatisticsModal title={title} statistics={statistics} />
                        </Statistic.Label>
                    </Statistic>
                ))}
            </Statistic.Group>
        </Card.Content>
    );
};

export default observer(UserKpisStat);
