import React from 'react';
import { observer } from 'mobx-react';

import Typography from '@material-ui/core/Typography';

import { DOMCLICK_API_KEY } from '~/types/settings.types';
import DomclickApiSettingsField from '~/components/Ads/BaseApiSettings/Fields/DomclickApiSettingsField';
import ApiSettingsWrapper from '~/components/Ads/BaseApiSettings/ApiSettingsWrapper';

const DomclickApiSettings = () => {
    return (
        <ApiSettingsWrapper
            apiKey={DOMCLICK_API_KEY}
            FieldComponent={DomclickApiSettingsField}
            defaultSettingsValue={{ token: '', reportUrl: '' }}
        >
            <Typography>
                Что нужно сделать, чтобы получить доступ к обратному фиду?
                <ul>
                    <li>
                        1.&nbsp;
                        <a href="https://helpdesk.domclick.ru/create" target="_blank" rel="noreferrer">
                            Создать обращение
                        </a>
                        &nbsp; в службу поддержки от лица руководителя компании
                    </li>
                    <li>2. Получить ссылку и токен (ключ доступа) в ответном сообщении от службы поддержки</li>
                </ul>
            </Typography>
            <a
                href="https://help.domclick.ru/%D0%B0%D0%B3%D0%B5%D0%BD%D1%82%D1%81%D1%82%D0%B2%D0%B0%D0%BC-%D0%B7%D0%B0%D1%81%D1%82%D1%80%D0%BE%D0%B9%D1%89%D0%B8%D0%BA%D0%B0%D0%BC-%D0%B8-%D0%BE%D1%86%D0%B5%D0%BD%D0%BE%D1%87%D0%BD%D1%8B%D0%BC-%D0%BA%D0%BE%D0%BC%D0%BF%D0%B0%D0%BD%D0%B8%D1%8F%D0%BC/%D1%80%D0%B0%D0%B7%D0%BC%D0%B5%D1%89%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE%D0%B1%D1%8A%D1%8F%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D0%B9-%D0%BD%D0%B0-%D0%B4%D0%BE%D0%BC%D0%BA%D0%BB%D0%B8%D0%BA/%D0%BA%D0%B0%D0%BA-%D0%BD%D0%B0%D1%81%D1%82%D1%80%D0%BE%D0%B8%D1%82%D1%8C-%D0%BE%D0%B1%D1%80%D0%B0%D1%82%D0%BD%D1%8B%D0%B9-%D1%84%D0%B8%D0%B4/"
                target="_blank"
                rel="noreferrer"
            >
                Источник
            </a>
        </ApiSettingsWrapper>
    );
};

export default observer(DomclickApiSettings);
