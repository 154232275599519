import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Table, Label, Icon, Button } from 'semantic-ui-react';

import Time from '../../Base/Time';
import DealLink, { DealLinkState } from './DealLink';
import { DealEstateLink } from '../../Items/Deal/ShowBlocks/DealPropertyShowing';

import UsersLinkList from '../Users/UsersLinkList';
import { DEAL_STATUS_OPEN, DEAL_TYPE_EXCLUSIVE_ID, DealTableBodyType } from '~/types/deals.types';
import estateL10n from '../../../L10n/estate.L10n';
import DealProgress from './DealProgress';
import SimpleTableCell from '../Common/SimpleTableCell';
import { TAB_DOCUMENTS_ID } from '../../Items/TabMenuWrapper';

type DealTableBodyProps = {
    dealsList: DealTableBodyType[];
    param: string;
    isStatusCellAvailable: boolean;
};

const DealTableBody = ({ dealsList, param, isStatusCellAvailable }: DealTableBodyProps) => {
    const currentTime = Date.now() / 1000;

    return (
        <Table.Body>
            {dealsList.length > 0 &&
                dealsList.map(
                    ({
                        deal_id,
                        estateOuterUrl,
                        estateType,
                        status,
                        usersFees,
                        type,
                        price,
                        currency,
                        createTime,
                        estate,
                        isExclusive,
                        releaseTime,
                        enable,
                        deposit,
                        depositTime,
                        documentsCount,

                        exclusiveDeadTime,
                        depositDeadTime
                    }) => {
                        const depositTimeStr = depositTime ? new Date(depositTime * 1000).toLocaleDateString() : '';

                        return (
                            <Table.Row key={deal_id}>
                                <Table.Cell>
                                    <b>
                                        <DealLink deal={{ deal_id, type, enable }} />
                                    </b>
                                    <div>
                                        Создание:&nbsp;
                                        <b>
                                            <Time time={createTime} />
                                        </b>
                                    </div>
                                </Table.Cell>
                                <SimpleTableCell textAlign="right">
                                    <div>
                                        {price.toLocaleString()} {estateL10n.CURRENCY_TYPES_FIND(currency)}
                                    </div>
                                    <div>
                                        {deposit > 0 && (
                                            <Label
                                                horizontal
                                                color="blue"
                                                size="tiny"
                                                title={`${deposit}${depositTimeStr ? ` внесен ${depositTimeStr}` : ''}`}
                                            >
                                                аванс
                                            </Label>
                                        )}
                                        {depositDeadTime && type !== DEAL_TYPE_EXCLUSIVE_ID && (
                                            <div>до {new Date(depositDeadTime * 1000).toLocaleDateString()}</div>
                                        )}
                                    </div>
                                </SimpleTableCell>
                                {isStatusCellAvailable && (
                                    <SimpleTableCell>
                                        {Number(status) > 0 && <DealProgress type={type} status={status} size="tiny" />}
                                    </SimpleTableCell>
                                )}
                                <SimpleTableCell>
                                    {type !== DEAL_TYPE_EXCLUSIVE_ID && (
                                        <Fragment>
                                            {releaseTime && <div>{new Date(releaseTime * 1000).toLocaleDateString()}</div>}
                                            {releaseTime && currentTime > releaseTime && status === DEAL_STATUS_OPEN && (
                                                <div>
                                                    <Label horizontal color="red" size="tiny">
                                                        просрочена
                                                    </Label>
                                                </div>
                                            )}
                                        </Fragment>
                                    )}

                                    {type === DEAL_TYPE_EXCLUSIVE_ID && (
                                        <Fragment>
                                            {exclusiveDeadTime && <div>{new Date(exclusiveDeadTime * 1000).toLocaleDateString()}</div>}
                                            {exclusiveDeadTime && currentTime > exclusiveDeadTime && (
                                                <div>
                                                    <Label horizontal color="red" size="tiny">
                                                        просрочен
                                                    </Label>
                                                </div>
                                            )}
                                        </Fragment>
                                    )}
                                </SimpleTableCell>
                                <Table.Cell>
                                    {(estate || estateOuterUrl) && (
                                        <DealEstateLink estate={estate} estateType={estateType} estateOuterUrl={String(estateOuterUrl)} />
                                    )}
                                    {isExclusive && type !== DEAL_TYPE_EXCLUSIVE_ID && (
                                        <div>
                                            <Label horizontal color="green" size="tiny">
                                                Эксклюзив
                                            </Label>
                                        </div>
                                    )}
                                </Table.Cell>
                                <SimpleTableCell>
                                    {documentsCount > 0 && (
                                        <Button as={Link} basic size="small" to={DealLinkState(deal_id, param, TAB_DOCUMENTS_ID)}>
                                            <Icon link name="file alternate outline" title="Количество документов">
                                                &nbsp;{documentsCount}
                                            </Icon>
                                        </Button>
                                    )}
                                </SimpleTableCell>
                                <Table.Cell>
                                    <UsersLinkList
                                        usersList={usersFees.reduce((users, { user }) => (user ? [...users, user] : users), [])}
                                    />
                                </Table.Cell>
                            </Table.Row>
                        );
                    }
                )}
        </Table.Body>
    );
};

export default DealTableBody;
