import React from 'react';
import { observer } from 'mobx-react';

import Grid from '~ui/Grid';
import TextInput from '~ui/TextInput';
import Button from '~ui/Button';
import settingStore from '~/stores/settingStore';
import { DOMCLICK_EXPORT_BASE_ID } from '~/types/exports.types';
import BaseApiEditingWrapper, { BaseApiEditingWrappedProps } from '../BaseApiEditingWrapper';
import { DOMCLICK_API_KEY, DomclickApiConfig } from '~/types/settings.types';

const DomclickApiSettingsField = ({ checkTest, handleEditValue, answer, loadingTest, apiIndex }: BaseApiEditingWrappedProps) => {
    const { editingItem, loadingItem } = settingStore.getItem(DOMCLICK_API_KEY);

    let token = '';
    let reportUrl = '';
    if (editingItem?.value) {
        const settings: DomclickApiConfig = JSON.parse(editingItem?.value);
        token = settings[apiIndex].token;
        reportUrl = settings[apiIndex].reportUrl;
    }

    return (
        <Grid justify="flex-end">
            <Grid.Column width={6}>
                <TextInput
                    disabled={loadingTest || Boolean(answer)}
                    label="Ссылка"
                    loading={loadingItem}
                    value={reportUrl}
                    name="reportUrl"
                    onChange={handleEditValue}
                    mask="https://my.domclick.ru/api/v1/company/000000/report"
                />
            </Grid.Column>
            <Grid.Column width={6}>
                <TextInput
                    disabled={loadingTest || Boolean(answer)}
                    label="Токен (ключ доступа)"
                    loading={loadingItem}
                    value={token}
                    name="token"
                    onChange={handleEditValue}
                />
            </Grid.Column>
            <Grid.Column width={3}>
                <Button
                    color={!answer ? 'secondary' : 'primary'}
                    loading={loadingItem || loadingTest}
                    disabled={Boolean(answer)}
                    onClick={checkTest}
                >
                    {!answer ? 'Протестировать' : 'Ключ сохранен'}
                </Button>
            </Grid.Column>
        </Grid>
    );
};

export default BaseApiEditingWrapper(observer(DomclickApiSettingsField), DOMCLICK_EXPORT_BASE_ID, DOMCLICK_API_KEY);
