import React from 'react';
import cs from 'classnames';
import { Link } from 'react-router-dom';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';

import Time from '../Base/Time';

import UserAvatar from '../Base/ui/UserAvatar';

import Typography from '@material-ui/core/Typography';
import MaterialLink from '@material-ui/core/Link';
import DoneIcon from '@material-ui/icons/Done';
import DoneAllIcon from '@material-ui/icons/DoneAll';

import urlify from '../../common/urlify';
import { nl2br } from '~/api/commentsApi';

const SmallDoneIcon = withStyles((theme: Theme) =>
    createStyles({
        root: {
            fontSize: '1em',
            marginLeft: '0.5em'
        }
    })
)(DoneIcon);

const SmallDoneAllIcon = withStyles((theme: Theme) =>
    createStyles({
        root: {
            fontSize: '1em',
            marginLeft: '0.5em'
        }
    })
)(DoneAllIcon);

const Message = ({
    message,
    avatar,
    reverse,
    time,
    name,
    hrefTo,
    deliveryTime,
    readTime,
    urlifyDisable
}: {
    message: string;
    reverse?: boolean;
    avatar: string;
    time: number;
    name: string;

    hrefTo?: string | object;
    deliveryTime: number | null;
    readTime: number | null;

    urlifyDisable?: boolean;
}) => (
    <div className={cs({ 'crm-Chat__message': true, 'crm-Chat__message_reverse': reverse })}>
        <UserAvatar name={name} src={avatar} size={40} />
        <div className="crm-Chat__message_card">
            {hrefTo && (
                <MaterialLink
                    variant="subtitle2"
                    component={typeof hrefTo === 'object' ? Link : undefined}
                    to={typeof hrefTo === 'object' ? hrefTo : undefined}
                    href={typeof hrefTo === 'string' ? hrefTo : undefined}
                    target={typeof hrefTo === 'string' ? '_blank' : null}
                    color="secondary"
                    title="Посмотреть профиль на сайте авито"
                >
                    {name}
                </MaterialLink>
            )}

            <Typography variant="body1" dangerouslySetInnerHTML={{ __html: urlifyDisable ? nl2br(message) : urlify(nl2br(message)) }} />

            <Typography variant="caption" className={cs({ 'crm-Chat__message_reverseMessage': reverse })}>
                <Time time={time} />
                {reverse && !deliveryTime && !readTime && <SmallDoneIcon />}
                {(deliveryTime || readTime) && <SmallDoneAllIcon color={readTime ? 'primary' : 'inherit'} />}
            </Typography>
        </div>
    </div>
);

export default React.memo(Message);
