import React, { ChangeEvent, useState, KeyboardEvent, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Icon } from 'semantic-ui-react';
import { observer } from 'mobx-react';
import Modal, { ModalContent } from '~ui/Modal';
import Grid from '~ui/Grid';

import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import Button from '~ui/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';

import contactStore from '~/stores/contactStore';

import { HandlerEditingWrappedProps } from '../../HandlerEditingBlockWrapper';
import HandlerEditingBlockWrapper from '../../HandlerEditingBlockWrapper';
import tagStore from '~/stores/tagStore';
import TagContact from '~/components/Settings/Tags/TagContact';
import commonStore from '~/stores/commonStore';
import LoaderAwait from '~/components/Base/LoaderAwait';

import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import SearchIcon from '@material-ui/icons/Search';
import { Tag } from '~/types/contacts.types';

const filterTags = (search: string) => (tag: Tag): boolean => {
    return tag.title.toUpperCase().includes(search.toUpperCase());
};

const tagsModalStyle = makeStyles(() => ({
    search: {
        minHeight: '5rem',
        maxHeight: '5rem'
    }
}));

const TagsModal = observer(({ onClose, item_id }: { onClose: () => void; item_id: number }) => {
    const classes = tagsModalStyle();

    const { allTags, loadingFetchAllTags } = tagStore;
    const { tags_ids } = contactStore.getItem(item_id).editingItem;

    const handleToggleTagId = (tag_id: number) => {
        if (!tags_ids.includes(tag_id)) {
            contactStore.addTag(item_id, tag_id);
        } else {
            contactStore.removeTag(item_id, tag_id);
        }
    };

    const [search, setSearch] = useState('');
    const changeSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setFocusedTag(0);
        setSearch(event.target.value);
    };

    const [focusedTag, setFocusedTag] = useState(0);

    const filteredTags = allTags.filter(filterTags(search));

    const handleKeyPress = (event: KeyboardEvent) => {
        const { key } = event;
        if (!['ArrowUp', 'ArrowDown', 'Enter'].includes(key)) {
            return;
        }

        event.preventDefault();

        if (key === 'ArrowUp') {
            setFocusedTag(focusedTag > 0 ? focusedTag - 1 : 0);
        } else if (key === 'ArrowDown') {
            setFocusedTag(focusedTag < filteredTags.length - 1 ? focusedTag + 1 : filteredTags.length);
        } else if (key === 'Enter') {
            if (filteredTags[focusedTag]) {
                handleToggleTagId(filteredTags[focusedTag].tag_id);
                onClose();
            }
        }
    };

    return (
        <Modal onClose={onClose} fullScreen={commonStore.isMobile} fullWidth maxWidth="xs" header="Список тэгов">
            <ModalContent className={classes.search}>
                <FormControl fullWidth>
                    <Input
                        autoFocus
                        value={search}
                        onChange={changeSearch}
                        startAdornment={
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        }
                        onKeyDown={handleKeyPress}
                    />
                </FormControl>
            </ModalContent>
            <ModalContent>
                <List dense>
                    {filteredTags.map(({ tag_id, title, color }, index) => (
                        <ListItem
                            button
                            key={tag_id}
                            onClick={() => handleToggleTagId(tag_id)}
                            selected={tags_ids.includes(tag_id) || index === focusedTag}
                        >
                            <ListItemIcon>
                                <Checkbox size="small" edge="start" checked={tags_ids.includes(tag_id)} tabIndex={-1} disableRipple />
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <>
                                        <Icon name="tag" color={color} /> {title}
                                    </>
                                }
                            />
                        </ListItem>
                    ))}
                </List>
                <LoaderAwait active={loadingFetchAllTags} linear />
            </ModalContent>
        </Modal>
    );
});

type TagsBlockEditingProps = HandlerEditingWrappedProps & {
    tags_ids?: number[];
};

const TagsBlockEditing = observer((props: TagsBlockEditingProps) => {
    const { item_id, editing } = props;

    useMemo(() => {
        const item = {
            contact_id: item_id,
            tags_ids: props.tags_ids || []
        };
        contactStore.setEditingItem(item_id, item);
    }, []);

    const handleRemoveTag = (tag_id: number) => {
        contactStore.removeTag(item_id, tag_id);
    };

    const [showModal, setShowModal] = useState(false);
    const handleToggleTagsModal = () => {
        setShowModal(!showModal);
    };

    const { tags_ids } = contactStore.getItem(item_id).editingItem;

    const MobileTrigger = (
        <Button variant="outlined" color="default" startIcon={<LocalOfferIcon fontSize="small" />} onClick={handleToggleTagsModal}>
            Тэги
        </Button>
    );

    return (
        <>
            <Grid columns={editing ? 1 : 2} stackable alignItems="flex-start">
                <Grid.Column>{MobileTrigger}</Grid.Column>
                <Grid.Column>
                    <List dense disablePadding>
                        {tags_ids?.map(tag_id => (
                            <ListItem key={tag_id} disableGutters>
                                <ListItemText
                                    primary={
                                        <div className="crm-Estate__fieldNowrap">
                                            <TagContact tag_id={tag_id} />
                                            <Icon link name="close" title="Убрать" onClick={handleRemoveTag.bind(this, tag_id)} />
                                        </div>
                                    }
                                />
                            </ListItem>
                        ))}
                    </List>
                </Grid.Column>
            </Grid>

            {showModal && <TagsModal item_id={item_id} onClose={handleToggleTagsModal}></TagsModal>}
        </>
    );
});

export default HandlerEditingBlockWrapper(contactStore, TagsBlockEditing);
