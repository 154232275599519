import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Input, Popup, Dropdown, Checkbox } from 'semantic-ui-react';
import taskL10n from '../../../L10n/tasks.L10n';
import { matchDuration, matchDurationSeconds, matchDurationType } from './EventDatesPickers';
import CalendarEventsStorePrototype from '~/stores/prototypes/CalendarEventsStore.prototype';

type EventNotyProps = {
    item_id: number;
    notificationEnable?: boolean;
    notificationTime?: number;
    startTime: number;
    store: CalendarEventsStorePrototype<any, any>;
};

type EventNotyState = {
    notyTime: number;
    notyTimeType: number;
};

const DEFAULT_NOTY_TIME = 15;
const DEFAULT_NOTY_TIME_TYPE = 1;

@observer
class EventNoty extends Component<EventNotyProps, EventNotyState> {
    constructor(props: EventNotyProps) {
        super(props);

        const { store, notificationTime } = props;

        const callEvent = {
            notificationEnable: props.notificationEnable || false,
            notificationTime: typeof notificationTime === 'number' ? notificationTime : null
        };

        store.setEditingItem(props.item_id, callEvent);

        let notyTime = DEFAULT_NOTY_TIME;
        let notyTimeType = DEFAULT_NOTY_TIME_TYPE;

        if (notificationTime) {
            const duration = props.startTime - notificationTime;

            notyTimeType = matchDurationType(duration);
            notyTime = notificationTime ? matchDuration(duration, notyTimeType) : DEFAULT_NOTY_TIME;
        }

        this.state = {
            notyTime,
            notyTimeType
        };
    }

    componentDidUpdate(prevProps: EventNotyProps) {
        const { startTime, item_id, store } = this.props;

        if (prevProps.startTime !== startTime) {
            store.setEditingItem(item_id, {
                notificationTime: startTime - this.state.notyTime * matchDurationSeconds(this.state.notyTimeType)
            });
        }
    }

    handleChangeNotyTime = async (event: React.SyntheticEvent, { value }: { value: string }) => {
        await this.setState({ notyTime: Number(value) });
        this.setNotificationTime();
    };

    handleChangeNotyTimeType = async (event: React.SyntheticEvent, { value }: { value: number }) => {
        await this.setState({ notyTimeType: value });
        this.setNotificationTime();
    };

    setNotificationTime = () => {
        const { item_id, store } = this.props;
        const { startTime } = store.getItem(item_id).editingItem;

        store.setEditingItem(item_id, {
            notificationTime: startTime - this.state.notyTime * matchDurationSeconds(this.state.notyTimeType)
        });
    };

    handleNotificationEnable = (event: React.SyntheticEvent, { checked }: { checked: boolean }) => {
        const { item_id, store } = this.props;

        if (!checked) {
            store.setEditingItem(item_id, {
                notificationTime: null
            });
        } else {
            this.setNotificationTime();
        }

        store.setEditingItem(item_id, {
            notificationEnable: checked
        });
    };

    render() {
        const { item_id, store } = this.props;
        const { notificationEnable } = store.getItem(item_id).editingItem;

        const { notyTime, notyTimeType } = this.state;

        return (
            <Fragment>
                <Checkbox
                    onClick={this.handleNotificationEnable}
                    name="notificationEnable"
                    label="напомнить за"
                    checked={notificationEnable}
                />
                &nbsp;
                <Popup
                    trigger={
                        <button>
                            {notyTime} {taskL10n.DURATION_TYPES_FIND(notyTimeType)}
                        </button>
                    }
                    on="click"
                    hideOnScroll={false}
                    size="tiny"
                    position="bottom right"
                    wide="very"
                    className="crm-callEvents__notyPopup"
                >
                    <Input
                        placeholder="напомнить за"
                        name="priceMax"
                        value={notyTime}
                        type="number"
                        size="small"
                        min={0}
                        step={1}
                        onChange={this.handleChangeNotyTime}
                        label={
                            <Dropdown
                                value={notyTimeType}
                                onChange={this.handleChangeNotyTimeType}
                                options={taskL10n.DURATION_TYPES_DROPDOWN}
                            />
                        }
                        labelPosition="right"
                        className="crm-Estate__filter_priceMax"
                    />
                </Popup>
            </Fragment>
        );
    }
}

export default EventNoty;
