import React, { Fragment } from 'react';
import { observer } from 'mobx-react';

import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import SettingsIcon from '@material-ui/icons/Settings';

import ContainerHeader from '~ui/ContainerHeader';

import LoaderAwait from '~/components/Base/LoaderAwait';
import settingStore from '~/stores/settingStore';
import ListError from '~/components/ListError';
import { NumberInput } from '~ui/TextInput';

import Grid from '~ui/Grid';
import Checkbox, { Switch } from '~ui/Checkbox';
import InfoPopup from '~/components/Base/InfoPopup';
import { CRM_SETTINGS_KEY, CrmMainSettings } from '~/types/settings.types';
import exportingBaseStore from '~/stores/export/exportingBaseStore';
import Select from '~ui/Select';
import Button from '~ui/Button';
import SettingsEditingWrapper, { SettingsEditingWrapperProps } from '~/components/Settings/SettingsEditingWrapper';

const CrmSettings = ({ handleEditValue, handleSave, handleCheckbox }: SettingsEditingWrapperProps) => {
    const { loadingList, exportingBasesDropdown } = exportingBaseStore;
    const { editingItem, loadingItem, errors } = settingStore.getItem(CRM_SETTINGS_KEY);

    let {
        enableFakeEstate,
        enableExpiredCalls,
        lastOutgoingCallTimesWarning,
        lastOutgoingCallTimesBan,
        enableCanonicalExportBasesId,
        CANONICAL_EXPORT_BASES_ID,
        whetherNeedCallForOwnerAccess,
        enableWishBooking,
        wishBookingExpirationDays
    } = settingStore.mainConfig;

    if (editingItem?.value) {
        const settings: CrmMainSettings = JSON.parse(editingItem?.value);
        enableFakeEstate = settings.enableFakeEstate;
        enableExpiredCalls = settings.enableExpiredCalls;
        lastOutgoingCallTimesWarning = settings.lastOutgoingCallTimesWarning;
        lastOutgoingCallTimesBan = settings.lastOutgoingCallTimesBan;
        enableCanonicalExportBasesId = settings.enableCanonicalExportBasesId;
        CANONICAL_EXPORT_BASES_ID = settings.CANONICAL_EXPORT_BASES_ID;
        whetherNeedCallForOwnerAccess = settings.whetherNeedCallForOwnerAccess;
        enableWishBooking = settings.enableWishBooking;
        wishBookingExpirationDays = settings.wishBookingExpirationDays;
    }

    return (
        <Fragment>
            <ContainerHeader title="Настройка CRM" enableCreating={false} icon={<SettingsIcon fontSize="large" />} />

            <ListError errors={errors} />

            <Container maxWidth="md" style={{ position: 'relative' }}>
                {loadingItem && <LoaderAwait dimmer active immediately />}

                <Paper>
                    <Box m={3}>
                        <Grid stackable justify="center">
                            <Grid.Column width={6}>
                                <Switch
                                    label="Просроченные звонки"
                                    checked={enableExpiredCalls}
                                    name="enableExpiredCalls"
                                    handleChange={handleCheckbox}
                                />
                                <InfoPopup
                                    content="Если включены просроченные звонки и агент не связывался с собственником больше Красного времени, экспорт объекта будет не доступен"
                                    size="small"
                                />
                            </Grid.Column>
                            <Grid.Column width={3}>
                                <NumberInput
                                    disabled={!enableExpiredCalls}
                                    value={lastOutgoingCallTimesWarning}
                                    name="lastOutgoingCallTimesWarning"
                                    onChange={handleEditValue}
                                    variant="filled"
                                    label="Предупреждение (желтые)"
                                    fullWidth
                                    extraLabel="дней"
                                    min={1}
                                />
                            </Grid.Column>
                            <Grid.Column width={3}>
                                <NumberInput
                                    disabled={!enableExpiredCalls}
                                    value={lastOutgoingCallTimesBan}
                                    variant="filled"
                                    name="lastOutgoingCallTimesBan"
                                    onChange={handleEditValue}
                                    label="Нарушение (красные)"
                                    fullWidth
                                    extraLabel="дней"
                                    min={1}
                                />
                            </Grid.Column>

                            <Grid.Column width={6}>
                                <Switch
                                    label="Обязательные площадки экспорта"
                                    checked={enableCanonicalExportBasesId}
                                    name="enableCanonicalExportBasesId"
                                    handleChange={handleCheckbox}
                                />
                                <InfoPopup
                                    content="Можно установить площадки для экспорта, одну из которых надо выбрать обязательно. Это делается, чтобы агенты не размещали объекты только в бесплатных, а работали с собственником дальше и договаривались о полноценной рекламе."
                                    size="small"
                                />
                            </Grid.Column>
                            <Grid.Column width={6}>
                                <Select
                                    options={exportingBasesDropdown}
                                    name="CANONICAL_EXPORT_BASES_ID"
                                    value={CANONICAL_EXPORT_BASES_ID}
                                    onChange={handleEditValue}
                                    multiple
                                    label="Базы"
                                    loading={loadingList}
                                    disabled={!enableCanonicalExportBasesId}
                                    fullWidth
                                />
                            </Grid.Column>

                            <Grid.Column width={12}>
                                <Switch
                                    checked={whetherNeedCallForOwnerAccess}
                                    name="whetherNeedCallForOwnerAccess"
                                    label="Доступ к Собственнику после звонка"
                                    handleChange={handleCheckbox}
                                />
                                <InfoPopup
                                    content="Возможность взять собственника в работу только если был звонок с номера агента на номер Собственника, указанный в карточке (Необходима Телефония)"
                                    size="small"
                                />
                            </Grid.Column>

                            <Grid.Column width={12}>
                                <Checkbox
                                    checked={enableFakeEstate}
                                    name="enableFakeEstate"
                                    label="Фейковые объекты"
                                    handleChange={handleCheckbox}
                                />
                                <InfoPopup content='Можно создавать "Фейковые объекты" в crm и отправлять их на экспорт' size="small" />
                            </Grid.Column>

                            <Grid.Column width={6}>
                                <Switch
                                    checked={enableWishBooking}
                                    name="enableWishBooking"
                                    label="Бронь покупателей"
                                    handleChange={handleCheckbox}
                                />
                                <InfoPopup content='Можно брать "Покупателей" в работу, и скрывать их из общего доступа' size="small" />
                            </Grid.Column>
                            <Grid.Column width={6}>
                                <NumberInput
                                    disabled={!enableWishBooking}
                                    value={wishBookingExpirationDays}
                                    variant="filled"
                                    name="wishBookingExpirationDays"
                                    onChange={handleEditValue}
                                    label={`На сколько дней бронируется ${!wishBookingExpirationDays ? '(Бессрочно)' : ''}`}
                                    fullWidth
                                    extraLabel="дней"
                                    min={0}
                                />
                            </Grid.Column>

                            <Grid.Column width={3}>
                                <Button fullWidth primary variant="outlined" onClick={handleSave}>
                                    Сохранить
                                </Button>
                            </Grid.Column>
                        </Grid>
                    </Box>
                </Paper>
            </Container>
        </Fragment>
    );
};

export default SettingsEditingWrapper(observer(CrmSettings), CRM_SETTINGS_KEY);
