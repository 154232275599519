import React, { Component, Fragment } from 'react';
import { Card, Icon, Image } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import ShadowFragment from '~/components/Base/ShadowFragment';
import UserInfoBoxEditing from '../EditBlocks/UserInfoEditing';
import EditingBlockWrapper, { EditingWrappedProps } from '../../EditingBlockWrapperNew';
import userStore from '~/stores/userStore';
import accessStore from '~/stores/accessStore';
import LabelArchive from '../../Common/LabelArchive';
import config from '~/config/ui.config';
import authStore from '~/stores/authStore';
import LoaderAwait from '~/components/Base/LoaderAwait';
import UserAvatar from '~ui/UserAvatar';
import deepCopy from '~/common/deepCopy';

@observer
class UserInfoShow extends Component<EditingWrappedProps> {
    constructor(props) {
        super(props);
        accessStore.fetchList();
    }

    render() {
        const { item_id, editing } = this.props;
        const { item, loadingItem } = userStore.getItem(item_id);
        const { loadingList } = accessStore;

        const { access_id, enable, dismissalTime, birthday, hiddenDescription, group } = item || {};
        const accessTitle = !loadingList && !loadingItem && access_id > 0 ? accessStore.findById(access_id).title : '';
        const [lastName, firstName, middleName] = item ? [item.lastName, item.firstName, item.middleName] : ['', '', ''];

        let inStateFrom;

        if (item) {
            const firstDate = new Date(item.recruitmentTime * 1000);
            inStateFrom = firstDate.toLocaleDateString();
        }

        const photoUrl = !item || loadingItem ? config.defaultAvatarUrl : item.avatarUrl;

        return (
            <Card fluid color="blue">
                <Card.Content>
                    {!editing && (
                        <Fragment>
                            <Card.Header>
                                <div className="crm-User__infoBlock_header">
                                    {!loadingItem && !enable && <LabelArchive ribbon />}
                                    <div className="crm-Main__hiddenDesktop crm-User__infoBlock_hiddenImage crm-Flex__center">
                                        <UserAvatar src={photoUrl} firstName={firstName} lastName={lastName} />
                                    </div>
                                    <div>
                                        <ShadowFragment print={!loadingItem && `${firstName} ${middleName || ''} ${lastName}`} />
                                    </div>
                                </div>
                            </Card.Header>
                            <Card.Meta>
                                <span className="date">
                                    В штате с <ShadowFragment print={!loadingItem && inStateFrom} />
                                    {!enable && ` по ${dismissalTime ? new Date(dismissalTime * 1000).toLocaleDateString() : '---'}`}
                                </span>
                            </Card.Meta>
                            <Card.Description>
                                День Рождения &nbsp;
                                <b>
                                    <ShadowFragment shadow={loadingItem} print={birthday && new Date(birthday).toLocaleDateString()} />
                                </b>
                            </Card.Description>
                            {authStore.canEdit(userStore.moduleName, item_id) && (
                                <Card.Meta>
                                    <div>{hiddenDescription}</div>
                                </Card.Meta>
                            )}
                        </Fragment>
                    )}
                    {editing && !loadingItem && (
                        <UserInfoBoxEditing editing group_id={group ? group.group_id : 0} item_id={item_id} {...item} />
                    )}
                </Card.Content>
                {!editing && (
                    <Card.Content extra>
                        {group && (
                            <Fragment>
                                <Icon name={group['parent_id'] ? 'users' : 'building'} />
                                {group.name}
                            </Fragment>
                        )}
                        {!group && 'Офис или Отдел не задан'}
                        <br />
                        <ShadowFragment print={`${item ? item.appointment : ''} (Доступ: ${accessTitle})`} />
                    </Card.Content>
                )}

                <LoaderAwait active={loadingItem} dimmer size="large" />
            </Card>
        );
    }
}

export default EditingBlockWrapper(userStore, UserInfoShow);
