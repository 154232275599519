import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Segment, Container, Header } from 'semantic-ui-react';
import statisticStore, {
    STAT_MODE_CHART_DAY,
    STAT_MODE_CHART_MONTH,
    STAT_MODE_CHART_WEEK,
    STAT_MODE_KPI,
    STAT_MODE_TABLE,
    StatModeType
} from '~/stores/statisticStore';
import commonStore from '~/stores/commonStore';
import './statistics.less';

import TableStatistics from './TableStatistics';
import ChartStatistics from './ChartStatistics';
import authStore from '~/stores/authStore';
import { ACCESS_COMPANY, ACCESS_READ, ACCESS_GROUP } from '~/types/access.types';
import ButtonGroup from '../Base/ButtonGroup';
import { CALENDAR_VIEW_TYPE_DAY, CALENDAR_VIEW_TYPE_MONTH, CALENDAR_VIEW_TYPE_WEEK } from '~/types/calendar.types';
import UsersAndGroupsDropdown from '../Lists/Common/UsersAndGroupsDropdown';

const StatisticsModal = () => {
    const handleChangeMode = (event: React.SyntheticEvent, { value }: { value: StatModeType }) => {
        statisticStore.setStatMode(value);
    };

    const { statMode } = statisticStore;

    return (
        <Container className="crm-Home">
            <Header>
                Сводная таблица показателей&nbsp;
                {[ACCESS_COMPANY, ACCESS_GROUP].includes(authStore.getPermission(statisticStore.moduleName, ACCESS_READ)) && (
                    <ButtonGroup
                        buttonSet={[
                            [STAT_MODE_TABLE, 'Таблица'],
                            [STAT_MODE_CHART_DAY, 'График (день)'],
                            [STAT_MODE_CHART_WEEK, 'График (неделя)'],
                            [STAT_MODE_CHART_MONTH, 'График (месяц)']
                        ]}
                        name="statMode"
                        value={statMode}
                        handleChange={handleChangeMode}
                    />
                )}
            </Header>

            {!commonStore.isMobile && (
                <div className="crm-Statistics__modal_groupDropdown">
                    <UsersAndGroupsDropdown store={statisticStore} disableUsers={[STAT_MODE_TABLE, STAT_MODE_KPI].includes(statMode)} />
                </div>
            )}
            <Segment>
                {statMode === STAT_MODE_TABLE && <TableStatistics />}
                {statMode === STAT_MODE_CHART_DAY && <ChartStatistics period={CALENDAR_VIEW_TYPE_DAY} />}
                {statMode === STAT_MODE_CHART_WEEK && <ChartStatistics period={CALENDAR_VIEW_TYPE_WEEK} />}
                {statMode === STAT_MODE_CHART_MONTH && <ChartStatistics period={CALENDAR_VIEW_TYPE_MONTH} />}
            </Segment>
        </Container>
    );
};

export default observer(StatisticsModal);
