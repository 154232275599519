import React, { Component, Fragment } from 'react';
import { Input, Form, Icon, Popup, List } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import estateStore from '~/stores/estateStore';
import HandlerEditingBlockWrapper from '../../HandlerEditingBlockWrapper';
import { HandlerEditingWrappedProps } from '../../HandlerEditingBlockWrapper';
import {
    ESTATE_PROPERTY_HOUSES,
    ESTATE_PROPERTY_TYPE_FLAT,
    ESTATE_PROPERTY_TYPE_NEWBUILDING,
    ESTATE_PROPERTY_TYPE_NEWBUILDING_CESSION,
    ESTATE_PROPERTY_TYPE_PART,
    ESTATE_PROPERTY_TYPE_ROOM
} from '~/types/estate.types';

type PrivateBlockEditingProps = HandlerEditingWrappedProps & {
    flatNumber?: string;
    cadastralNumber: string | null;
    lotCadastralNumber: string | null;
    propertyType?: number;
};

@observer
class PrivateBlockEditing extends Component<PrivateBlockEditingProps> {
    constructor(props: PrivateBlockEditingProps) {
        super(props);

        const estate = {
            estate_id: props.item_id,
            flatNumber: props.flatNumber || '',
            cadastralNumber: props.cadastralNumber || null,
            lotCadastralNumber: props.lotCadastralNumber || null,
            propertyType: props.propertyType || 0
        };
        estateStore.setEditingItem(estate.estate_id, estate);
    }

    render() {
        const { cadastralNumber, flatNumber, propertyType, lotCadastralNumber } = estateStore.getItem(this.props.item_id).editingItem;

        return (
            <div className="crm-Item__editingMode">
                {[
                    ESTATE_PROPERTY_TYPE_FLAT,
                    ESTATE_PROPERTY_TYPE_NEWBUILDING,
                    ESTATE_PROPERTY_TYPE_NEWBUILDING_CESSION,
                    ESTATE_PROPERTY_TYPE_PART,
                    ESTATE_PROPERTY_TYPE_ROOM
                ].includes(propertyType) && (
                    <Form.Group>
                        <Form.Field inline>
                            <label className="crm-Estate__field_label">Номер квартиры</label>
                            <span className="crm-Estate__fieldNowrap">
                                <Input value={flatNumber} size="mini" placeholder="" name="flatNumber" onChange={this.props.handleChange} />

                                {flatNumber && (
                                    <Popup
                                        trigger={
                                            <a target="_blank" rel="noopener noreferrer" href={`https://rosreestr.net/`}>
                                                <Icon name="info" />
                                            </a>
                                        }
                                        hideOnScroll
                                        size="tiny"
                                        wide="very"
                                        hoverable
                                    >
                                        <List>
                                            <List.Item>
                                                <List.Header>Кадастровый номер можете найти на сайте</List.Header>
                                                <a target="_blank" rel="noopener noreferrer" href={`https://rosreestr.net/`}>
                                                    <Icon name="external" /> rosreestr.net{' '}
                                                </a>
                                            </List.Item>
                                        </List>
                                    </Popup>
                                )}
                            </span>
                        </Form.Field>
                    </Form.Group>
                )}
                <Form.Group>
                    <Form.Field inline>
                        <label className="crm-Estate__field_label">Кадастровый номер</label>
                        <span className="crm-Estate__fieldNowrap">
                            <Input
                                value={cadastralNumber || ''}
                                size="mini"
                                placeholder="Например, 47:14:1203001:814"
                                name="cadastralNumber"
                                onChange={this.props.handleChange}
                            />
                            {cadastralNumber && cadastralNumber.match(/\d+\:\d+\:\d+\:\d+/) && (
                                <a
                                    target="_blank"
                                    title="Смотреть информацию на сайте www.rosreestr.net"
                                    rel="noopener noreferrer"
                                    href={`https://rosreestr.net/kadastr/${cadastralNumber.replace(/:/g, '-')}`}
                                >
                                    <Icon name="external" />
                                </a>
                            )}
                        </span>
                    </Form.Field>
                </Form.Group>
                {[...ESTATE_PROPERTY_HOUSES].includes(propertyType) && (
                    <Form.Group>
                        <Form.Field inline>
                            <label className="crm-Estate__field_label">Кадастровый номер участка</label>
                            <span className="crm-Estate__fieldNowrap">
                                <Input
                                    value={lotCadastralNumber || ''}
                                    size="mini"
                                    placeholder="Например, 47:14:1203001:814"
                                    name="lotCadastralNumber"
                                    onChange={this.props.handleChange}
                                />
                                {lotCadastralNumber && lotCadastralNumber.match(/\d+\:\d+\:\d+\:\d+/) && (
                                    <a
                                        target="_blank"
                                        title="Смотреть информацию на сайте www.rosreestr.net"
                                        rel="noopener noreferrer"
                                        href={`https://rosreestr.net/kadastr/${lotCadastralNumber.replace(/:/g, '-')}`}
                                    >
                                        <Icon name="external" />
                                    </a>
                                )}
                            </span>
                        </Form.Field>
                    </Form.Group>
                )}
            </div>
        );
    }
}

export default HandlerEditingBlockWrapper(estateStore, PrivateBlockEditing);
