import React, { useState } from 'react';
import { observer } from 'mobx-react';

import commonStore from '~/stores/commonStore';
import estateSMAStore from '~/stores/estateSMAStore';
import { ShareEstateId } from '~/types/estate.types';
import { SmaEstateParamsType } from '~/types/estateSma.types';

import { NumberInput } from '~ui/TextInput';
import { Modal, ModalBtnAction, ModalContent } from '~ui/Modal';
import { MobileDatePicker } from '@material-ui/pickers';

import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import SaveIcon from '@material-ui/icons/Save';

import EstateSmaOuterLink from '~/components/Items/Estate/EstateSmaBlocks/EstateSmaOuterLink';
import InfoPopup from '~/components/Base/InfoPopup';
import ListError from '~/components/ListError';

const InfoBtn = React.memo(() => (
    <InfoPopup size="small">
        <Typography variant="body2">
            Если ссылки на объявление нет или она уже не работает, то укажите свои данные или оставьте те, что есть.
        </Typography>
        <Typography variant="body1">
            Чем более актуальные данные вы укажите в СМА, тем достовернее будет оценка стоимости и сроков продажи вашего объекта.
        </Typography>
    </InfoPopup>
));

const SmaEstateChangeParamsModal = ({
    onClose,
    sma_estate_id,
    editingItem
}: {
    onClose: () => void;
    sma_estate_id: number;
    editingItem: ShareEstateId;
}) => {
    const { isMobile } = commonStore;

    const {
        price,
        updateTimeInSma,
        priceInSma,
        source,
        sourceInnerId,
        createTime,
        createTimeInSma,
        updateTime,
        enable
    } = estateSMAStore.getItemInList(sma_estate_id, editingItem);
    const {
        updatingItem: { loading, errors }
    } = estateSMAStore.getItemList(sma_estate_id);

    const [soldPrice, setSoldPrice] = useState(price);
    const handleChangePrice = (event: React.ChangeEvent, { value }: { value: string }) => {
        setSoldPrice(Number(value));
    };

    const [isFixPrice, setIsFixPrice] = useState(Boolean(priceInSma));
    const handleToggleIsFixPrice = () => {
        setIsFixPrice(!isFixPrice);
    };

    const [hasAlreadySold, setHasAlreadySold] = useState(Boolean(updateTimeInSma));
    const handleHasAlreadySold = () => {
        setHasAlreadySold(!hasAlreadySold);
    };

    const [createDate, setCreateDate] = useState(new Date((createTimeInSma || createTime) * 1000));
    const [deleteDate, setDeleteDate] = useState(new Date((updateTimeInSma || updateTime) * 1000));

    const saveEstateSmaParams = async () => {
        const params: Partial<SmaEstateParamsType> = {};
        if (price !== soldPrice) {
            params.priceInSma = soldPrice;
        }

        const createDateTime = Math.floor(createDate.getTime() / 1000);
        if (createDateTime && createDateTime !== createTime) {
            params.createTimeInSma = createDateTime;
        }

        const updateDateTime = Math.floor(deleteDate.getTime() / 1000);
        if (!hasAlreadySold && updateTimeInSma) {
            params.updateTimeInSma = null;
        } else if (updateDateTime && (updateDateTime !== updateTime || enable)) {
            params.updateTimeInSma = updateDateTime;
        }

        if (Object.keys(params).length) {
            if (await estateSMAStore.updateItemList(sma_estate_id, editingItem, params)) {
                estateSMAStore.fetchItemList(sma_estate_id);
                onClose();
            }
        } else {
            onClose();
        }
    };

    const urlIsAvailable = Boolean(source && sourceInnerId);

    const modalButtons: ModalBtnAction[] = [
        { onClick: onClose, label: 'Отмена', disabled: loading },
        {
            onClick: saveEstateSmaParams,
            label: 'Поменять параметры',
            disabled: loading,
            color: 'primary',
            startIcon: <SaveIcon />,
            loading: loading
        }
    ];

    return (
        <Modal
            onClose={loading ? null : onClose}
            fullScreen={isMobile}
            maxWidth="sm"
            header="Параметры объекта в СМА"
            actions={modalButtons}
        >
            <ModalContent dividers>
                <ListError errors={errors} />

                <Grid container spacing={2} justify="space-around" alignItems="center">
                    {urlIsAvailable && (
                        <>
                            <Grid item xs={6}>
                                <Typography variant="subtitle1">{isMobile ? 'Источник' : 'Ссылка на источник'}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <EstateSmaOuterLink source={source} sourceInnerId={sourceInnerId} />
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant="body2">
                                    Посмотрите, соответствует ли цена и актуальность объявления. Если нет, откорректируйте эти данные.{' '}
                                    <InfoBtn />
                                </Typography>
                            </Grid>
                        </>
                    )}
                    {!urlIsAvailable && (
                        <Grid item xs={6}>
                            <Typography variant="subtitle1">
                                Ссылка на источник <b>не указана</b>
                                <InfoBtn />
                            </Typography>
                        </Grid>
                    )}
                </Grid>

                <Grid container spacing={2} justify="space-around" alignItems="center">
                    <Grid item xs={isMobile ? 12 : 6}>
                        <MobileDatePicker
                            label="Выставлен на продажу"
                            value={createDate}
                            onChange={setCreateDate}
                            mask="__.__.____"
                            renderInput={props => <TextField {...props} helperText="дд.мм.г" />}
                        />
                    </Grid>

                    <Grid item xs={isMobile ? 12 : 6}>
                        <Grid container justify="flex-start" alignItems="center">
                            <Tooltip title={!hasAlreadySold ? 'Уже продано или снято с продажи?' : 'Убрать дату продажи (еще не продано)'}>
                                <FormControlLabel
                                    control={<Checkbox checked={hasAlreadySold} color="primary" onChange={handleHasAlreadySold} />}
                                    label={!hasAlreadySold ? 'Уже продано' : ''}
                                />
                            </Tooltip>

                            {hasAlreadySold && (
                                <MobileDatePicker
                                    label="Дата продажи"
                                    value={deleteDate}
                                    onChange={setDeleteDate}
                                    mask="__.__.____"
                                    renderInput={props => <TextField {...props} helperText="дд.мм.г" />}
                                />
                            )}
                        </Grid>
                    </Grid>

                    <Grid item xs={isMobile ? 12 : 6}>
                        <FormControlLabel
                            control={<Checkbox checked={isFixPrice} color="primary" onChange={handleToggleIsFixPrice} />}
                            label="Зафиксировать цену?"
                        />
                    </Grid>

                    <Grid item xs={isMobile ? 12 : 6}>
                        <NumberInput
                            label="Цена"
                            value={soldPrice}
                            min={0}
                            onChange={handleChangePrice}
                            fullWidth={false}
                            extraLabel="руб."
                            disabled={!isFixPrice}
                        />
                    </Grid>
                </Grid>
            </ModalContent>
        </Modal>
    );
};

export default observer(SmaEstateChangeParamsModal);
