import React, { Component, Fragment, useState, ChangeEvent } from 'react';
import { List, Icon, Dropdown } from 'semantic-ui-react';
import { observer } from 'mobx-react';

import { CallTransaction, ExportTransaction } from '~/types/exports.types';
import { ACCESS_COMPANY, ACCESS_DELETE } from '~/types/access.types';

import exportingBaseStore from '~/stores/export/exportingBaseStore';
import commonStore from '~/stores/commonStore';
import authStore from '~/stores/authStore';
import estateStore from '~/stores/estateStore';
import exportStore from '~/stores/export/exportStore';

import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';

import PreventDblClick from '~/components/Base/PreventDblClick';
import UserLinkWithPreview from '~/components/Lists/Users/UserLinkWithPreview';
import UsersLinkList from '~/components/Lists/Users/UsersLinkList';
import InfoPopup from '~/components/Base/InfoPopup';
import Modal, { ModalContent } from '~ui/Modal';
import Time from '~/components/Base/Time';

import { WarningMessage } from '~ui/Message';
import ConfirmTrigger from '~ui/ConfirmTrigger';
import Table, { TableBody, TableCell, TableRow, TableHead } from '~ui/Table';
import Progress from '~ui/Progress';

type EstateExportHistoryModeProps = {
    estate_id: number;
};

const ChangeCallPriceModal = ({
    estate_id,
    call_id,
    price,
    onClose
}: {
    estate_id: number;
    call_id: number;
    price: number;
    onClose: () => void;
}) => {
    const [currentPrice, setCurrentPrice] = useState(price);

    const handlePrice = (event: ChangeEvent<HTMLInputElement>) => {
        setCurrentPrice(Number(event.target.value));
    };

    const handleSave = () => {
        estateStore.changeCallTransactionPrice(estate_id, call_id, currentPrice);
    };

    return (
        <Modal
            header="Укажите цену звонка"
            maxWidth="sm"
            onClose={onClose}
            actions={[
                { onClick: onClose, label: 'Отмена' },
                {
                    onClick: handleSave,
                    label: 'Поменять',
                    color: 'primary'
                }
            ]}
        >
            <ModalContent>
                <Input
                    value={currentPrice}
                    onChange={handlePrice}
                    inputProps={{
                        step: 1,
                        min: 0,
                        style: { width: '5em' }
                    }}
                    endAdornment={<InputAdornment position="end">р.</InputAdornment>}
                    type="number"
                    fullWidth
                />
            </ModalContent>
        </Modal>
    );
};

const TransactionDropdownMenu = React.memo(({ estate_id, transaction_id }: { estate_id: number; transaction_id: number }) => {
    return (
        <Dropdown item icon="setting" inline>
            <Dropdown.Menu>
                <Dropdown.Item>
                    <ConfirmTrigger
                        trigger={
                            <div>
                                <Icon name="edit" color="red" />
                                <Icon name="money" />
                                Погасить
                            </div>
                        }
                        header={'Погасить транзакцию'}
                        content={'Внимание, восстановить транзакцию не получится!'}
                        confirmButton={'Погасить'}
                        onConfirm={async () => {
                            await exportStore.redeemTransaction(estate_id, transaction_id);
                        }}
                    />
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
});

const TransactionsModal = ({
    transactions,
    calls,
    date,
    estate_id,
    onClose
}: {
    transactions: ExportTransaction[];
    calls: CallTransaction[];
    date: string;
    estate_id: number;
    onClose: () => void;
}) => {
    const [showCallsModal, setOpenCallsModal] = useState(false);
    const [price, setPrice] = useState(0);
    const [call_id, setCallId] = useState(0);

    const handleOpenModal = (call_id: number, price: number) => {
        setOpenCallsModal(true);
        setPrice(price);
        setCallId(call_id);
    };

    return (
        <>
            <Modal fullScreen={commonStore.isMobile} maxWidth="sm" header={`Расход за ${date}`} onClose={onClose}>
                <ModalContent dividers>
                    <div style={{ display: 'grid', gridTemplateColumns: calls.length > 0 ? '250px 250px' : '1fr' }}>
                        <List>
                            {transactions.map(({ price, export_base_id, redeemTime, assigned_user, extra, transaction_id }) => {
                                return (
                                    <List.Item key={transaction_id}>
                                        <List.Content>
                                            <List.Header>
                                                <UserLinkWithPreview user={assigned_user} />
                                            </List.Header>
                                            <List.Description>
                                                {price}
                                                ₽&nbsp;
                                                {extra && (
                                                    <Fragment>
                                                        <b>(экстра включен)</b>
                                                    </Fragment>
                                                )}
                                                &nbsp; - {exportingBaseStore.getBaseName(export_base_id)}
                                                {redeemTime && (
                                                    <span className="crm-Estate__redeem_info">
                                                        (Погашено: {new Date(redeemTime * 1000).toLocaleDateString()})
                                                    </span>
                                                )}
                                                {!redeemTime &&
                                                    authStore.getPermission(estateStore.moduleName, ACCESS_DELETE) === ACCESS_COMPANY && (
                                                        <TransactionDropdownMenu estate_id={estate_id} transaction_id={transaction_id} />
                                                    )}
                                            </List.Description>
                                        </List.Content>
                                    </List.Item>
                                );
                            })}
                        </List>

                        {calls.length > 0 && (
                            <List>
                                {calls.map(({ price, export_base_id, assigned_user, call_id, executeTime }) => (
                                    <List.Item key={call_id}>
                                        <List.Content>
                                            <List.Header>
                                                <Icon name="phone" />
                                                {authStore.getPermission(estateStore.moduleName, ACCESS_DELETE) === ACCESS_COMPANY && (
                                                    <Dropdown item icon="setting" inline>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item onClick={() => handleOpenModal(call_id, price)}>
                                                                <Icon name="edit" color="red" />
                                                                <Icon name="money" />
                                                                Изменить стоимость звонка
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                )}
                                                <Time time={executeTime} />
                                                &nbsp;
                                                <UserLinkWithPreview user={assigned_user} />
                                            </List.Header>
                                            <List.Description>
                                                {price}
                                                ₽&nbsp; &nbsp; - {exportingBaseStore.getBaseName(export_base_id)}
                                            </List.Description>
                                        </List.Content>
                                    </List.Item>
                                ))}
                            </List>
                        )}
                    </div>
                </ModalContent>
            </Modal>

            {showCallsModal && (
                <ChangeCallPriceModal estate_id={estate_id} call_id={call_id} price={price} onClose={() => setOpenCallsModal(false)} />
            )}
        </>
    );
};

const ShowTransactionInfo = ({
    estate_id,
    transactions,
    calls,
    date
}: {
    estate_id: number;
    transactions: ExportTransaction[];
    calls: CallTransaction[];
    date: string;
}) => {
    const [showModal, setOpenModal] = useState(false);
    const handleToggleModal = () => {
        setOpenModal(!showModal);
    };

    return (
        <>
            <div title="Информация об экспорте (нажмите, чтобы просмотреть)" onClick={handleToggleModal} style={{ cursor: 'pointer' }}>
                <Icon circular color="red" name="info" size="small" style={{ marginRight: '0.5em' }} />
                Транзакций: {transactions.length}
                {calls.length > 0 && `; Звонков: ${calls.length}`}
            </div>
            {showModal && (
                <TransactionsModal
                    estate_id={estate_id}
                    transactions={transactions}
                    calls={calls}
                    date={date}
                    onClose={handleToggleModal}
                />
            )}
        </>
    );
};

const ShowTransactionUsers = ({ transactions }: { transactions: Array<ExportTransaction> }) => {
    const usersSet = {};
    transactions.forEach(({ assigned_user }) => {
        if (!usersSet[assigned_user.user_id]) {
            usersSet[assigned_user.user_id] = assigned_user;
        }
    });
    return <UsersLinkList usersList={Object.keys(usersSet).map(key => usersSet[key])} />;
};

@observer
class EstateExportHistoryMode extends Component<EstateExportHistoryModeProps> {
    componentDidMount() {
        estateStore.fetchEstateExportHistory(this.props.estate_id);
    }

    render() {
        const { estate_id } = this.props;
        const { exportHistory, loadingExportHistory } = estateStore.getItem(estate_id).property;

        return (
            <PreventDblClick>
                <div>
                    {exportHistory && exportHistory.days.length > 0 && (
                        <Table size="small" compact>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Дата</TableCell>
                                    <TableCell>Сумма</TableCell>
                                    <TableCell>Инфо</TableCell>
                                    <TableCell>
                                        Сотрудник <InfoPopup content="На кого был списан экспорт" />
                                    </TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {exportHistory.days.map(({ dayCost, time, transactions, calls }) => {
                                    const dateString = new Date(time * 1000).toLocaleDateString();

                                    return (
                                        <TableRow key={time}>
                                            <TableCell>{new Date(time * 1000).toLocaleDateString()}</TableCell>
                                            <TableCell>{dayCost.toFixed(2)} ₽</TableCell>
                                            <TableCell>
                                                <ShowTransactionInfo
                                                    date={dateString}
                                                    estate_id={estate_id}
                                                    transactions={transactions}
                                                    calls={calls}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <ShowTransactionUsers transactions={transactions} />
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>

                            {/*<ListFooter*/}
                            {/*loading={loadingExportHistory}*/}
                            {/*colSpan={2}*/}
                            {/*pagination={exportPagination}*/}
                            {/*listCount={5}*/}
                            {/*pageChange={() => {}}*/}
                            {/*pageSizeChange={() => {}}*/}
                            {/*/>*/}
                        </Table>
                    )}
                    {exportHistory && exportHistory.days.length === 0 && (
                        <WarningMessage>У данного объекта нет истории экспорта</WarningMessage>
                    )}

                    {loadingExportHistory && <Progress show size={64} />}
                </div>
            </PreventDblClick>
        );
    }
}

export default EstateExportHistoryMode;
